import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { loaderProps } from '../Common/CommonStyles'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  TextField,
  Typography,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
} from '@mui/material'
import {
  CommonButton,
  InputField,
  SelectField,
  StyledMenuItem,
  useStyles,
} from './EmpoloyeementInfoStyle'
import { FormValues } from './IFormValue'
import { RootState } from '../../configureStore'
import {
  employeePortalEntity,
  fetchUserDetailsEntities,
  fetchUserDetailsUI,
  projectManagementEntity,
} from '../../reducers'
import {
  createUser,
  fetchAllRoles,
  fetchAllStates,
  fetchAllCountries,
  fetchAllFloors,
  fetchAllWorkstation,
  fetchAllLocation,
  uploadUserImg,
  updateUser,
  fetchAllClientLocation,
  getUserImage,
  fetchDesignation,
  fetchDesignationBand,
  fetchWorkingEmp,
  fetchTiming,
  fetchEmployeeInfo,
  fetchQualificationSkill,
  fetchQualification,
  addBackgroundInfo,
  fetchDownloadDocument,
  fetchBackgroundInfo,
} from '../../actions'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { ArrowBack } from '@mui/icons-material'
import styles from '../../utils/styles.json'
import { debugPort } from 'process'
import moment from 'moment'
import { FieldArray, Form, FormikProvider, useFormik } from 'formik'
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'

const style = {
  selectStyle: {
    '& .MuiSelect-select': {
      height: '15px',
    },
  },
  gridStyle: {
    '& .MuiFormControl-root': {
      margin: '0 0 15px',
    },
  },
}

const educationTypes = [
  { id: '1', label: 'High School' },
  { id: '2', label: 'Associate Degree' },
  { id: '3', label: "Bachelor's Degree" },
  { id: '4', label: "Master's Degree" },
  { id: '5', label: 'PhD' },
  { id: '6', label: 'Diploma' },
  { id: '7', label: 'Other' },
]

const AddBackgroundInfo = (props: any) => {
  const {
    fetchQualificationSkill,
    getQualificationSkill,
    fetchQualification,
    qualificationData,
    addBackgroundInfo,
    fetchDownloadDocument,
    fetchBackgroundInfo,
    getBackgroundInfo,
    isEmpBackgroundInfoLoader,
    isAddBackgroundInfo,
    resetAddBackgroundInfo,
    getDocument,
    fetchDownloadDocumentReset,
    isAddBackgroundInfoLoader,
  } = props

  const rowData: any = useLocation()?.state

  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const [deletedPosition, setDeletedPosition] = useState<number[]>([])
  const [deletedEducation, setDeletedEducation] = useState<number[]>([])

  // useEffect(() => {
  //   if (props.isUserUpdated) {
  //     toast.success('User updated successfully')
  //     navigate('/home/admin/employees')
  //   }
  // }, [props.isUserUpdated])

  useEffect(() => {
    fetchQualification()
    fetchQualificationSkill()
  }, [])

  useEffect(() => {
    if (rowData?.userid) fetchBackgroundInfo({ userId: rowData?.userid })
  }, [rowData?.userid])

  const start_date = moment().format('YYYY-MM-DD')
  const end_date = moment().add(30, 'days').format('YYYY-MM-DD')

  const validationSchema = Yup.object({
    educationInfo: Yup.array()
      .of(
        Yup.object({
          educationType: Yup.string().required('Education Type is required'),
          idQualificationSet: Yup.string().required('Graduated field is required'),
          passingYear: Yup.string()
            .required('Passing Year is required')
            .matches(/^\d{4}$/, 'Passing Year must be a valid year (e.g., 2024)'),
          collegeUniversity: Yup.string().required('College/University is required'),
          branchSubject: Yup.string().required('Branch/Subject is required'),
          grade: Yup.string().required('Grade is required'),
        }),
      )
      .min(1, 'At least one education record is required'),
    // prevPositionInfo: Yup.array()
    //   .of(
    //     Yup.object({
    //       // prevPosition: Yup.string().required('Previous Position is required'),
    //       // company: Yup.string().required('Company is required'),
    //       from: Yup.date(),
    //         // .required('Start Date is required')
    //         // .typeError('Invalid Start Date'),
    //       to: Yup.date()
    //         // .required('End Date is required')
    //         // .typeError('Invalid End Date')
    //         .min(Yup.ref('from'), 'End Date cannot be before Start Date'),
    //       // salaryPerMonth: Yup.number()
    //       //   .required('Monthly Salary is required')
    //       //   .typeError('Monthly Salary must be a number'),
    //       // annualSalary: Yup.number()
    //       //   .required('Annual Salary is required')
    //       //   .typeError('Annual Salary must be a number'),
    //     }),
    //   ),
    // .min(1, 'At least one previous position record is required'),
  })

  const formik = useFormik({
    initialValues: {
      id_user: Number(rowData?.userid),

      // licenseCertificateInfo: [
      //   {
      //     licenseCertiType: '',
      //     licenseName: '',
      //     issuingOrganization: '',
      //     issueDate: '',
      //     expirationDate: '',
      //     licenseNumber: '',
      //   },
      // ],
      educationInfo: getBackgroundInfo?.educationalInfo?.map((education: any) => ({
        id: education.id,
        fileName: education.fileName,
        educationType: education.id_qualification_set || '',
        idQualificationSet: education.skill_name || '',
        passingYear: education.year || '',
        collegeUniversity: education.degree || '',
        branchSubject: education.course_of_study || '',
        grade: education.percentage || '',
        certificate: null as File | null, // No certificate file from API
        fileCurrent: education.fileName,
      })) || [
        {
          educationType: '',
          idQualificationSet: '',
          passingYear: '',
          collegeUniversity: '',
          branchSubject: '',
          grade: '',
          certificate: null as File | null,
        },
      ],
      prevPositionInfo: getBackgroundInfo.previousPositionsHistory?.map((position: any) => ({
        id: position.id || 0,
        prevPosition: position.position || '',
        company: position.company || '',
        from: position.from_year ? new Date(position.from_year).toISOString().split('T')[0] : '',
        to: position.to_year ? new Date(position.to_year).toISOString().split('T')[0] : '',
        salaryPerMonth: position.supervisor || '',
        annualSalary: position.telephone || '',
      })) || [
        {
          prevPosition: '',
          company: '',
          from: '',
          to: '',
          salaryPerMonth: '',
          annualSalary: '',
        },
      ],
    },
    validationSchema,
    onSubmit: (values) => {},
  })

  useEffect(() => {
    if (isAddBackgroundInfo) {
      toast.success('Background information updated successfully')

      if (rowData?.userid) fetchBackgroundInfo({ userId: rowData?.userid })

      resetAddBackgroundInfo()
    }
  }, [isAddBackgroundInfo])

  useEffect(() => {
    if (getBackgroundInfo) {
      formik.setValues({
        id_user: Number(rowData?.userid) || 0,
        educationInfo:
          getBackgroundInfo?.educationalInfo?.length > 0
            ? getBackgroundInfo?.educationalInfo?.map((education: any) => ({
                id: education.id,
                fileName: education.fileName || '',
                educationType: education.id_qualification_set || '',
                idQualificationSet: education.skill_name || '',
                passingYear: education.year || '',
                collegeUniversity: education.degree || '',
                branchSubject: education.course_of_study || '',
                grade: education.percentage || '',
                certificate: education?.fileName
                  ? { name: education?.fileName }
                  : (null as File | null), // No certificate file from API
              }))
            : [
                {
                  id: '',
                  fileName: '',
                  educationType: '',
                  idQualificationSet: '',
                  passingYear: '',
                  collegeUniversity: '',
                  branchSubject: '',
                  grade: '',
                  certificate: null as File | null,
                },
              ],
        prevPositionInfo:
          getBackgroundInfo?.previousPositionsHistory?.length > 0
            ? getBackgroundInfo?.previousPositionsHistory?.map((position: any) => ({
                id: position.id || 0,
                prevPosition: position.position || '',
                company: position.company || '',
                from: position.from_year
                  ? new Date(position.from_year).toISOString().split('T')[0]
                  : '',
                to: position.to_year ? new Date(position.to_year).toISOString().split('T')[0] : '',
                salaryPerMonth: position.supervisor || '',
                annualSalary: position.telephone || '',
              }))
            : [
                {
                  prevPosition: '',
                  company: '',
                  from: '',
                  to: '',
                  salaryPerMonth: '',
                  annualSalary: '',
                },
              ],
      })
    }
  }, [getBackgroundInfo, rowData?.userid])

  const handleFileChange = (event: any, index: number) => {
    const file = event.target.files[0]

    if (file) {
      if (
        file.type === 'video/mp4' ||
        file.type === 'video/mpeg' ||
        file.type === 'video/quicktime' ||
        file.type === 'video/x-matroska'
      ) {
        toast.error('Video files are not allowed')
        event.target.value = ''
        formik.setFieldValue(`educationInfo[${index}].certificate`, null)
      } else {
        formik.setFieldValue(`educationInfo[${index}].certificate`, file)
      }
    }
  }

  const handlerSubmit = async (event: React.FormEvent) => {
    // event.preventDefault() // Prevent default form submission behavior
    const previousPositionsHeld = formik.values.prevPositionInfo.every((position: any) =>
      ['prevPosition', 'company', 'from', 'to', 'salaryPerMonth', 'annualSalary'].every(
        (field) => position[field] === '',
      ),
    )
      ? []
      : formik.values.prevPositionInfo.map((position: any) => ({
          id: position.id,
          position: position.prevPosition,
          company: position.company,
          from_year: position.from,
          to_year: position.to,
          supervisor: position.salaryPerMonth,
          telephone: position.annualSalary,
        }))

    try {
      // Validate the entire form using Yup validation schema
      await validationSchema.validate(formik.values, { abortEarly: false })
      const entity = {
        id_user: rowData?.userid, // Assuming id_user is available in formik.values
        educationalInfo: formik.values.educationInfo.map((education: any) => ({
          id_qualification_set: education.educationType,
          skill_name: education.idQualificationSet, // Mapping educationType to skill_name
          graduated: 'No', // Assuming grade indicates graduation status
          year: education.passingYear,
          degree: education.collegeUniversity, // Combine grade and branchSubject for degree
          course_of_study: `${education.branchSubject}`, // You can modify this based on your requirements
          percentage: education.grade,
          file: education.certificate,
        })),
        previousPositionsHeld: previousPositionsHeld,
        deletedPosition: deletedPosition,
        deletedEducation: deletedEducation,
      }

      // Call the update function
      addBackgroundInfo(entity)
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        // Extract error messages and map them to Formik's error state
        const stepErrors = error.inner.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr.message
          }
          return acc
        }, {} as { [key: string]: string })

        // Set touched state for the fields with validation errors
        formik.setTouched(
          Object.keys(stepErrors).reduce((acc, key) => {
            acc[key] = true
            return acc
          }, {} as { [key: string]: boolean }),
        )

        // Update Formik's error state with validation errors
        formik.setErrors(stepErrors)
      } else {
        // Handle unexpected errors
        console.error('Unexpected error:', error)
      }
    }
  }

  const setDownloadForm16Data = (id: number, dependent: any) => {
    fetchDownloadDocument({ id: id })
  }

  useEffect(() => {
    if (getDocument) {
      const getEle = document.getElementById('download')
      getEle?.click()
      fetchDownloadDocumentReset()
    }
  }, [getDocument])

  return (
    <div className={classes.root}>
      {(isEmpBackgroundInfoLoader || isAddBackgroundInfoLoader) && (
        <Dialog
          open={isEmpBackgroundInfoLoader || isAddBackgroundInfoLoader}
          PaperProps={{ style: loaderProps }}
        >
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ mx: 4, mt: 3 }}>
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '8px',
              padding: '20px 0',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  fontFamily: styles.FONT_BOLD,
                  fontSize: '32px',
                  fontWeight: 700,
                  lineHeight: '38.73px',
                  textAlign: 'center',
                  flexGrow: 1,
                  margin: '0',
                  color: '#193C6C',
                  // paddingRight: '30px',
                }}
              >
                Add {`${rowData?.first_name}`}'s Background Info
              </Typography>
              <Box onClick={() => navigate(-1)} sx={{ cursor: 'pointer', marginTop: '0px' }}>
                <ArrowBack sx={{ marginRight: '20px' }} />
              </Box>
            </Box>
            <Typography fontFamily={styles.FONT_BOLD} sx={{ paddingLeft: '15px' }}>
              Education Details
            </Typography>

            <Grid container>
              <FormikProvider value={formik}>
                <Form style={{ width: '100%' }}>
                  <FieldArray name='educationInfo'>
                    {({ push, remove }) => (
                      <>
                        {formik.values.educationInfo.map((dependent: any, index: number) => {
                          return (
                            <Grid
                              container
                              spacing={1}
                              key={index}
                              paddingLeft={0}
                              alignItems='center'
                              style={{
                                maxHeight: '80px', // Set a max height for the grid
                              }}
                            >
                              <Grid item xs={1.8}>
                                <SelectField
                                  name={`educationInfo[${index}].educationType`}
                                  size='small'
                                  fullWidth
                                  sx={{ marginLeft: '0' }}
                                  select
                                  label='Education Type'
                                  variant='outlined'
                                  margin='normal'
                                  value={dependent.educationType}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  required
                                  error={Boolean(
                                    (formik.touched.educationInfo as any[])?.[index]
                                      ?.educationType &&
                                      !formik.values.educationInfo[index].educationType,
                                  )}
                                >
                                  {qualificationData?.length > 0 &&
                                    qualificationData?.map((type: any) => (
                                      <StyledMenuItem
                                        sx={{ textTransform: 'capitalize' }}
                                        key={type.id}
                                        value={type.id}
                                        disabled={formik.values.educationInfo.some(
                                          (item: any, idx: number) =>
                                            idx !== index && item.educationType === type.id,
                                        )}
                                      >
                                        {type.qualification
                                          .toLowerCase()
                                          .replace(/(^\w|\s\w)/g, (letter: any) =>
                                            letter.toUpperCase(),
                                          )}
                                      </StyledMenuItem>
                                    ))}
                                </SelectField>
                              </Grid>
                              <Grid item xs={1.7}>
                                <SelectField
                                  name={`educationInfo[${index}].idQualificationSet`}
                                  size='small'
                                  sx={{ marginLeft: '0' }}
                                  fullWidth
                                  select
                                  label='Specialization'
                                  variant='outlined'
                                  margin='normal'
                                  value={dependent.idQualificationSet}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  required
                                  error={Boolean(
                                    (formik.touched.educationInfo as any[])?.[index]
                                      ?.idQualificationSet &&
                                      !formik.values.educationInfo[index].idQualificationSet,
                                  )}
                                >
                                  {/* Filter and display qualification skills based on selected educationType */}
                                  {getQualificationSkill
                                    ?.filter(
                                      (skill: any) =>
                                        skill.id_qualification_set === dependent.educationType,
                                    )
                                    ?.map((skill: any) => (
                                      <StyledMenuItem key={skill.id} value={skill.id}>
                                        {skill.qualification_skill_name}
                                      </StyledMenuItem>
                                    ))}
                                </SelectField>
                              </Grid>

                              <Grid item xs={1.4}>
                                <InputField
                                  name={`educationInfo[${index}].passingYear`}
                                  size='small'
                                  fullWidth
                                  label='Passing Year'
                                  variant='outlined'
                                  margin='normal'
                                  sx={{
                                    marginLeft: '0',
                                    '& .MuiFormLabel-asterisk': {
                                      color: 'red',
                                    },
                                  }}
                                  value={dependent.passingYear}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  required={true}
                                  error={
                                    (formik.touched.educationInfo as any[])?.[index]?.passingYear &&
                                    Boolean(
                                      (formik.errors.educationInfo as any[])?.[index]?.passingYear,
                                    )
                                  }
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <InputField
                                  name={`educationInfo[${index}].collegeUniversity`}
                                  size='small'
                                  fullWidth
                                  label='College/University'
                                  variant='outlined'
                                  margin='normal'
                                  value={dependent.collegeUniversity}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  required={true}
                                  sx={{
                                    marginLeft: '0',
                                    '& .MuiFormLabel-asterisk': {
                                      color: 'red',
                                    },
                                  }}
                                  error={
                                    (formik.touched.educationInfo as any[])?.[index]
                                      ?.collegeUniversity &&
                                    Boolean(
                                      (formik.errors.educationInfo as any[])?.[index]
                                        ?.collegeUniversity,
                                    )
                                  }
                                />
                              </Grid>

                              <Grid item xs={1.7}>
                                <InputField
                                  name={`educationInfo[${index}].branchSubject`}
                                  size='small'
                                  fullWidth
                                  sx={{
                                    marginLeft: '0',
                                    '& .MuiFormLabel-asterisk': {
                                      color: 'red',
                                    },
                                  }}
                                  label='Branch/Subject'
                                  variant='outlined'
                                  margin='normal'
                                  value={dependent.branchSubject}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  required={true}
                                  error={
                                    (formik.touched.educationInfo as any[])?.[index]
                                      ?.branchSubject &&
                                    Boolean(
                                      (formik.errors.educationInfo as any[])?.[index]
                                        ?.branchSubject,
                                    )
                                  }
                                />
                              </Grid>

                              <Grid item xs={1.8}>
                                <InputField
                                  name={`educationInfo[${index}].grade`}
                                  size='small'
                                  fullWidth
                                  sx={{
                                    marginLeft: '-5px',
                                    '& .MuiFormLabel-asterisk': {
                                      color: 'red',
                                    },
                                  }}
                                  label='Percentage/CGPA'
                                  value={dependent.grade}
                                  onChange={formik.handleChange}
                                  required={true}
                                  onBlur={formik.handleBlur}
                                  error={Boolean(
                                    (formik.touched.educationInfo as any[])?.[index]?.grade &&
                                      !formik.values.educationInfo[index].grade,
                                  )}
                                />
                              </Grid>

                              {/* <Grid item xs={3}>
                                  {formik.values.educationInfo[index]?.certificate ? (
                                    <Typography
                                      sx={{
                                        fontSize: '13px',
                                        maxWidth: '200px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      {formik.values.educationInfo[index].certificate}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      sx={{ fontSize: '13px', color: 'gray' }}
                                      variant='body2'
                                    >
                                      No file selected
                                    </Typography>
                                  )}
                                  {formik.touched.educationInfo?.[index]?.certificate &&
                                    !formik.values.educationInfo[index]?.certificate && (
                                      <Typography color='error' variant='body2'>
                                        {formik.errors.educationInfo?.[index]?.certificate}
                                      </Typography>
                                    )}
                                </Grid> */}

                              <Grid
                                item
                                xs={1}
                                display='flex'
                                alignItems='center'
                                sx={{ marginTop: '15px', marginLeft: '-7px' }}
                              >
                                <a id='download' href={getDocument} download></a>
                                <Tooltip
                                  title={dependent?.fileName ? 'Download Document' : ''}
                                  arrow
                                >
                                  <IconButton
                                    sx={{
                                      opacity: dependent?.fileName ? '1' : '0.5',
                                      '&:hover': {
                                        cursor: dependent?.fileName ? 'pointer' : 'not-allowed',
                                      },
                                    }}
                                    onClick={(event) => {
                                      if (dependent?.fileName) {
                                        event.stopPropagation()
                                        setDownloadForm16Data(dependent.id, dependent)
                                      }
                                    }}
                                    disableRipple={dependent?.fileName}
                                  >
                                    <CloudDownloadIcon
                                      fontSize='medium'
                                      sx={{ color: '#8b41f7' }}
                                    />
                                  </IconButton>
                                </Tooltip>
                                <input
                                  type='file'
                                  id={`file-${index}`}
                                  style={{ display: 'none' }}
                                  onChange={(event) => handleFileChange(event, index)}
                                  accept='image/jpeg, image/png, application/pdf, application/msword, 
                                          application/vnd.openxmlformats-officedocument.wordprocessingml.document, 
                                          application/vnd.oasis.opendocument.text, 
                                          application/vnd.ms-excel, 
                                          application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                />
                                <label htmlFor={`file-${index}`}>
                                  <Tooltip title='Upload Document' arrow>
                                    <IconButton component='span'>
                                      <CloudUploadIcon
                                        fontSize='medium'
                                        sx={{ color: '#3d4ff9' }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </label>
                                {index === formik.values.educationInfo.length - 1 ? (
                                  <>
                                    <Tooltip title='Add Education Details' arrow>
                                      <AddCircleOutlineIcon
                                        onClick={() =>
                                          push({
                                            educationType: '',
                                            idQualificationSet: '',
                                            passingYear: '',
                                            collegeUniversity: '',
                                            branchSubject: '',
                                            grade: '',
                                            certificate: '',
                                          })
                                        }
                                        fontSize='large'
                                        sx={{
                                          cursor: 'pointer',
                                          color: 'green',
                                        }}
                                      />
                                    </Tooltip>

                                    {index !== 0 && (
                                      <Tooltip title='Remove Education Details' arrow>
                                        <HighlightOffIcon
                                          onClick={() => {
                                            setDeletedEducation((prev) => [...prev, dependent.id])
                                            return remove(index)
                                          }}
                                          fontSize='large'
                                          sx={{
                                            cursor: 'pointer',
                                            color: 'red',
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </>
                                ) : (
                                  <Tooltip title='Remove Education Details' arrow>
                                    <HighlightOffIcon
                                      onClick={() => {
                                        setDeletedEducation((prev) => [...prev, dependent.id])
                                        return remove(index)
                                      }}
                                      fontSize='large'
                                      sx={{
                                        cursor: 'pointer',
                                        color: 'red',
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </Grid>
                              {formik.values.educationInfo[index]?.certificate ? (
                                <Typography
                                  sx={{
                                    fontSize: '13px',
                                    color: 'gray',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginLeft: '-25px',
                                  }}
                                >
                                  {(formik.values.educationInfo[index]?.certificate?.name.length ??
                                    0) < 20
                                    ? formik.values.educationInfo[index]?.certificate?.name
                                    : formik.values.educationInfo[index]?.certificate?.name.slice(
                                        0,
                                        20,
                                      ) + '...'}
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{
                                    fontSize: '13px',
                                    color: 'gray',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginLeft: '-25px',
                                  }}
                                  variant='body2'
                                >
                                  No file selected
                                </Typography>
                              )}
                            </Grid>
                          )
                        })}

                        {formik.values.educationInfo.length === 0 && (
                          <Tooltip title='Add Previous Position Held' arrow>
                            <AddCircleOutlineIcon
                              onClick={() =>
                                push({
                                  educationType: '',
                                  idQualificationSet: '',
                                  passingYear: '',
                                  collegeUniversity: '',
                                  branchSubject: '',
                                  grade: '',
                                  certificate: '',
                                })
                              }
                              fontSize='large'
                              sx={{
                                cursor: 'pointer',
                                color: 'green',
                                marginLeft: '15px',
                              }}
                            />
                          </Tooltip>
                        )}
                      </>
                    )}
                  </FieldArray>
                </Form>
              </FormikProvider>
            </Grid>

            <Divider sx={{ my: 2 }} />

            <Typography fontFamily={styles.FONT_BOLD} sx={{ paddingLeft: '15px' }}>
              Previous Position Held
            </Typography>

            <Grid container>
              <FormikProvider value={formik}>
                <Form style={{ width: '100%' }}>
                  <FieldArray name='prevPositionInfo'>
                    {({ push, remove }) => (
                      <>
                        {formik.values.prevPositionInfo.map((dependent: any, index: number) => {
                          return (
                            <Grid
                              container
                              spacing={1}
                              key={index}
                              alignItems='center'
                              style={{
                                maxHeight: '80px', // Set a max height for the grid
                              }}
                            >
                              <Grid item xs={2.2}>
                                <InputField
                                  name={`prevPositionInfo[${index}].prevPosition`}
                                  size='small'
                                  fullWidth
                                  label='Previous Position Held'
                                  variant='outlined'
                                  margin='normal'
                                  // sx={{
                                  //   '& .MuiFormLabel-asterisk': {
                                  //     color: 'red',
                                  //   },
                                  // }}
                                  value={dependent.prevPosition}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  // required={true}
                                  // error={
                                  //   (formik.touched.prevPositionInfo as any[])?.[index]
                                  //     ?.prevPosition &&
                                  //   Boolean(
                                  //     (formik.errors.prevPositionInfo as any[])?.[index]
                                  //       ?.prevPosition,
                                  //   )
                                  // }
                                />
                              </Grid>
                              <Grid item xs={1.5}>
                                <InputField
                                  name={`prevPositionInfo[${index}].company`}
                                  size='small'
                                  fullWidth
                                  label='Company'
                                  variant='outlined'
                                  margin='normal'
                                  value={dependent.company}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  required={false}
                                  // sx={{
                                  //   '& .MuiFormLabel-asterisk': {
                                  //     color: 'red',
                                  //   },
                                  // }}
                                  // error={
                                  //   (formik.touched.prevPositionInfo as any[])?.[index]?.company &&
                                  //   Boolean(
                                  //     (formik.errors.prevPositionInfo as any[])?.[index]?.company,
                                  //   )
                                  // }
                                />
                              </Grid>
                              <Grid item xs={1.8}>
                                <InputField
                                  size='small'
                                  fullWidth
                                  id={`prevPositionInfo[${index}].from`}
                                  label='Start Date'
                                  name={`prevPositionInfo[${index}].from`}
                                  type='date'
                                  value={dependent.from || ''}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const selectedDate = event.target.value
                                    formik.setFieldValue(
                                      `prevPositionInfo[${index}].from`,
                                      selectedDate,
                                    )
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  required={false}
                                  inputProps={{
                                    max: dayjs().format('YYYY-MM-DD'),
                                  }}
                                  // error={
                                  //   (formik.touched.prevPositionInfo as any[])?.[index]?.from &&
                                  //   Boolean(
                                  //     (formik.errors.prevPositionInfo as any[])?.[index]?.from,
                                  //   )
                                  // }
                                />
                              </Grid>
                              <Grid item xs={1.8}>
                                <InputField
                                  size='small'
                                  fullWidth
                                  id={`prevPositionInfo[${index}].to`}
                                  label='End Date'
                                  name={`prevPositionInfo[${index}].to`}
                                  type='date'
                                  value={dependent.to || ''}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const selectedDate = event.target.value
                                    formik.setFieldValue(
                                      `prevPositionInfo[${index}].to`,
                                      selectedDate,
                                    )
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  required={false}
                                  inputProps={{
                                    min: dependent.from || '',
                                    max: dayjs().format('YYYY-MM-DD'),
                                  }}
                                  // error={
                                  //   (formik.touched.prevPositionInfo as any[])?.[index]?.to &&
                                  //   Boolean((formik.errors.prevPositionInfo as any[])?.[index]?.to)
                                  // }
                                />
                              </Grid>

                              <Grid item xs={1.8}>
                                <InputField
                                  name={`prevPositionInfo[${index}].salaryPerMonth`}
                                  size='small'
                                  fullWidth
                                  // sx={{
                                  //   '& .MuiFormLabel-asterisk': {
                                  //     color: 'red',
                                  //   },
                                  // }}
                                  label='Salary Per Month'
                                  value={dependent.salaryPerMonth}
                                  onChange={formik.handleChange}
                                  // required={true}
                                  onBlur={formik.handleBlur}
                                  type='number'
                                  // error={Boolean(
                                  //   (formik.touched.prevPositionInfo as any[])?.[index]
                                  //     ?.salaryPerMonth &&
                                  //     !formik.values.prevPositionInfo[index].salaryPerMonth,
                                  // )}
                                />
                              </Grid>
                              <Grid item xs={1.7}>
                                <InputField
                                  name={`prevPositionInfo[${index}].annualSalary`}
                                  size='small'
                                  fullWidth
                                  // sx={{
                                  //   '& .MuiFormLabel-asterisk': {
                                  //     color: 'red',
                                  //   },
                                  // }}
                                  label='Annual Salary'
                                  type='number'
                                  value={dependent.annualSalary}
                                  onChange={formik.handleChange}
                                  // required={true}
                                  onBlur={formik.handleBlur}
                                  // error={Boolean(
                                  //   (formik.touched.prevPositionInfo as any[])?.[index]
                                  //     ?.annualSalary &&
                                  //     !formik.values.prevPositionInfo[index].annualSalary,
                                  // )}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                display='flex'
                                alignItems='center'
                                sx={{ marginTop: '15px', marginLeft: '15px' }}
                              >
                                {index === formik.values.prevPositionInfo.length - 1 ? (
                                  <>
                                    <Tooltip title='Add Previous Position Held' arrow>
                                      <AddCircleOutlineIcon
                                        onClick={() =>
                                          push({
                                            prevPosition: '',
                                            company: '',
                                            from: '',
                                            to: '',
                                            salaryPerMonth: '',
                                            annualSalary: '',
                                          })
                                        }
                                        fontSize='large'
                                        sx={{
                                          cursor: 'pointer',
                                          color: 'green',
                                        }}
                                      />
                                    </Tooltip>

                                    {index !== 0 && (
                                      <Tooltip title='Remove Previous Position Held' arrow>
                                        <HighlightOffIcon
                                          onClick={() => {
                                            setDeletedPosition((prev) => [...prev, dependent.id])
                                            return remove(index)
                                          }}
                                          fontSize='large'
                                          sx={{
                                            cursor: 'pointer',
                                            color: 'red',
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </>
                                ) : (
                                  <Tooltip title='Remove Previous Position Held' arrow>
                                    <HighlightOffIcon
                                      onClick={() => {
                                        setDeletedPosition((prev) => [...prev, dependent.id])
                                        return remove(index)
                                      }}
                                      fontSize='large'
                                      sx={{
                                        cursor: 'pointer',
                                        color: 'red',
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                          )
                        })}

                        {formik.values.prevPositionInfo.length === 0 && (
                          <Tooltip title='Add Previous Position Held' arrow>
                            <AddCircleOutlineIcon
                              onClick={() =>
                                push({
                                  prevPosition: '',
                                  company: '',
                                  from: '',
                                  to: '',
                                  salaryPerMonth: '',
                                  annualSalary: '',
                                })
                              }
                              fontSize='large'
                              sx={{
                                cursor: 'pointer',
                                color: 'green',
                                marginLeft: '15px',
                              }}
                            />
                          </Tooltip>
                        )}
                      </>
                    )}
                  </FieldArray>
                </Form>
              </FormikProvider>
            </Grid>

            {/* <Divider sx={{ my: 2 }} /> */}

            {/* <Typography fontFamily={styles.FONT_BOLD} sx={{ paddingLeft: '15px' }}>
              License/Professional Certificate
            </Typography>

            <Grid container>
              <FormikProvider value={formik}>
                <Form style={{ width: '100%' }}>
                  <FieldArray name='licenseCertificateInfo'>
                    {({ push, remove }) => (
                      <>
                        {formik.values.licenseCertificateInfo.map(
                          (dependent: any, index: number) => {
                            return (
                              <Grid
                                container
                                spacing={1}
                                key={index}
                                alignItems='center'
                                style={{
                                  maxHeight: '80px', // Set a max height for the grid
                                }}
                              >
                                <Grid item xs={1.6}>
                                  <SelectField
                                    name={`licenseCertificateInfo[${index}].licenseCertiType`}
                                    size='small'
                                    fullWidth
                                    sx={{ marginLeft: '0' }}
                                    select
                                    label='License Type'
                                    variant='outlined'
                                    margin='normal'
                                    value={dependent.licenseCertiType}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                    error={Boolean(
                                      (formik.touched.licenseCertificateInfo as any[])?.[index]
                                        ?.licenseCertiType &&
                                        !formik.values.licenseCertificateInfo[index]
                                          .licenseCertiType,
                                    )}
                                  >
                                    <StyledMenuItem sx={{ textTransform: 'capitalize' }} value={1}>
                                      sample
                                    </StyledMenuItem>
                                  </SelectField>
                                </Grid>
                                <Grid item xs={2}>
                                  <InputField
                                    name={`licenseCertificateInfo[${index}].licenseName`}
                                    size='small'
                                    fullWidth
                                    label='License/Certi Name'
                                    variant='outlined'
                                    margin='normal'
                                    sx={{
                                      '& .MuiFormLabel-asterisk': {
                                        color: 'red',
                                      },
                                    }}
                                    value={dependent.licenseName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required={true}
                                    error={
                                      (formik.touched.licenseCertificateInfo as any[])?.[index]
                                        ?.licenseName &&
                                      Boolean(
                                        (formik.errors.licenseCertificateInfo as any[])?.[index]
                                          ?.licenseName,
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <InputField
                                    name={`licenseCertificateInfo[${index}].issuingOrganization`}
                                    size='small'
                                    fullWidth
                                    label='Institution Name'
                                    variant='outlined'
                                    margin='normal'
                                    value={dependent.issuingOrganization}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required={true}
                                    sx={{
                                      '& .MuiFormLabel-asterisk': {
                                        color: 'red',
                                      },
                                    }}
                                    error={
                                      (formik.touched.licenseCertificateInfo as any[])?.[index]
                                        ?.issuingOrganization &&
                                      Boolean(
                                        (formik.errors.licenseCertificateInfo as any[])?.[index]
                                          ?.issuingOrganization,
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid item xs={1.8}>
                                  <InputField
                                    size='small'
                                    fullWidth
                                    id={`licenseCertificateInfo[${index}].issueDate`}
                                    label='Issue Date'
                                    name={`licenseCertificateInfo[${index}].issueDate`}
                                    type='date'
                                    value={dependent.issueDate || ''}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                      const selectedDate = event.target.value
                                      formik.setFieldValue(
                                        `licenseCertificateInfo[${index}].issueDate`,
                                        selectedDate,
                                      )
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    required={false}
                                    inputProps={{
                                      max: dayjs().format('YYYY-MM-DD'),
                                    }}
                                    error={
                                      (formik.touched.licenseCertificateInfo as any[])?.[index]
                                        ?.issueDate &&
                                      Boolean(
                                        (formik.errors.licenseCertificateInfo as any[])?.[index]
                                          ?.issueDate,
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid item xs={1.8}>
                                  <InputField
                                    size='small'
                                    fullWidth
                                    id={`licenseCertificateInfo[${index}].expirationDate`}
                                    label='Expiration Date'
                                    name={`licenseCertificateInfo[${index}].expirationDate`}
                                    type='date'
                                    value={dependent.expirationDate || ''}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                      const selectedDate = event.target.value
                                      formik.setFieldValue(
                                        `licenseCertificateInfo[${index}].expirationDate`,
                                        selectedDate,
                                      )
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    required={false}
                                    inputProps={{
                                      min: dependent.issueDate || '',
                                      max: dayjs().format('YYYY-MM-DD'),
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={1.6}>
                                  <InputField
                                    name={`licenseCertificateInfo[${index}].licenseNumber`}
                                    size='small'
                                    fullWidth
                                    sx={{
                                      '& .MuiFormLabel-asterisk': {
                                        color: 'red',
                                      },
                                    }}
                                    label='License Number'
                                    value={dependent.licenseNumber}
                                    onChange={formik.handleChange}
                                    required={false}
                                    onBlur={formik.handleBlur}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={1}
                                  display='flex'
                                  alignItems='center'
                                  sx={{ marginTop: '15px', marginLeft: '15px' }}
                                >
                                  {index === formik.values.licenseCertificateInfo.length - 1 ? (
                                    <>
                                      <Tooltip title='Add Task'>
                                        <AddCircleOutlineIcon
                                          onClick={() =>
                                            push({
                                              licenseCertiType: '',
                                              licenseName: '',
                                              issuingOrganization: '',
                                              issueDate: '',
                                              expirationDate: '',
                                              licenseNumber: '',
                                            })
                                          }
                                          fontSize='large'
                                          sx={{
                                            cursor: 'pointer',
                                            color: 'green',
                                          }}
                                        />
                                      </Tooltip>

                                      {index !== 0 && (
                                        <Tooltip title='Remove Task'>
                                          <HighlightOffIcon
                                            onClick={() => remove(index)}
                                            fontSize='large'
                                            sx={{
                                              cursor: 'pointer',
                                              color: 'red',
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                    </>
                                  ) : (
                                    <Tooltip title='Remove Task'>
                                      <HighlightOffIcon
                                        onClick={() => remove(index)}
                                        fontSize='large'
                                        sx={{
                                          cursor: 'pointer',
                                          color: 'red',
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </Grid>
                              </Grid>
                            )
                          },
                        )}

                        {formik.values.licenseCertificateInfo.length === 0 && (
                          <Tooltip title='Add Task'>
                            <AddCircleOutlineIcon
                              onClick={() =>
                                push({
                                  licenseCertiType: '',
                                  licenseName: '',
                                  issuingOrganization: '',
                                  issueDate: '',
                                  expirationDate: '',
                                  licenseNumber: '',
                                })
                              }
                              fontSize='large'
                              sx={{
                                cursor: 'pointer',
                                color: 'green',
                                marginLeft: '15px',
                              }}
                            />
                          </Tooltip>
                        )}
                      </>
                    )}
                  </FieldArray>
                </Form>
              </FormikProvider>
            </Grid> */}
            <Box
              sx={{
                marginTop: '24px',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
                marginRight: '20px',
              }}
            >
              <Button
                sx={{
                  fontSize: '16px',
                  fontFamily: styles.FONT_BOLD,
                  width: '20%',
                  borderRadius: '20px',
                  height: '40px',
                  padding: '14px 28px',
                  background: '#E2E2E2',
                  color: '#193C6C',
                  '&:hover': {
                    background: '#E2E2E2',
                    color: '#000000',
                  },
                }}
                variant='contained'
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <CommonButton
                type='submit'
                variant='contained'
                color='primary'
                onClick={handlerSubmit}
              >
                SUBMIT
              </CommonButton>
            </Box>
          </div>
        </Box>
      </form>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isAddBackgroundInfoLoader: fetchUserDetailsUI.fetchUserData(state).isAddBackgroundInfoLoader,

    getDocument: fetchUserDetailsEntities.fetchUserData(state).getDocument,
    isAddBackgroundInfo: fetchUserDetailsUI.fetchUserData(state).isAddBackgroundInfo,
    isEmpBackgroundInfoLoader: fetchUserDetailsUI.fetchUserData(state).isEmpBackgroundInfoLoader,
    getBackgroundInfo: fetchUserDetailsEntities.fetchUserData(state).getBackgroundInfo,
    qualificationData: employeePortalEntity.getEmployeePortal(state).getQualification,
    getQualificationSkill: employeePortalEntity.getEmployeePortal(state).getQualificationSkill,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchQualification: () => dispatch(fetchQualification.request()),
    fetchQualificationSkill: () => dispatch(fetchQualificationSkill.request()),
    addBackgroundInfo: (data: any) => dispatch(addBackgroundInfo.request(data)),
    resetAddBackgroundInfo: () => dispatch(addBackgroundInfo.reset()),

    fetchDownloadDocument: (data: any) => dispatch(fetchDownloadDocument.request(data)),
    fetchDownloadDocumentReset: () => dispatch(fetchDownloadDocument.reset()),
    fetchBackgroundInfo: (data: any) => dispatch(fetchBackgroundInfo.request(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddBackgroundInfo)
