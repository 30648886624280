import styled from 'styled-components'
import { StyledTableCell } from '../../Common/CommonStyles'
import { TableRow } from '@mui/material'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  left: '160px',
  width: '1719px',
  height: '15px',
  boxShadow: '0px 5px 2px #6c6c6c10',
  opacity: '1',
}))

const style = {
  rowInnerItem: {
    fontFamily: 'Montserrat-Regular',
    fontSize: '0.8rem',
    padding: '0',
  },
  rowBorder: {
    borderTop: '1px solid #EEEEEE',
    borderBottom: '1px solid #EEEEEE',
    minHeight: '60px',
    margin: '16px 0 0',
  },
  border: {
    borderLeft: '1px solid #E9E9E9',
    borderRight: '1px solid #E9E9E9',
  },
}

const TableRowContainer = (props: any) => {
  const { row, idx } = props
  return (
    <StyledTableRow id='body' key={idx} sx={style.border}>
      <StyledTableCell>{row.name}</StyledTableCell>
      <StyledTableCell>{row.customer}</StyledTableCell>
      <StyledTableCell>{row.source}</StyledTableCell>
      <StyledTableCell>
        {row?.domain?.length > 0
          ? row?.domain?.map((domain: any) => domain.domain_name).join(', ')
          : 'NA'}
      </StyledTableCell>
      <StyledTableCell>{row.location}</StyledTableCell>
      <StyledTableCell>{row.project_type}</StyledTableCell>
      <StyledTableCell>{row.project_status}</StyledTableCell>
      <StyledTableCell align='center'>
        {new Date(row.start_date).toLocaleDateString()}
      </StyledTableCell>
      <StyledTableCell align='center'>
        {row.end_date ? new Date(row.end_date).toLocaleDateString() : 'NA'}
      </StyledTableCell>
      <StyledTableCell>
        {parseInt(row?.stats?.dev_fixed) > 0 && (
          <>
            Fixed - {row?.stats?.dev_fixed}
            <br />
          </>
        )}
        {parseInt(row?.stats?.dev_monthly) > 0 && (
          <>
            Monthly - {row?.stats?.dev_monthly}
            <br />
          </>
        )}
        {parseInt(row?.stats?.dev_hourly) > 0 && (
          <>
            Hours - {row?.stats?.dev_hourly}
            <br />
          </>
        )}
        {parseInt(row?.stats?.dev_fixed) === 0 &&
          parseInt(row?.stats?.dev_monthly) === 0 &&
          parseInt(row?.stats?.dev_hourly) === 0 && (
            <>
              NA
              <br />
            </>
          )}
      </StyledTableCell>

      <StyledTableCell>
        {parseInt(row?.stats?.qa_fixed) > 0 && (
          <>
            Fixed - {row?.stats?.qa_fixed}
            <br />
          </>
        )}
        {parseInt(row?.stats?.qa_monthly) > 0 && (
          <>
            Monthly - {row?.stats?.qa_monthly}
            <br />
          </>
        )}
        {parseInt(row?.stats?.qa_hourly) > 0 && (
          <>
            Hours - {row?.stats?.qa_hourly}
            <br />
          </>
        )}
        {parseInt(row?.stats?.qa_fixed) === 0 &&
          parseInt(row?.stats?.qa_monthly) === 0 &&
          parseInt(row?.stats?.qa_hourly) === 0 && (
            <>
              NA
              <br />
            </>
          )}
      </StyledTableCell>

      <StyledTableCell>
        {parseInt(row?.stats?.BA_fixed) > 0 && (
          <>
            Fixed - {row?.stats?.BA_fixed}
            <br />
          </>
        )}
        {parseInt(row?.stats?.BA_monthly) > 0 && (
          <>
            Monthly - {row?.stats?.BA_monthly}
            <br />
          </>
        )}
        {parseInt(row?.stats?.BA_hourly) > 0 && (
          <>
            Hours - {row?.stats?.BA_hourly}
            <br />
          </>
        )}
        {parseInt(row?.stats?.BA_fixed) === 0 &&
          parseInt(row?.stats?.BA_monthly) === 0 &&
          parseInt(row?.stats?.BA_hourly) === 0 && (
            <>
              NA
              <br />
            </>
          )}
      </StyledTableCell>

      <StyledTableCell>
        {parseInt(row?.stats?.designer_fixed) > 0 && (
          <>
            Fixed - {row?.stats?.designer_fixed}
            <br />
          </>
        )}
        {parseInt(row?.stats?.designer_monthly) > 0 && (
          <>
            Monthly - {row?.stats?.designer_monthly}
            <br />
          </>
        )}
        {parseInt(row?.stats?.designer_hourly) > 0 && (
          <>
            Hours - {row?.stats?.designer_hourly}
            <br />
          </>
        )}
        {parseInt(row?.stats?.designer_fixed) === 0 &&
          parseInt(row?.stats?.designer_monthly) === 0 &&
          parseInt(row?.stats?.designer_hourly) === 0 && (
            <>
              NA
              <br />
            </>
          )}
      </StyledTableCell>

      <StyledTableCell>
        {parseInt(row?.stats?.lead_fixed) > 0 && (
          <>
            Fixed - {row?.stats?.lead_fixed}
            <br />
          </>
        )}
        {parseInt(row?.stats?.lead_monthly) > 0 && (
          <>
            Monthly - {row?.stats?.lead_monthly}
            <br />
          </>
        )}
        {parseInt(row?.stats?.lead_hourly) > 0 && (
          <>
            Hours - {row?.stats?.lead_hourly}
            <br />
          </>
        )}
        {parseInt(row?.stats?.lead_fixed) === 0 &&
          parseInt(row?.stats?.lead_monthly) === 0 &&
          parseInt(row?.stats?.lead_hourly) === 0 && (
            <>
              NA
              <br />
            </>
          )}
      </StyledTableCell>

      <StyledTableCell>
        {parseInt(row?.stats?.reviewer_fixed) > 0 && (
          <>
            Fixed - {row?.stats?.reviewer_fixed}
            <br />
          </>
        )}
        {parseInt(row?.stats?.reviewer_monthly) > 0 && (
          <>
            Monthly - {row?.stats?.reviewer_monthly}
            <br />
          </>
        )}
        {parseInt(row?.stats?.reviewer_hourly) > 0 && (
          <>
            Hours - {row?.stats?.reviewer_hourly}
            <br />
          </>
        )}
        {parseInt(row?.stats?.reviewer_fixed) === 0 &&
          parseInt(row?.stats?.reviewer_monthly) === 0 &&
          parseInt(row?.stats?.reviewer_hourly) === 0 && (
            <>
              NA
              <br />
            </>
          )}
      </StyledTableCell>

      <StyledTableCell>
        {parseInt(row?.stats?.total_fixed) > 0 && (
          <>
            Fixed - {row?.stats?.total_fixed}
            <br />
          </>
        )}
        {parseInt(row?.stats?.total_monthly) > 0 && (
          <>
            Monthly - {row?.stats?.total_monthly}
            <br />
          </>
        )}
        {parseInt(row?.stats?.total_hourly) > 0 && (
          <>
            Hours - {row?.stats?.total_hourly}
            <br />
          </>
        )}
        {parseInt(row?.stats?.total_fixed) === 0 &&
          parseInt(row?.stats?.total_monthly) === 0 &&
          parseInt(row?.stats?.total_hourly) === 0 && (
            <>
              NA
              <br />
            </>
          )}
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default TableRowContainer
