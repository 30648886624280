import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { Actions as actions } from '../../actions/Types'
import { RootState } from '../../configureStore'
import {
  DELETE_USER,
  ADD_NEW_JOINERS,
  UPDATE_NEW_JOINERS,
  GET_NEW_JOINERS_DETAILS,
  FAILURE,
  FETCH_ALL_CLIENT_LOCATION,
  FETCH_ALL_COUNTRY,
  FETCH_ALL_FLOOR,
  FETCH_ALL_LOCATION,
  FETCH_ALL_STATE,
  FETCH_ALL_USER_LIST,
  FETCH_ALL_WORKSTATION,
  GET_USER_IMAGE,
  GET_EXPECTED_JOINERS_IMAGE,
  RESET,
  UPDATE_USER,
  UPLOAD_USER_IMG,
  UPLOAD_CERTIFICATE,
  GET_EMPLOYEE,
  GET_EMPLOYEEMENT_INFO,
  GET_CERTIFICATE_BY_SPLIT,
  GET_CERTI_PERCENTAGE,
  GET_USER_STATICS,
  GET_COUNTRY_DISTRIBUTION,
  ADD_BANK_INFO,
  EMP_SALARY_SLIP,
  EMP_COMPENSATION,
  FETCH_BACKGROUND_INFO,
  DOWNLOAD_DOCUMENT,
  FETCH_LEAVE_TYPE,
  UPDATE_LEAVE,
  PAYROLL_DROPDOWN_TYPE,
  GET_PAYROLL,
} from '../../actions/actiontypes'
import { toast } from 'react-toastify'

const { SUCCESS, REQUEST, CREATE_USER, FETCH_ALL_ROLE, GET_USER_TECHNOLOGY, GET_GRADE_LABLE } =
  actionTypes

const entity = () => {
  const fetchUser = (state = [], action: actions) => {
    switch (action.type) {
      case CREATE_USER[SUCCESS]:
        return action.payload
      case CREATE_USER[REQUEST]:
        return []
      case CREATE_USER[FAILURE]:
        return action.payload.data
      case CREATE_USER[RESET]:
        return {}
      default:
        return state
    }
  }

  const getUserTechnology = (state = [], action: actions) => {
    switch (action.type) {
      case GET_USER_TECHNOLOGY[SUCCESS]:
        return action.payload
      case GET_USER_TECHNOLOGY[REQUEST]:
        return []
      case GET_USER_TECHNOLOGY[FAILURE]:
        return action.payload.data
      case GET_USER_TECHNOLOGY[RESET]:
        return {}
      default:
        return state
    }
  }

  const getGradeLabelData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_GRADE_LABLE[SUCCESS]:
        return action.payload
      case GET_GRADE_LABLE[REQUEST]:
        return []
      case GET_GRADE_LABLE[FAILURE]:
        return action.payload.data
      case GET_GRADE_LABLE[RESET]:
        return {}
      default:
        return state
    }
  }

  const getCertiBySplitUpData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_CERTIFICATE_BY_SPLIT[SUCCESS]:
        return action.payload
      case GET_CERTIFICATE_BY_SPLIT[REQUEST]:
        return []
      case GET_CERTIFICATE_BY_SPLIT[FAILURE]:
        return action.payload.data
      case GET_CERTIFICATE_BY_SPLIT[RESET]:
        return {}
      default:
        return state
    }
  }
  const getCertiPercentage = (state = [], action: actions) => {
    switch (action.type) {
      case GET_CERTI_PERCENTAGE[SUCCESS]:
        return action.payload
      case GET_CERTI_PERCENTAGE[REQUEST]:
        return []
      case GET_CERTI_PERCENTAGE[FAILURE]:
        return action.payload.data
      case GET_CERTI_PERCENTAGE[RESET]:
        return {}
      default:
        return state
    }
  }

  const getUserStatistics = (state = [], action: actions) => {
    switch (action.type) {
      case GET_USER_STATICS[SUCCESS]:
        return action.payload
      case GET_USER_STATICS[REQUEST]:
        return []
      case GET_USER_STATICS[FAILURE]:
        return action.payload.data
      case GET_USER_STATICS[RESET]:
        return {}
      default:
        return state
    }
  }

  const getContryDistribution = (state = [], action: actions) => {
    switch (action.type) {
      case GET_COUNTRY_DISTRIBUTION[SUCCESS]:
        return action.payload
      case GET_COUNTRY_DISTRIBUTION[REQUEST]:
        return []
      case GET_COUNTRY_DISTRIBUTION[FAILURE]:
        return action.payload.data
      case GET_COUNTRY_DISTRIBUTION[RESET]:
        return {}
      default:
        return state
    }
  }

  const fetchNewJoiners = (state = [], action: actions) => {
    switch (action.type) {
      case ADD_NEW_JOINERS[SUCCESS]:
        return action.payload
      case ADD_NEW_JOINERS[REQUEST]:
        return []
      case ADD_NEW_JOINERS[FAILURE]:
        return action.payload.data
      case ADD_NEW_JOINERS[RESET]:
        return {}
      default:
        return state
    }
  }

  const updateNewJoiners = (state = [], action: actions) => {
    switch (action.type) {
      case UPDATE_NEW_JOINERS[SUCCESS]:
        return action.payload
      case UPDATE_NEW_JOINERS[REQUEST]:
        return []
      case UPDATE_NEW_JOINERS[FAILURE]:
        return action.payload.data
      case UPDATE_NEW_JOINERS[RESET]:
        return {}
      default:
        return state
    }
  }

  const getNewJoinersDetails = (state = [], action: actions) => {
    switch (action.type) {
      case GET_NEW_JOINERS_DETAILS[SUCCESS]:
        return action.payload
      case GET_NEW_JOINERS_DETAILS[REQUEST]:
        return []
      case GET_NEW_JOINERS_DETAILS[FAILURE]:
        return action.payload.data
      case GET_NEW_JOINERS_DETAILS[RESET]:
        return {}
      default:
        return state
    }
  }

  const fetchAllRole = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ALL_ROLE[SUCCESS]:
        return action.payload
      case FETCH_ALL_ROLE[REQUEST]:
        return []
      case FETCH_ALL_ROLE[FAILURE]:
        return action.payload.data
      case FETCH_ALL_ROLE[RESET]:
        return {}
      default:
        return state
    }
  }

  const fetchAllState = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ALL_STATE[SUCCESS]:
        return action.payload
      case FETCH_ALL_STATE[REQUEST]:
        return []
      case FETCH_ALL_STATE[FAILURE]:
        return action.payload.data
      case FETCH_ALL_STATE[RESET]:
        return {}
      default:
        return state
    }
  }

  const getEmployee = (state = [], action: actions) => {
    switch (action.type) {
      case GET_EMPLOYEE[SUCCESS]:
        return action.payload
      case GET_EMPLOYEE[REQUEST]:
        return []
      case GET_EMPLOYEE[FAILURE]:
        return action.payload.data
      case GET_EMPLOYEE[RESET]:
        return {}
      default:
        return state
    }
  }

  const getEmployeementInfo = (state = [], action: actions) => {
    switch (action.type) {
      case GET_EMPLOYEEMENT_INFO[SUCCESS]:
        return action.payload
      case GET_EMPLOYEEMENT_INFO[REQUEST]:
        return []
      case GET_EMPLOYEEMENT_INFO[FAILURE]:
        return action.payload.data
      case GET_EMPLOYEEMENT_INFO[RESET]:
        return {}
      default:
        return state
    }
  }

  const getSalarySlipInfo = (state = [], action: actions) => {
    switch (action.type) {
      case EMP_SALARY_SLIP[SUCCESS]:
        return action.payload
      case EMP_SALARY_SLIP[REQUEST]:
        return []
      case EMP_SALARY_SLIP[FAILURE]:
        return action.payload.data
      case EMP_SALARY_SLIP[RESET]:
        return {}
      default:
        return state
    }
  }

  const getCompensationInfo = (state = [], action: actions) => {
    switch (action.type) {
      case EMP_COMPENSATION[SUCCESS]:
        return action.payload
      case EMP_COMPENSATION[REQUEST]:
        return []
      case EMP_COMPENSATION[FAILURE]:
        return action.payload.data
      case EMP_COMPENSATION[RESET]:
        return {}
      default:
        return state
    }
  }

  const getBackgroundInfo = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_BACKGROUND_INFO[SUCCESS]:
        return action.payload
      case FETCH_BACKGROUND_INFO[REQUEST]:
        return []
      case FETCH_BACKGROUND_INFO[FAILURE]:
        return action.payload.data
      case FETCH_BACKGROUND_INFO[RESET]:
        return {}
      default:
        return state
    }
  }

  const getLeaveTypes = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_LEAVE_TYPE[SUCCESS]:
        return action.payload
      case FETCH_LEAVE_TYPE[REQUEST]:
        return []
      case FETCH_LEAVE_TYPE[FAILURE]:
        return action.payload.data
      case FETCH_LEAVE_TYPE[RESET]:
        return {}
      default:
        return state
    }
  }

  const getUpdateLeaves = (state = [], action: actions) => {
    switch (action.type) {
      case UPDATE_LEAVE[SUCCESS]:
        return action.payload
      case UPDATE_LEAVE[REQUEST]:
        return []
      case UPDATE_LEAVE[FAILURE]:
        return action.payload.data
      case UPDATE_LEAVE[RESET]:
        return {}
      default:
        return state
    }
  }

  const getDocument = (state = '', action: actions) => {
    switch (action.type) {
      case DOWNLOAD_DOCUMENT[SUCCESS]:
        return action.payload
      case DOWNLOAD_DOCUMENT[REQUEST]:
        return ''
      case DOWNLOAD_DOCUMENT[FAILURE]:
        return ''
      case DOWNLOAD_DOCUMENT[RESET]:
        return ''
      default:
        return state
    }
  }

  const fetchAllCountry = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ALL_COUNTRY[SUCCESS]:
        return action.payload
      case FETCH_ALL_COUNTRY[REQUEST]:
        return []
      case FETCH_ALL_COUNTRY[FAILURE]:
        return action.payload.data
      case FETCH_ALL_COUNTRY[RESET]:
        return {}
      default:
        return state
    }
  }

  const fetchAllFloor = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ALL_FLOOR[SUCCESS]:
        return action.payload
      case FETCH_ALL_FLOOR[REQUEST]:
        return []
      case FETCH_ALL_FLOOR[FAILURE]:
        return action.payload.data
      case FETCH_ALL_FLOOR[RESET]:
        return {}
      default:
        return state
    }
  }

  const fetchAllWorkstation = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ALL_WORKSTATION[SUCCESS]:
        return action.payload
      case FETCH_ALL_WORKSTATION[REQUEST]:
        return []
      case FETCH_ALL_WORKSTATION[FAILURE]:
        return action.payload.data
      case FETCH_ALL_WORKSTATION[RESET]:
        return {}
      default:
        return state
    }
  }

  const fetchAllLocation = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ALL_LOCATION[SUCCESS]:
        return action.payload
      case FETCH_ALL_LOCATION[REQUEST]:
        return []
      case FETCH_ALL_LOCATION[FAILURE]:
        return action.payload.data
      case FETCH_ALL_LOCATION[RESET]:
        return {}
      default:
        return state
    }
  }

  const payrollDropDown = (state = [], action: actions) => {
    switch (action.type) {
      case PAYROLL_DROPDOWN_TYPE[SUCCESS]:
        return action.payload
      case PAYROLL_DROPDOWN_TYPE[REQUEST]:
        return []
      case PAYROLL_DROPDOWN_TYPE[FAILURE]:
        return action.payload.data
      case PAYROLL_DROPDOWN_TYPE[RESET]:
        return {}
      default:
        return state
    }
  }

  const getPayrollData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_PAYROLL[SUCCESS]:
        return action.payload
      case GET_PAYROLL[REQUEST]:
        return []
      case GET_PAYROLL[FAILURE]:
        return action.payload.data
      case GET_PAYROLL[RESET]:
        return {}
      default:
        return state
    }
  }

  const fetchAllClientLocation = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ALL_CLIENT_LOCATION[SUCCESS]:
        return action.payload
      case FETCH_ALL_CLIENT_LOCATION[REQUEST]:
        return []
      case FETCH_ALL_CLIENT_LOCATION[FAILURE]:
        return action.payload.data
      case FETCH_ALL_CLIENT_LOCATION[RESET]:
        return {}
      default:
        return state
    }
  }

  const fetchAllUserList = (state = [], action: actions) => {
    switch (action.type) {
      case FETCH_ALL_USER_LIST[SUCCESS]:
        return action.payload
      case FETCH_ALL_USER_LIST[REQUEST]:
        return []
      case FETCH_ALL_USER_LIST[FAILURE]:
        return action.payload.data
      case FETCH_ALL_USER_LIST[RESET]:
        return {}
      default:
        return state
    }
  }

  const updateUser = (state = [], action: actions) => {
    switch (action.type) {
      case UPDATE_USER[SUCCESS]:
        return action.payload
      case UPDATE_USER[REQUEST]:
        return []
      case UPDATE_USER[FAILURE]:
        return action.payload.data
      case UPDATE_USER[RESET]:
        return {}
      default:
        return state
    }
  }

  const uploadUser = (state = [], action: actions) => {
    switch (action.type) {
      case UPLOAD_USER_IMG[SUCCESS]:
        return action.payload
      case UPLOAD_USER_IMG[REQUEST]:
        return []
      case UPLOAD_USER_IMG[FAILURE]:
        return action.payload.data
      case UPLOAD_USER_IMG[RESET]:
        return {}
      default:
        return state
    }
  }

  const getUserImage = (state = [], action: actions) => {
    switch (action.type) {
      case GET_USER_IMAGE[SUCCESS]:
        return action.payload
      case GET_USER_IMAGE[REQUEST]:
        return []
      case GET_USER_IMAGE[FAILURE]:
        return action.payload.data
      case GET_USER_IMAGE[RESET]:
        return {}
      default:
        return state
    }
  }

  const getExpectedJoinersImage = (state = [], action: actions) => {
    switch (action.type) {
      case GET_EXPECTED_JOINERS_IMAGE[SUCCESS]:
        return action.payload
      case GET_EXPECTED_JOINERS_IMAGE[REQUEST]:
        return []
      case GET_EXPECTED_JOINERS_IMAGE[FAILURE]:
        return action.payload.data
      case GET_EXPECTED_JOINERS_IMAGE[RESET]:
        return {}
      default:
        return state
    }
  }

  const deleleUser = (state = [], action: actions) => {
    switch (action.type) {
      case DELETE_USER[SUCCESS]:
        return true
      case DELETE_USER[REQUEST]:
        return []
      case DELETE_USER[FAILURE]:
        return action.payload.data
      case DELETE_USER[RESET]:
        return {}
      default:
        return state
    }
  }

  const uploadCertificate = (state = [], action: actions) => {
    switch (action.type) {
      case UPLOAD_CERTIFICATE[SUCCESS]:
        return action.payload
      case UPLOAD_CERTIFICATE[REQUEST]:
        return []
      case UPLOAD_CERTIFICATE[FAILURE]:
        return action.payload.data
      case UPLOAD_CERTIFICATE[RESET]:
        return {}
      default:
        return state
    }
  }

  const getAddBankInfoData = (state = [], action: actions) => {
    switch (action.type) {
      case ADD_BANK_INFO[SUCCESS]:
        return action.payload
      case ADD_BANK_INFO[REQUEST]:
        return []
      case ADD_BANK_INFO[FAILURE]:
        return action.payload.data
      case ADD_BANK_INFO[RESET]:
        return {}
      default:
        return state
    }
  }

  return combineReducers({
    getUserTechnology,
    getEmployeementInfo,
    fetchUser,
    getExpectedJoinersImage,
    uploadCertificate,
    fetchNewJoiners,
    updateNewJoiners,
    getNewJoinersDetails,
    fetchAllRole,
    fetchAllState,
    fetchAllUserList,
    fetchAllCountry,
    fetchAllFloor,
    fetchAllWorkstation,
    fetchAllLocation,
    updateUser,
    fetchAllClientLocation,
    uploadUser,
    getUserImage,
    deleleUser,
    getEmployee,
    getGradeLabelData,
    getCertiBySplitUpData,
    getCertiPercentage,
    getUserStatistics,
    getContryDistribution,
    getAddBankInfoData,
    getSalarySlipInfo,
    getCompensationInfo,
    getBackgroundInfo,
    getDocument,
    getLeaveTypes,
    payrollDropDown,
    getPayrollData,
  })
}

export default entity

export const fetchUserData = (state: RootState) => state.entities.user
