import { Box, MenuItem, TextField } from '@mui/material'
import DateRangePicker from './DateRangePicker'
import SearchBox from './SearchBox'
import { ActionBarPropsType } from './ProjectSheetTypes'
import { style } from './SheetStyles'
import ExportButton from './ExportButton'
import styles from '../../utils/styles.json'
import styled from 'styled-components'

export const SelectField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    padding: '11px 14px', // Adjust the padding here
    fontSize: '15px', // Adjust the font size here
    fontFamily: styles.FONT_MEDIUM,
  },
  '& .MuiFormLabel-root': {
    marginTop: '2px',
    fontSize: '15px',
    fontFamily: styles.FONT_MEDIUM,
    backgroundColor: 'white',
    padding: '0 7px',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
    height: '40px',
  },
}))

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '15px !important',
  fontFamily: `${styles.FONT_MEDIUM} !important`,
}))

const ActionBar = (props: ActionBarPropsType) => {
  const {
    searchQuery,
    selectedTab,
    setSearchQuery,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setShowResults,
    subTableData,
    searchByProject,
    setSearchByProject,
    handleSearchFilterType,
  } = props

  return (
    <Box component='div' id='actionBar'>
      <Box sx={style.actionBarConatiner} paddingBottom={'0'}>
        <Box sx={style.actionBar}>
          <Box sx={{ width: '45%', display: 'flex', alignItems: 'center', gap: '10px' }}>
            <SelectField
              select
              size='small'
              fullWidth
              id='employment_type'
              name='employment_type'
              value={searchByProject}
              onChange={handleSearchFilterType}
            >
              <StyledMenuItem value={1}>Search By Emp Name</StyledMenuItem>
              <StyledMenuItem value={2}>Search By Project Name</StyledMenuItem>
            </SelectField>
            <SearchBox
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              searchByProject={searchByProject}
            />
          </Box>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            padding='0'
            sx={style.datePickerContainer}
          >
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setShowResults={setShowResults}
              searchQuery={searchQuery}
              selectedTab={selectedTab}
            />
          </Box>
          <Box sx={style.actionBarDownloadbtn}>
            <ExportButton
              subTableData={subTableData}
              selectedTab={selectedTab}
              startDate={startDate}
              endDate={endDate}
              searchQuery={searchQuery}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ActionBar
