import { useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { loaderProps } from '../Common/CommonStyles'
import { Box, Button, CircularProgress, Dialog, Grid, TextField, Typography } from '@mui/material'
import {
  CommonButton,
  InputField,
  SelectField,
  StyledMenuItem,
  useStyles,
} from './EmpoloyeementInfoStyle'
import { FormValues } from './IFormValue'
import { RootState } from '../../configureStore'
import {
  employeePortalEntity,
  fetchUserDetailsEntities,
  fetchUserDetailsUI,
  projectManagementEntity,
} from '../../reducers'
import {
  createUser,
  fetchAllRoles,
  fetchAllStates,
  fetchAllCountries,
  fetchAllFloors,
  fetchAllWorkstation,
  fetchAllLocation,
  uploadUserImg,
  updateUser,
  fetchAllClientLocation,
  getUserImage,
  fetchDesignation,
  fetchDesignationBand,
  fetchWorkingEmp,
  fetchTiming,
  fetchEmployeeInfo,
} from '../../actions'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { ArrowBack } from '@mui/icons-material'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import styles from '../../utils/styles.json'
import { debugPort } from 'process'
import moment from 'moment'

const style = {
  selectStyle: {
    '& .MuiSelect-select': {
      height: '15px',
    },
  },
  gridStyle: {
    '& .MuiFormControl-root': {
      margin: '0 0 15px',
    },
  },
}

const EmpoloyeementInfo = (props: any) => {
  const {
    isUserUpdating,
    updateUser,
    fetchEmployeementInfo,
    isEmployeementInfoUpdated,
    employeementInfoData,
    isGetEmpoyeementInfoLoader,
    restFetchEmployee,
  } = props

  const rowdata: any = useLocation()?.state
  const empInfo: any = employeementInfoData?.employmentInfo
  const getEmployeeInfo: any = employeementInfoData

  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  // const userData = location?.state?.data?.rowData || {}

  useEffect(() => {
    props.fetchAllRole()
    props.fetchAllState()
    props.fetchAllCountry()
    props.fetchAllFloor()
    props.fetchAllWorkstation()
    props.fetchAllLocation()
    props.fetchAllClientLocation()

    if (rowdata?.userid) {
      props.getUserImage(rowdata)
      fetchEmployeementInfo({ userId: rowdata?.userid })
    }

    return () => {
      props.resetCreateUser()
      props.resetUpdateUser()
      props.resetUserImage()
    }
  }, [rowdata?.userid])

  useEffect(() => {
    if (employeementInfoData?.employmentInfo) {
      const empInfo = employeementInfoData.employmentInfo;
      const getEmployeeInfo = employeementInfoData;
  
      formik.setValues({
        employee_id: getEmployeeInfo?.employee_id ?? '',
        under_probation: getEmployeeInfo?.under_probation === 0 ? 0 : 1,
        desig_band: empInfo?.designation_band ?? '',
        manager: empInfo?.id_manager ?? '',
        status: empInfo?.status ? Number(empInfo?.status) : 1,
        probation_period: getEmployeeInfo?.probation_period ?? 12,
        pf_applicable: empInfo?.pf_applicable ?? 1,
        pf_no: empInfo?.pf_no ?? '',
        uan_no: empInfo?.uan_no ?? '',
        pan_card_no: empInfo?.pan_card_no ?? '',
        emp_type: empInfo?.employment_type ? Number(empInfo?.employment_type) : 1,
        aadhaar_card_no: empInfo?.aadhaar_card_no ?? '',
        referred_by: empInfo?.referred_by ?? '',
        service_agreement: empInfo?.id_service_agreement ?? '',
        service_agreement_amount: empInfo?.service_agreement_amount ?? '75000',
        experience: empInfo?.working_experience ?? '',
        isIDCard: empInfo?.id_card_issued ?? '0',
        under_maternity: empInfo?.under_maternity ?? '0',
        salary_on_hold: empInfo?.salary_on_hold ?? '0',
        poor_performance_review: empInfo?.under_performance_review ?? '0',
        isMediclaim: empInfo?.mediclaim_issued ?? '0',
        mediclaim_no: empInfo?.mediclaim_no ?? '',
        passport_no: empInfo?.passport_no ?? '',
        tshirt_size: empInfo?.tshirt_size ?? 0,
        under_notice: getEmployeeInfo?.under_notice ?? 0,
        notice_period: getEmployeeInfo?.notice_period ?? 0,
        day_to_visit: empInfo?.day_to_visit ?? '',
        relieving_type: empInfo?.termination_type ?? 0,
        first_performance_review: empInfo?.first_performance_review ?? '0',
        first_weekend: empInfo?.first_weekend ?? 'Saturday',
        second_weekend: empInfo?.second_weekend ?? 'Sunday',
        hire_date: formattedHireDate ?? '',
        start_date: formattedEffectiveDate ?? '',
        from_date: formattedStartDate ?? '',
        to_date: formattedEndDate ?? '',
        empDesignation: empInfo?.id_grade ?? '',
        shift_timing: getEmployeeInfo?.officeLog?.timing_id ?? 47,
        notice_description: getEmployeeInfo?.notice_description,
      });
    }
  }, [employeementInfoData]); // Dependency on fetched data
  

  useEffect(() => {
    props.fetchDesignationData()
    props.fetchAllEmpAPI()
    props.fetchDesignationBandData()
    props.fetchTimingData()
  }, [])

  useEffect(() => {
    if (props.isUserUpdated) {
      toast.success('User updated successfully')
      navigate('/home/admin/employees')
    }
  }, [props.isUserUpdated])
  useEffect(() => {
    if (empInfo?.userid) {
      restFetchEmployee()
    }
  }, [empInfo])

  const start_date = moment().format('YYYY-MM-DD')
  const end_date = moment().add(30, 'days').format('YYYY-MM-DD')

  const formattedStartDate = getEmployeeInfo?.poor_performance?.start_date
    ? moment(getEmployeeInfo?.poor_performance?.start_date).format('YYYY-MM-DD')
    : start_date

  const formattedEndDate = getEmployeeInfo?.poor_performance?.end_date
    ? moment(getEmployeeInfo?.poor_performance?.end_date).format('YYYY-MM-DD')
    : end_date

  const formattedHireDate = empInfo?.hire_date ? dayjs(empInfo?.hire_date).format('YYYY-MM-DD') : ''

  const formattedEffectiveDate = getEmployeeInfo?.officeLog?.start_date
    ? dayjs(getEmployeeInfo?.officeLog?.start_date).format('YYYY-MM-DD')
    : ''

  const validationSchema = Yup.object({
    // empDesignation: Yup.string().required('Required'),
    service_agreement: Yup.string().required('Required'),
    // manager: Yup.string().required('Required'),
    hire_date: Yup.string().required('Required'),
    first_weekend: Yup.string().required('Required'),
    second_weekend: Yup.string().required('Required'),
    start_date: Yup.string().required('Required'),
  })

  const formik = useFormik({
    initialValues: {
      employee_id: getEmployeeInfo?.employee_id ?? '',
      under_probation: getEmployeeInfo?.under_probation === 0 ? 0 : 1,
      // designation: rowdata?.DesginationName?.id ?? '',
      desig_band: empInfo?.designation_band ?? '',
      manager: empInfo?.id_manager ?? '',
      status: empInfo?.status ? Number(empInfo?.status) : 1,
      probation_period: getEmployeeInfo?.probation_period ?? 12,
      pf_applicable: empInfo?.pf_applicable ?? 1,
      pf_no: empInfo?.pf_no ?? '',
      uan_no: empInfo?.uan_no ?? '',
      pan_card_no: employeementInfoData?.employmentInfo?.pan_card_no ?? '',
      emp_type: empInfo?.employment_type ? Number(empInfo?.employment_type) : 1,
      aadhaar_card_no: empInfo?.aadhaar_card_no ?? '',
      referred_by: empInfo?.referred_by ?? '',
      service_agreement: empInfo?.id_service_agreement ?? '',
      service_agreement_amount: empInfo?.service_agreement_amount ?? '75000',
      experience: empInfo?.working_experience ?? '',
      isIDCard: empInfo?.id_card_issued ?? '0',
      under_maternity: empInfo?.under_maternity ?? '0',
      salary_on_hold: empInfo?.salary_on_hold ?? '0',
      poor_performance_review: empInfo?.under_performance_review ?? '0',
      isMediclaim: empInfo?.mediclaim_issued ?? '0',
      mediclaim_no: empInfo?.mediclaim_no ?? '',
      passport_no: empInfo?.passport_no ?? '',
      tshirt_size: empInfo?.tshirt_size ?? 0,
      under_notice: getEmployeeInfo?.under_notice ?? 0,
      notice_period: getEmployeeInfo?.notice_period ?? 0,
      day_to_visit: empInfo?.day_to_visit ?? '',
      relieving_type: empInfo?.termination_type ?? 0,
      first_performance_review: empInfo?.first_performance_review ?? '0',
      first_weekend: empInfo?.first_weekend ?? 'Saturday',
      second_weekend: empInfo?.second_weekend ?? 'Sunday',
      hire_date: formattedHireDate ?? '',
      start_date: formattedEffectiveDate ?? '',
      from_date: formattedStartDate ?? '',
      to_date: formattedEndDate ?? '',
      // time_to_visit: formattedTimetoVisit ?? '',

      empDesignation: empInfo?.id_grade ?? '',
      shift_timing: getEmployeeInfo?.officeLog?.timing_id ?? 47,
      notice_description: getEmployeeInfo?.notice_description,
    },
    validationSchema,
    onSubmit: (values) => {},
  })

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formik.values, { abortEarly: false })
      const entity = {
        userId: rowdata?.userid,
        employee_id: String(formik.values.employee_id),
        under_probation: Number(formik.values.under_probation),
        status: Number(formik.values.status),
        probation_period: Number(formik.values.probation_period),
        id_manager: formik.values.manager, //manger
        id_grade: formik.values?.empDesignation, //designation
        designation_band: formik.values.desig_band, //designation
        hire_date: formik.values.hire_date,
        under_notice: Number(formik.values.under_notice),
        notice_period: Number(formik.values.notice_period),
        referred_by: formik.values.referred_by,
        employment_type: formik.values.emp_type,
        pan_card_no: formik.values.pan_card_no,
        aadhaar_card_no: formik.values.aadhaar_card_no,
        id_service_agreement: formik.values.service_agreement,
        service_agreement_amount: formik.values.service_agreement_amount,
        mediclaim_issued: formik.values.isMediclaim,
        mediclaim_no: formik.values.mediclaim_no,

        termination_type: formik.values.relieving_type,
        pf_applicable: formik.values.pf_applicable,
        pf_no: formik.values.pf_no,
        uan_no: formik.values.uan_no,
        passport_no: formik.values.passport_no,
        working_experience: formik.values.experience,
        under_performance_review: formik.values.poor_performance_review,
        under_maternity: formik.values.under_maternity,
        id_card_issued: formik.values.isIDCard,
        salary_on_hold: formik.values.salary_on_hold,
        first_performance_review: formik.values.first_performance_review,
        first_weekend: formik.values.first_weekend,
        second_weekend: formik.values.second_weekend,
        tshirt_size: formik.values.tshirt_size,
        // time_to_visit: formik.values.time_to_visit,
        // day_to_visit: String(formik.values.day_to_visit),
        updateEmpInfoFlag: 1,
        start_date: formik.values.start_date,
        // poor_performance_start_date: formik.values.from_date,
        // poor_performance_end_date: formik.values.to_date,
        ...(Number(formik.values.poor_performance_review) !== 0 && {
          poor_performance_start_date: formik.values.from_date,
          poor_performance_end_date: formik.values.to_date,
        }),
        timing_id: Number(formik.values.shift_timing),
        update: 1,
        notice_description: formik.values.notice_description,
      }
      updateUser(entity)
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const stepErrors = error.inner.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path as keyof FormValues] = curr.message
          }
          return acc
        }, {} as { [K in keyof FormValues]?: string })

        formik.setTouched(
          Object.keys(stepErrors).reduce((acc, key) => {
            acc[key as keyof FormValues] = true
            return acc
          }, {} as { [K in keyof FormValues]: any }),
        )

        formik.setErrors(stepErrors)
      } else {
        console.error('Unexpected error:', error)
      }
    }
  }

  const daysOfWeek = [
    { id: 1, value: 'Sunday' },
    { id: 2, value: 'Monday' },
    { id: 3, value: 'Tuesday' },
    { id: 4, value: 'Wednesday' },
    { id: 5, value: 'Thursday' },
    { id: 6, value: 'Friday' },
    { id: 7, value: 'Saturday' },
  ]

  return (
    <div className={classes.root}>
      {isUserUpdating ||
        (isGetEmpoyeementInfoLoader && (
          <Dialog open={isUserUpdating || isGetEmpoyeementInfoLoader} PaperProps={{ style: loaderProps }}>
            <CircularProgress color='secondary' />
          </Dialog>
        ))}
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center', // Center items vertically
            justifyContent: 'space-between', // Space items apart (button left, heading center)
            width: '89.5%', // Set width as per your requirement
            height: '70px', // Set height as per your requirement
            borderRadius: '8px', // Round corners of the box
            marginLeft: '35px', // Center the box horizontally
            padding: '0 20px', // Optional: Add horizontal padding
            opacity: 1, // Adjust opacity as needed
            backgroundColor: 'white',
            marginTop: '20px',
          }}
        >
          {/* Centered heading */}
          <Typography
            sx={{
              fontFamily: styles.FONT_BOLD,
              fontSize: '32px', // Set font size
              fontWeight: 700, // Set font weight
              lineHeight: '38.73px', // Set line height
              textAlign: 'center', // Center align the text
              flexGrow: 1, // Allow the heading to grow and take available space
              margin: '0', // Optional: Remove default margin
              color: '#193C6C',
            }}
          >
            Update {getEmployeeInfo?.first_name ? `${getEmployeeInfo?.first_name}'s` : ''}{' '}
            Employment Info
          </Typography>
          <Box onClick={() => navigate(-1)} sx={{ float: 'right', mt: '0px', cursor: 'pointer' }}>
            <ArrowBack />
          </Box>
        </Box>
        <Box sx={{ mx: 4, my: 1, mt: 3, backgroundColor: '' }}>
          <div>
            <Grid container sx={{ display: 'flex', gap: '20px' }}>
              {/* First Section */}
              <Grid item container xs={7.8} sx={{ display: 'flex', gap: '20px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
                  <Grid item xs={12} sx={{ backgroundColor: 'white', borderRadius: '8px' }}>
                    <Box
                      sx={{
                        borderBottom: '1px solid #193C6C', // Move border to the container
                        width: '100% !important', // Ensure the container takes up the full width
                      }}
                    >
                      <Typography
                        padding={'10px 10px'} // Retain padding inside the Typography
                        sx={{
                          fontFamily: styles.FONT_BOLD,
                          fontSize: '16px', // Use quotes around the size
                          fontWeight: 700, // Use camelCase for font-weight
                          lineHeight: '19.36px', // Use quotes around the line height
                          textAlign: 'left', // Use camelCase for text-align
                          color: '#193C6C',
                        }}
                      >
                        Finance Info
                      </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Grid item xs={5.7}>
                        <Grid item>
                          <InputField
                            size='small'
                            fullWidth
                            id='pan_card_no'
                            name='pan_card_no'
                            label='Pan Number'
                            required={false}
                            value={formik.values.pan_card_no}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item>
                          <SelectField
                            size='small'
                            id='pf_applicable'
                            name='pf_applicable'
                            label='Pf Applicable'
                            select
                            value={formik.values.pf_applicable}
                            onChange={formik.handleChange}
                            sx={style.selectStyle}
                            required={false}
                          >
                            <StyledMenuItem value={1}> Yes </StyledMenuItem>
                            <StyledMenuItem value={0}> No </StyledMenuItem>
                          </SelectField>
                        </Grid>
                        <Grid item>
                          <InputField
                            size='small'
                            fullWidth
                            id='pf_no'
                            name='pf_no'
                            label='PF Number'
                            value={formik.values.pf_no}
                            required={false}
                            onChange={formik.handleChange}
                            disabled={formik.values.pf_applicable === 1 ? false : true}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={5.7}>
                        <Grid item>
                          <Box
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            width='100%' // Adjust height as needed
                          >
                            <InputField
                              size='small'
                              fullWidth
                              id='aadhaar_card_no'
                              name='aadhaar_card_no'
                              label='Aadhaar Number'
                              onChange={(e: any) => {
                                let onlyNums = e.target.value.replace(/[^0-9]/g, '')
                                if (onlyNums.length > 12) {
                                  onlyNums = onlyNums.slice(0, 12)
                                }
                                formik.setFieldValue('aadhaar_card_no', onlyNums)
                              }}
                              value={formik.values.aadhaar_card_no}
                              required={false}
                            />
                          </Box>
                        </Grid>
                        <Grid item>
                          <InputField
                            size='small'
                            required={false}
                            fullWidth
                            id='uan_no'
                            name='uan_no'
                            label='UAN Number'
                            value={formik.values.uan_no}
                            onChange={formik.handleChange}
                            disabled={formik.values.pf_applicable === 1 ? false : true}
                          />
                        </Grid>

                        <Grid item>
                          <SelectField
                            size='small'
                            id='salary_on_hold'
                            name='salary_on_hold'
                            label='Salary On Hold'
                            select
                            value={formik.values.salary_on_hold}
                            onChange={formik.handleChange}
                            sx={style.selectStyle}
                            required={false}
                          >
                            {' '}
                            <StyledMenuItem value={'1'}>Yes</StyledMenuItem>
                            <StyledMenuItem value={'0'}>No</StyledMenuItem>
                          </SelectField>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
                <Grid
                  item
                  xs={12}
                  sx={{ backgroundColor: 'white', borderRadius: '8px', paddingBottom: '15px' }}
                >
                  <Typography
                    padding={'10px 10px'} // Retain padding inside the Typography
                    sx={{
                      fontFamily: styles.FONT_BOLD,
                      fontSize: '16px', // Use quotes around the size
                      fontWeight: 700, // Use camelCase for font-weight
                      lineHeight: '19.36px', // Use quotes around the line height
                      textAlign: 'left', // Use camelCase for text-align
                      borderBottom: '1px solid #193C6C',
                      color: '#193C6C',
                    }}
                  >
                    Service Info
                  </Typography>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xs={5.7}>
                      <SelectField
                        size='small'
                        id='experience'
                        name='experience'
                        label='Experience while joinning'
                        select
                        value={formik.values.experience}
                        onChange={formik.handleChange}
                        sx={style.selectStyle}
                        required={false}
                      >
                        <StyledMenuItem value={'0'}> 0 - 0.5 Year </StyledMenuItem>
                        <StyledMenuItem value={'1'}> 0.5 - 1 Year </StyledMenuItem>
                        <StyledMenuItem value={'2'}> 1 - 2 Year </StyledMenuItem>
                        <StyledMenuItem value={'3'}> 2 - 5 Year </StyledMenuItem>
                        <StyledMenuItem value={'4'}> 5 - 10 Year </StyledMenuItem>
                        <StyledMenuItem value={'5'}> 10+ Year </StyledMenuItem>
                      </SelectField>
                      <SelectField
                        size='small'
                        id='service_agreement'
                        name='service_agreement'
                        label='Service Agreement Period'
                        select
                        value={formik.values.service_agreement}
                        error={
                          formik.touched.service_agreement &&
                          Boolean(formik.errors.service_agreement)
                        }
                        required={true}
                        onChange={formik.handleChange}
                        sx={style.selectStyle}
                      >
                        <StyledMenuItem value={1}> 1 year </StyledMenuItem>
                        <StyledMenuItem value={2}> 1.5 year </StyledMenuItem>
                        <StyledMenuItem value={3}> 2 year </StyledMenuItem>
                      </SelectField>

                      <SelectField
                        select
                        size='small'
                        id='under_probation'
                        label='Under Probation'
                        name='under_probation'
                        required={false}
                        value={formik.values.under_probation}
                        onChange={(e: any) => {
                          if (e.target.value === 0) formik.values.under_probation = 0
                          else formik.values.under_probation = 1
                          return formik.handleChange(e)
                        }}
                      >
                        <StyledMenuItem value=''>Select Probation Period</StyledMenuItem>
                        <StyledMenuItem value={1}>Yes</StyledMenuItem>
                        <StyledMenuItem value={0}>No</StyledMenuItem>
                      </SelectField>

                      <SelectField
                        size='small'
                        id='under_notice'
                        label='Under Notice'
                        select
                        name='under_notice'
                        value={formik.values.under_notice}
                        onChange={(e: any) => {
                          if (e.target.value === 0) formik.values.notice_period = 0
                          else formik.values.notice_period = 1
                          return formik.handleChange(e)
                        }}
                        sx={style.selectStyle}
                        required={false}
                      >
                        <StyledMenuItem value={1}> Yes </StyledMenuItem>
                        <StyledMenuItem value={0}> No </StyledMenuItem>
                      </SelectField>
                      <SelectField
                        sx={{
                          '& .MuiFormLabel-root': {
                            marginTop: '0px !important',
                          },
                        }}
                        size='small'
                        id='relieving_type'
                        name='relieving_type'
                        label='Type Of Relieving'
                        select
                        value={formik.values.relieving_type}
                        onChange={formik.handleChange}
                        disabled={formik.values.under_notice <= 0}
                        required={false}
                      >
                        <StyledMenuItem value={0}>Select</StyledMenuItem>
                        <StyledMenuItem value={1}>Absconded</StyledMenuItem>
                        <StyledMenuItem value={2}>Relieved</StyledMenuItem>
                        <StyledMenuItem value={3}>Terminated</StyledMenuItem>
                      </SelectField>
                      <Grid item>
                        <SelectField
                          size='small'
                          id='referred_by'
                          name='referred_by'
                          label='Referred By'
                          select
                          value={formik.values.referred_by}
                          onChange={formik.handleChange}
                          required={false}
                          sx={style.selectStyle}
                        >
                          {props.AllEmpList?.map((data: any, index: number) => {
                            return (
                              <StyledMenuItem key={index} value={data.userId}>
                                {data.name}
                              </StyledMenuItem>
                            )
                          })}
                        </SelectField>
                      </Grid>
                    </Grid>

                    <Grid item xs={5.8}>
                      <SelectField
                        size='small'
                        id='emp_type'
                        name='emp_type'
                        label='Emp Type'
                        required={false}
                        select
                        value={formik.values.emp_type}
                        onChange={formik.handleChange}
                        sx={style.selectStyle}
                      >
                        <StyledMenuItem value={1}>Salary</StyledMenuItem>
                        <StyledMenuItem value={2}>Hourly</StyledMenuItem>
                      </SelectField>

                      <SelectField
                        size='small'
                        id='service_agreement_amount'
                        name='service_agreement_amount'
                        label='Service Agreement Amount (In Rs.)'
                        select
                        required={false}
                        value={formik.values.service_agreement_amount}
                        onChange={formik.handleChange}
                        sx={style.selectStyle}
                      >
                        <StyledMenuItem value={'0'}> NA </StyledMenuItem>
                        <StyledMenuItem value={'200000'}> 2,00,000 </StyledMenuItem>
                        <StyledMenuItem value={'10000'}> 1,00,000 </StyledMenuItem>
                        <StyledMenuItem value={'75000'}> 75,000 </StyledMenuItem>
                        <StyledMenuItem value={'50000'}> 50,000 </StyledMenuItem>
                        <StyledMenuItem value={'25000'}> 25,000 </StyledMenuItem>
                      </SelectField>

                      <SelectField
                        size='small'
                        id='probation_period'
                        name='probation_period'
                        label='Probation Period'
                        select
                        value={formik.values.probation_period}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.probation_period && Boolean(formik.errors.probation_period)
                        }
                        disabled={formik.values.under_probation <= 0}
                        required={false}
                      >
                        {formik.values.under_probation <= 0 && (
                          <StyledMenuItem value={0}>0 Months</StyledMenuItem>
                        )}
                        <StyledMenuItem value={3}>3 Months</StyledMenuItem>
                        <StyledMenuItem value={6}>6 Months</StyledMenuItem>
                        <StyledMenuItem value={9}>9 Months</StyledMenuItem>
                        <StyledMenuItem value={12}>1 Year</StyledMenuItem>
                      </SelectField>
                      <SelectField
                        size='small'
                        id='notice_period'
                        name='notice_period'
                        label='Notice Period'
                        select
                        value={formik.values.notice_period}
                        onChange={formik.handleChange}
                        disabled={formik.values.under_notice <= 0}
                        required={false}
                      >
                        <StyledMenuItem value={0}>NA</StyledMenuItem>
                        <StyledMenuItem value={3}>3 Months</StyledMenuItem>
                        <StyledMenuItem value={6}>6 Months</StyledMenuItem>
                        <StyledMenuItem value={9}>9 Months</StyledMenuItem>
                        <StyledMenuItem value={12}>1 Year</StyledMenuItem>
                      </SelectField>
                      <TextField
                        multiline
                        fullWidth
                        variant='outlined'
                        name='notice_description'
                        label='Notice Description'

                        value={formik.values.notice_description}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: Boolean(formik.values.notice_description), // Ensures label floats if there is a value
                        }}
                        sx={{
                          // margin: '0 0 0 14px',
                          marginLeft: '14px',
                          '& .MuiInputBase-inputMultiline': {
                            position: 'relative',
                            left: '12px',
                            top: '6px',
                          },
                          '& .MuiOutlinedInput-root': {
                            width: '92%',
                            fontSize: '12px',
                            fontFamily: 'Montserrat-Medium',
                            borderRadius: '30px',
                            minHeight: '30px',
                            padding: '6px 8px',
                            alignItems: 'center',
                          },
                          '& .MuiOutlinedInput-input': {
                            fontSize: '12px',
                            fontFamily: 'Montserrat-Medium',
                            minHeight: '30px',
                            padding: '0',
                            boxSizing: 'border-box',
                            lineHeight: '1.2',
                            '& .MuiFormLabel-asterisk': {
                              color: 'white',
                            },
                          },
                          '& .MuiInputLabel-root': {
                          
                            fontSize: '13px !important',
                            fontFamily: 'Montserrat-Medium !important',
                            '& .MuiFormLabel-asterisk': {
                              color: 'white',
                            },
                          },
                          '& textarea': {
                            fontSize: '12px',
                            fontFamily: 'Montserrat-Medium',
                            height: 'auto',
                            width: '95%',
                            minHeight: '35px',
                            overflowY: 'auto',
                          },
                        }}
                        disabled={formik.values.under_notice <= 0}
                      />
                      <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DatePicker']}
                            sx={{ width: '100%', marginTop: '-22px' }}
                          >
                            <Box sx={{ width: '92%' }}>
                              <DatePicker
                                sx={{
                                  border: 'none',
                                  '& .MuiOutlinedInput-root': {
                                    marginLeft: '15px',
                                    borderRadius: '20px',
                                    height: '40px',
                                    fontSize: '13px', // Adjust the font size here
                                    fontFamily: styles.FONT_MEDIUM,
                                  },
                                  '& .MuiInputLabel-root': {
                                    marginLeft: '15px',
                                    fontSize: '13px', // Adjust the font size here
                                    fontFamily: styles.FONT_MEDIUM,
                                    marginTop: '-5px',
                                    '& .MuiFormLabel-asterisk': {
                                      color: 'red',
                                    },
                                  },
                                }}
                                label='Hire Date'
                                value={
                                  formik.values.hire_date ? dayjs(formik.values.hire_date) : null
                                }
                                onChange={(date: any) => {
                                  formik.setFieldValue('hire_date', date?.format('YYYY-MM-DD'))
                                }}
                                slotProps={{
                                  textField: {
                                    error:
                                      formik.touched.hire_date && Boolean(formik.errors.hire_date),
                                  },
                                }}
                              />
                            </Box>
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>

              {/* Second Section */}
              <Grid
                item
                xs={3.8}
                sx={{ backgroundColor: 'white', borderRadius: '8px', border: 'none' }}
              >
                <Typography
                  padding={'10px 10px'} // Retain padding inside the Typography
                  sx={{
                    fontFamily: styles.FONT_BOLD,
                    fontSize: '16px', // Use quotes around the size
                    fontWeight: 700, // Use camelCase for font-weight
                    lineHeight: '19.36px', // Use quotes around the line height
                    textAlign: 'left', // Use camelCase for text-align
                    borderBottom: '1px solid #193C6C',
                    color: '#193C6C',
                  }}
                >
                  Other Info
                </Typography>{' '}
                <Box>
                  <Grid item>
                    <SelectField
                      sx={{
                        '& .MuiFormLabel-root': {
                          marginTop: '0px !important',
                        },
                      }}
                      size='small'
                      id='first_performance_review'
                      name='first_performance_review'
                      label='First Performance Review'
                      select
                      value={formik.values.first_performance_review}
                      onChange={formik.handleChange}
                      required={false}
                    >
                      <StyledMenuItem value={'1'}>Yes</StyledMenuItem>
                      <StyledMenuItem value={'0'}>No</StyledMenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item>
                    <SelectField
                      size='small'
                      id='first_weekend'
                      name='first_weekend'
                      label='First Weekend'
                      select
                      value={formik.values.first_weekend}
                      onChange={formik.handleChange}
                      sx={style.selectStyle}
                      error={formik.touched.first_weekend && Boolean(formik.errors.first_weekend)}
                      required={true}
                    >
                      <StyledMenuItem value={'Sunday'}> Sunday </StyledMenuItem>
                      <StyledMenuItem value={'Monday'}> Monday </StyledMenuItem>
                      <StyledMenuItem value={'Tuesday'}> Tuesday </StyledMenuItem>
                      <StyledMenuItem value={'Wednesday'}> Wednesday </StyledMenuItem>
                      <StyledMenuItem value={'Thursday'}> Thursday </StyledMenuItem>
                      <StyledMenuItem value={'Friday'}> Friday </StyledMenuItem>
                      <StyledMenuItem value={'Saturday'}> Saturday </StyledMenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item>
                    <SelectField
                      size='small'
                      id='second_weekend'
                      name='second_weekend'
                      label='Second Weekend'
                      select
                      value={formik.values.second_weekend}
                      onChange={formik.handleChange}
                      sx={style.selectStyle}
                    >
                      <StyledMenuItem value={'Sunday'}> Sunday </StyledMenuItem>
                      <StyledMenuItem value={'Monday'}> Monday </StyledMenuItem>
                      <StyledMenuItem value={'Tuesday'}> Tuesday </StyledMenuItem>
                      <StyledMenuItem value={'Wednesday'}> Wednesday </StyledMenuItem>
                      <StyledMenuItem value={'Thursday'}> Thursday </StyledMenuItem>
                      <StyledMenuItem value={'Friday'}> Friday </StyledMenuItem>
                      <StyledMenuItem value={'Saturday'}> Saturday </StyledMenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item>
                    <SelectField
                      size='small'
                      id='isIDCard'
                      name='isIDCard'
                      label='Id Card Issued'
                      select
                      value={formik.values.isIDCard}
                      onChange={formik.handleChange}
                      required={false}
                      sx={style.selectStyle}
                    >
                      <StyledMenuItem value={'1'}>Yes</StyledMenuItem>
                      <StyledMenuItem value={'0'}>No</StyledMenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item>
                    <SelectField
                      size='small'
                      id='poor_performance_review'
                      name='poor_performance_review'
                      label='Poor Performance Review'
                      select
                      value={formik.values.poor_performance_review}
                      onChange={formik.handleChange}
                      required={false}
                      sx={style.selectStyle}
                    >
                      <StyledMenuItem value={'1'}>Yes</StyledMenuItem>
                      <StyledMenuItem value={'0'}>No</StyledMenuItem>
                    </SelectField>
                  </Grid>
                  {Number(formik.values.poor_performance_review) !== 0 && (
                    <>
                      <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DatePicker']}
                            sx={{ width: '100%', marginTop: '-8px', marginBottom: '-16px' }}
                          >
                            <Box sx={{ width: '92%' }}>
                              <DatePicker
                                sx={{
                                  border: 'none',
                                  '& .MuiOutlinedInput-root': {
                                    marginLeft: '15px',
                                    borderRadius: '20px',
                                    height: '40px',
                                    fontSize: '13px', // Adjust the font size here
                                    fontFamily: styles.FONT_MEDIUM,
                                  },
                                  '& .MuiInputLabel-root': {
                                    marginLeft: '15px',
                                    fontSize: '13px', // Adjust the font size here
                                    fontFamily: styles.FONT_MEDIUM,
                                    marginTop: '-5px',
                                    '& .MuiFormLabel-asterisk': {
                                      color: 'red',
                                    },
                                  },
                                }}
                                label='Start Date'
                                value={
                                  formik.values.from_date ? dayjs(formik.values.from_date) : null
                                }
                                onChange={(date: any) => {
                                  formik.setFieldValue('from_date', date?.format('YYYY-MM-DD'))
                                }}
                                slotProps={{
                                  textField: {
                                    error:
                                      formik.touched.from_date && Boolean(formik.errors.from_date),
                                  },
                                }}
                              />
                            </Box>
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>

                      <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DatePicker']}
                            sx={{ width: '100%', marginTop: '-8px', marginBottom: '-16px' }}
                          >
                            <Box sx={{ width: '92%' }}>
                              <DatePicker
                                sx={{
                                  border: 'none',
                                  '& .MuiOutlinedInput-root': {
                                    marginLeft: '15px',
                                    borderRadius: '20px',
                                    height: '40px',
                                    fontSize: '13px', // Adjust the font size here
                                    fontFamily: styles.FONT_MEDIUM,
                                  },
                                  '& .MuiInputLabel-root': {
                                    marginLeft: '15px',
                                    fontSize: '13px', // Adjust the font size here
                                    fontFamily: styles.FONT_MEDIUM,
                                    marginTop: '-5px',
                                    '& .MuiFormLabel-asterisk': {
                                      color: 'red',
                                    },
                                  },
                                }}
                                label='End Date'
                                value={formik.values.to_date ? dayjs(formik.values.to_date) : null}
                                onChange={(date: any) => {
                                  formik.setFieldValue('to_date', date?.format('YYYY-MM-DD'))
                                }}
                                slotProps={{
                                  textField: {
                                    error: formik.touched.to_date && Boolean(formik.errors.to_date),
                                  },
                                }}
                              />
                            </Box>
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                    </>
                  )}
                  <Grid item>
                    <SelectField
                      size='small'
                      id='under_maternity'
                      name='under_maternity'
                      label='Under Maternity'
                      select
                      value={formik.values.under_maternity}
                      required={false}
                      onChange={formik.handleChange}
                    >
                      <StyledMenuItem value={'1'}>Yes</StyledMenuItem>
                      <StyledMenuItem value={'0'}>No</StyledMenuItem>
                    </SelectField>
                  </Grid>

                  <Grid item>
                    <SelectField
                      size='small'
                      id='isMediclaim'
                      label='Mediclaim Issued'
                      select
                      name='isMediclaim'
                      value={formik.values.isMediclaim}
                      onChange={formik.handleChange}
                      sx={style.selectStyle}
                      required={false}
                    >
                      <StyledMenuItem value={'1'}> Yes </StyledMenuItem>
                      <StyledMenuItem value={'0'}> No </StyledMenuItem>
                    </SelectField>
                  </Grid>

                  <Grid item>
                    <InputField
                      size='small'
                      id='mediclaim_no'
                      name='mediclaim_no'
                      label='Mediclam No'
                      value={formik.values.mediclaim_no}
                      required={false}
                      onChange={formik.handleChange}
                      disabled={formik.values.isMediclaim != '1'}
                    />{' '}
                  </Grid>
                  <Grid item>
                    <InputField
                      size='small'
                      id='passport_no'
                      name='passport_no'
                      label='Passport No'
                      value={formik.values.passport_no}
                      required={false}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item>
                    <SelectField
                      size='small'
                      id='tshirt_size'
                      name='tshirt_size'
                      label='Tshirt Size'
                      value={formik.values.tshirt_size}
                      select
                      onChange={formik.handleChange}
                      sx={style.selectStyle}
                      required={false}
                    >
                      <StyledMenuItem value={0} disabled>
                        {' '}
                        Select{' '}
                      </StyledMenuItem>
                      <StyledMenuItem value={1}> S </StyledMenuItem>
                      <StyledMenuItem value={2}> M </StyledMenuItem>
                      <StyledMenuItem value={3}> L </StyledMenuItem>
                      <StyledMenuItem value={4}> XL </StyledMenuItem>
                      <StyledMenuItem value={5}> XXL </StyledMenuItem>
                      <StyledMenuItem value={6}> XXXL </StyledMenuItem>
                    </SelectField>
                  </Grid>
                  {/* <Grid item>
                    <InputField
                      size='small'
                      fullWidth
                      id='time_to_visit'
                      name='time_to_visit'
                      label='Time To Visit'
                      type='time'
                      value={formik.values.time_to_visit || ''}
                      onChange={formik.handleChange}
                      InputLabelProps={{ shrink: true }}
                      required={false}
                    />
                  </Grid>
                  <Grid item>
                    <SelectField
                      size='small'
                      id='day_to_visit'
                      name='day_to_visit'
                      label='Day To Visit'
                      select
                      value={formik.values.day_to_visit}
                      onChange={formik.handleChange}
                      error={formik.touched.day_to_visit && Boolean(formik.errors.day_to_visit)}
                      sx={style.selectStyle}
                      required={false}
                    >
                      {daysOfWeek?.map((day: any) => (
                        <StyledMenuItem key={day.id} value={day.value}>
                          {day.value}
                        </StyledMenuItem>
                      ))}
                    </SelectField>
                  </Grid> */}
                  <Grid item>
                    <SelectField
                      size='small'
                      id='shift_timing'
                      name='shift_timing'
                      label='Timing'
                      value={formik.values.shift_timing}
                      select
                      onChange={formik.handleChange}
                      sx={style.selectStyle}
                      required={false}
                    >
                      {props?.timingData?.map((data: any) => {
                        return (
                          <StyledMenuItem value={data?.id}>
                            {' '}
                            {moment(data?.timing).utc().format('h:mm A')}
                          </StyledMenuItem>
                        )
                      })}
                    </SelectField>
                  </Grid>

                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={['DatePicker']}
                        sx={{ width: '100%', marginTop: '-8px' }}
                      >
                        <Box sx={{ width: '92%' }}>
                          <DatePicker
                            sx={{
                              border: 'none',
                              '& .MuiOutlinedInput-root': {
                                marginLeft: '15px',
                                borderRadius: '20px',
                                height: '40px',
                                fontSize: '13px', // Adjust the font size here
                                fontFamily: styles.FONT_MEDIUM,
                              },
                              '& .MuiInputLabel-root': {
                                marginLeft: '15px',
                                fontSize: '13px', // Adjust the font size here
                                fontFamily: styles.FONT_MEDIUM,
                                marginTop: '-5px',
                                '& .MuiFormLabel-asterisk': {
                                  color: 'red',
                                },
                              },
                            }}
                            label='Effective Date'
                            value={
                              formik.values.start_date ? dayjs(formik.values.start_date) : null
                            }
                            onChange={(date: any) => {
                              formik.setFieldValue('start_date', date?.format('YYYY-MM-DD'))
                            }}
                            slotProps={{
                              textField: {
                                error:
                                  formik.touched.start_date && Boolean(formik.errors.start_date),
                              },
                            }}
                          />
                        </Box>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <div
              style={{
                marginTop: '24px',
                marginRight: '15px',
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Button
                sx={{
                  fontSize: '16px !important',
                  fontFamily: `${styles.FONT_BOLD}!important`,
                  width: '20%',
                  borderRadius: '20px !important',
                  cursor: 'pointer',
                  height: '40px', // Set height as per your requirement
                  padding: '14px 28px',
                  opacity: 1, // Adjust opacity as needed
                  background: '#E2E2E2',
                  color: '#193C6C',
                  '&:hover': {
                    background: '#E2E2E2',
                    color: '#000000',
                  },
                }}
                variant='contained'
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <CommonButton variant='contained' color='primary' onClick={handleSubmit}>
                FINISH
              </CommonButton>
            </div>
          </div>
        </Box>
      </form>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isGetEmpoyeementInfoLoader: fetchUserDetailsUI.fetchUserData(state).isGetEmpoyeementInfoLoader,
    employeementInfoData: fetchUserDetailsEntities.fetchUserData(state).getEmployeementInfo,
    isEmployeementInfoUpdated: fetchUserDetailsUI.fetchUserData(state).isGetEmpoyeementInfo,
    designationData: employeePortalEntity.getEmployeePortal(state).getDesignationData,
    designationBandData: employeePortalEntity.getEmployeePortal(state).getDesignationBandData,
    AllEmpList: projectManagementEntity.getAllProjectManagement(state).getAllEmpList,
    getUserDetails: fetchUserDetailsEntities.fetchUserData(state).fetchUser,
    allRoles: fetchUserDetailsEntities.fetchUserData(state).fetchAllRole,
    allStates: fetchUserDetailsEntities.fetchUserData(state).fetchAllState,
    allCountries: fetchUserDetailsEntities.fetchUserData(state).fetchAllCountry,
    allFloors: fetchUserDetailsEntities.fetchUserData(state).fetchAllFloor,
    allWorkstations: fetchUserDetailsEntities.fetchUserData(state).fetchAllWorkstation,
    allLocation: fetchUserDetailsEntities.fetchUserData(state).fetchAllLocation,
    allClientLocation: fetchUserDetailsEntities.fetchUserData(state).fetchAllClientLocation,
    isUserCreateFailed: fetchUserDetailsUI.fetchUserData(state).isUserCreateFailed,
    isUserUpdated: fetchUserDetailsUI.fetchUserData(state).isUserUpdated,
    isUserUpdating: fetchUserDetailsUI.fetchUserData(state).isUserUpdating,
    isUserUpdateFailed: fetchUserDetailsUI.fetchUserData(state).isUserUpdateFailed,
    userImage: fetchUserDetailsEntities.fetchUserData(state).getUserImage,
    timingData: employeePortalEntity.getEmployeePortal(state).getTimingData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchDesignationData: () => dispatch(fetchDesignation.request()),
    fetchDesignationBandData: () => dispatch(fetchDesignationBand.request()),
    fetchAllEmpAPI: () => dispatch(fetchWorkingEmp.request()),
    getUserImage: (data: any) => dispatch(getUserImage.request(data)),
    resetUserImage: () => {
      dispatch(getUserImage.reset())
    },
    uploadUserImg: (data: any) => dispatch(uploadUserImg.request({ data })),
    createUser: (data: any) => dispatch(createUser.request({ data })),

    fetchEmployeementInfo: (data: any) => dispatch(fetchEmployeeInfo.request({ data })),
    resetEmployeementInfo: () => dispatch(fetchEmployeeInfo.reset()),
    resetCreateUser: () => {
      dispatch(createUser.reset())
    },
    fetchAllRole: () => dispatch(fetchAllRoles.request()),
    fetchAllState: () => dispatch(fetchAllStates.request()),
    fetchAllCountry: () => dispatch(fetchAllCountries.request()),
    fetchAllFloor: () => dispatch(fetchAllFloors.request()),
    fetchAllWorkstation: () => dispatch(fetchAllWorkstation.request()),
    fetchAllLocation: () => dispatch(fetchAllLocation.request()),
    updateUser: (data: any) => dispatch(updateUser.request({ data })),
    resetUpdateUser: () => {
      dispatch(updateUser.reset())
    },
    fetchAllClientLocation: () => dispatch(fetchAllClientLocation.request()),
    fetchTimingData: () => dispatch(fetchTiming.request()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EmpoloyeementInfo)
