import { call, takeLatest } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import * as ApiService from '../services'
import { sendPayload, sendPayloadFailure } from './helper'
import {
  CREATE_USER,
  FETCH_ALL_COUNTRY,
  FETCH_ALL_FLOOR,
  FETCH_ALL_LOCATION,
  FETCH_ALL_ROLE,
  FETCH_ALL_STATE,
  FETCH_ALL_USER_LIST,
  FETCH_ALL_WORKSTATION,
  UPLOAD_USER_IMG,
  UPDATE_USER,
  FETCH_ALL_CLIENT_LOCATION,
  GET_USER_IMAGE,
  GET_EXPECTED_JOINERS_IMAGE,
  DELETE_USER,
  ADD_NEW_JOINERS,
  UPDATE_NEW_JOINERS,
  GET_NEW_JOINERS_DETAILS,
  UPLOAD_CERTIFICATE,
  GET_EMPLOYEE,
  GET_EMPLOYEEMENT_INFO,
  GET_USER_TECHNOLOGY,
  GET_GRADE_LABLE,
  GET_CERTIFICATE_BY_SPLIT,
  GET_CERTI_PERCENTAGE,
  GET_USER_STATICS,
  GET_COUNTRY_DISTRIBUTION,
  ADD_BANK_INFO,
  ADD_BACKGROUND_INFO,
  EMP_SALARY_SLIP,
  EMP_COMPENSATION,
  DOWNLOAD_DOCUMENT,
  FETCH_BACKGROUND_INFO,
  FETCH_LEAVE_TYPE,
  CREATE_LEAVE,
  UPDATE_LEAVE,
  DELETE_LEAVE,
  PAYROLL_DROPDOWN_TYPE,
  CREATE_PAYROLL,
  GET_PAYROLL,
} from '../actions/actiontypes'
const { REQUEST } = actionTypes

function* uploadUserImg(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.uploadUserImg,
      data.data,
    )
    yield sendPayload(apiResponse, UPLOAD_USER_IMG)
  } catch (e) {
    yield sendPayloadFailure(e, UPLOAD_USER_IMG)
  }
}

function* getUserImage(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.getUserImage, data)
    yield sendPayload(apiResponse, GET_USER_IMAGE)
  } catch (e) {
    yield sendPayloadFailure(e, GET_USER_IMAGE)
  }
}

function* getExpectedJoinersImages(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getExpectedJoinersImage,
      data,
    )
    yield sendPayload(apiResponse, GET_EXPECTED_JOINERS_IMAGE)
  } catch (e) {
    yield sendPayloadFailure(e, GET_EXPECTED_JOINERS_IMAGE)
  }
}

function* getEmployee(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getEmployeePoint,
      data,
    )
    yield sendPayload(apiResponse, GET_EMPLOYEE)
  } catch (e) {
    yield sendPayloadFailure(e, GET_EMPLOYEE)
  }
}

function* getEmployeementInfo(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getEmployeementInfoApiCall,
      data,
    )
    yield sendPayload(apiResponse, GET_EMPLOYEEMENT_INFO)
  } catch (e) {
    yield sendPayloadFailure(e, GET_EMPLOYEEMENT_INFO)
  }
}

function* getUserTechnologyApiCall(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getUserTechnologyApiCall,
      data.date,
    )
    yield sendPayload(apiResponse, GET_USER_TECHNOLOGY)
  } catch (e) {
    yield sendPayloadFailure(e, GET_USER_TECHNOLOGY)
  }
}

function* getPayrollAPI(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getPayroll,
      data.data,
    )
    yield sendPayload(apiResponse, GET_PAYROLL)
  } catch (e) {
    yield sendPayloadFailure(e, GET_PAYROLL)
  }
}

function* deleteUser(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.deleteUser,
      data.userid,
    )
    yield sendPayload(apiResponse, DELETE_USER)
  } catch (e) {
    yield sendPayloadFailure(e, DELETE_USER)
  }
}

function* createBackgroundInfo(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createBackgroundInfo,
      data.data,
    )
    yield sendPayload(apiResponse, ADD_BACKGROUND_INFO)
  } catch (e) {
    yield sendPayloadFailure(e, ADD_BACKGROUND_INFO)
  }
}

function* getBackgroundInfo(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getBackgroundInfo,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_BACKGROUND_INFO)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_BACKGROUND_INFO)
  }
}

function* createUser(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.createUser,
      data.data,
    )
    yield sendPayload(apiResponse, CREATE_USER)
  } catch (e) {
    yield sendPayloadFailure(e, CREATE_USER)
  }
}

function* addNewJoiners(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.addNewJoiners,
      data.data,
    )
    yield sendPayload(apiResponse, ADD_NEW_JOINERS)
  } catch (e) {
    yield sendPayloadFailure(e, ADD_NEW_JOINERS)
  }
}

function* addBankInfo(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.addBankInfoApi,
      data.data,
    )
    yield sendPayload(apiResponse, ADD_BANK_INFO)
  } catch (e) {
    yield sendPayloadFailure(e, ADD_BANK_INFO)
  }
}

function* uploadCertificate(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.uploadCertificate,
      data.data,
    )
    yield sendPayload(apiResponse, UPLOAD_CERTIFICATE)
  } catch (e) {
    yield sendPayloadFailure(e, UPLOAD_CERTIFICATE)
  }
}

function* updateNewJoiners(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.updateNewJoiners,
      data.data,
    )
    yield sendPayload(apiResponse, UPDATE_NEW_JOINERS)
  } catch (e) {
    yield sendPayloadFailure(e, UPDATE_NEW_JOINERS)
  }
}

function* getNewJoinersDetails(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getNewJoinersDetails,
      data.data,
    )
    yield sendPayload(apiResponse, GET_NEW_JOINERS_DETAILS)
  } catch (e) {
    yield sendPayloadFailure(e, GET_NEW_JOINERS_DETAILS)
  }
}

function* getGradeLabel() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getGradeLabelApiCall,
    )
    yield sendPayload(apiResponse, GET_GRADE_LABLE)
  } catch (e) {
    yield sendPayloadFailure(e, GET_GRADE_LABLE)
  }
}

function* getUserStatisticsApiCall() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getUserStatisticsApiCall,
    )
    yield sendPayload(apiResponse, GET_USER_STATICS)
  } catch (e) {
    yield sendPayloadFailure(e, GET_USER_STATICS)
  }
}

function* getCountryDistributionApiCall(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getCountryDistributionApiCall,
      data.date,
    )
    yield sendPayload(apiResponse, GET_COUNTRY_DISTRIBUTION)
  } catch (e) {
    yield sendPayloadFailure(e, GET_COUNTRY_DISTRIBUTION)
  }
}

function* getCertificationWiseSplitUp(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getCertificationWiseSplitUpApiCall,
      data.date,
    )
    yield sendPayload(apiResponse, GET_CERTIFICATE_BY_SPLIT)
  } catch (e) {
    yield sendPayloadFailure(e, GET_CERTIFICATE_BY_SPLIT)
  }
}

function* getCertificationPercentage(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getCertiPercentageApiCall,
      data.date,
    )
    yield sendPayload(apiResponse, GET_CERTI_PERCENTAGE)
  } catch (e) {
    yield sendPayloadFailure(e, GET_CERTI_PERCENTAGE)
  }
}

function* fetchAllRole() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.fetchAllRoles)
    yield sendPayload(apiResponse, FETCH_ALL_ROLE)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ALL_ROLE)
  }
}

function* fetchAllState() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.fetchAllState)
    yield sendPayload(apiResponse, FETCH_ALL_STATE)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ALL_STATE)
  }
}

function* fetchAllCountry() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.fetchAllCountry)
    yield sendPayload(apiResponse, FETCH_ALL_COUNTRY)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ALL_COUNTRY)
  }
}

function* fetchAllFloor() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.fetchAllFloors)
    yield sendPayload(apiResponse, FETCH_ALL_FLOOR)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ALL_FLOOR)
  }
}

function* fetchAllWorkstation() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.fetchAllWorkstation,
    )
    yield sendPayload(apiResponse, FETCH_ALL_WORKSTATION)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ALL_WORKSTATION)
  }
}

function* fetchAllLocation() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(ApiService.fetchAllLocation)
    yield sendPayload(apiResponse, FETCH_ALL_LOCATION)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ALL_LOCATION)
  }
}

function* fetchAllClientLocation() {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.fetchAllClietLocation,
    )
    yield sendPayload(apiResponse, FETCH_ALL_CLIENT_LOCATION)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ALL_CLIENT_LOCATION)
  }
}

function* getAllUserList(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getAllUserList,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_ALL_USER_LIST)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_ALL_USER_LIST)
  }
}

function* updateUser(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.updateUser,
      data.data,
    )
    yield sendPayload(apiResponse, UPDATE_USER)
  } catch (e) {
    yield sendPayloadFailure(e, UPDATE_USER)
  }
}

function* getEmpSalarySlip(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getEmpSalarySlip,
      data.data,
    )
    yield sendPayload(apiResponse, EMP_SALARY_SLIP)
  } catch (e) {
    yield sendPayloadFailure(e, EMP_SALARY_SLIP)
  }
}

function* empCompensation(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getEmpCompensation,
      data.data,
    )
    yield sendPayload(apiResponse, EMP_COMPENSATION)
  } catch (e) {
    yield sendPayloadFailure(e, EMP_COMPENSATION)
  }
}

function* empDocumentDownoad(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getEmpDocumentDownload,
      data.data,
    )
    yield sendPayload(apiResponse, DOWNLOAD_DOCUMENT)
  } catch (e) {
    yield sendPayloadFailure(e, DOWNLOAD_DOCUMENT)
  }
}

function* getLeavesType(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getLeavesType,
      data.data,
    )
    yield sendPayload(apiResponse, FETCH_LEAVE_TYPE)
  } catch (e) {
    yield sendPayloadFailure(e, FETCH_LEAVE_TYPE)
  }
}

function* createLeaves(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.addLeaveApi,
      data.data,
    )
    yield sendPayload(apiResponse, CREATE_LEAVE)
  } catch (e) {
    yield sendPayloadFailure(e, CREATE_LEAVE)
  }
}

function* addPayrollApi(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.addPayrollApi,
      data.data,
    )
    yield sendPayload(apiResponse, CREATE_PAYROLL)
  } catch (e) {
    yield sendPayloadFailure(e, CREATE_PAYROLL)
  }
}

function* updateLeaveApi(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.updateLeaveApi,
      data.data,
    )
    yield sendPayload(apiResponse, UPDATE_LEAVE)
  } catch (e) {
    yield sendPayloadFailure(e, UPDATE_LEAVE)
  }
}

function* deleteLeaveApi(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.deleteLeaveApi,
      data.data,
    )
    yield sendPayload(apiResponse, DELETE_LEAVE)
  } catch (e) {
    yield sendPayloadFailure(e, DELETE_LEAVE)
  }
}

function* getpayrollDropDown(data: any) {
  try {
    const apiResponse: Generator<string, number, string> = yield call(
      ApiService.getpayrollDropDown,
      data.data,
    )
    yield sendPayload(apiResponse, PAYROLL_DROPDOWN_TYPE)
  } catch (e) {
    yield sendPayloadFailure(e, PAYROLL_DROPDOWN_TYPE)
  }
}

export const sagas = {
  watchUploadUserImg: takeLatest(actionTypes.UPLOAD_USER_IMG[REQUEST], uploadUserImg),
  watchUploadCertificate: takeLatest(actionTypes.UPLOAD_CERTIFICATE[REQUEST], uploadCertificate),
  watchgetUserImg: takeLatest(actionTypes.GET_USER_IMAGE[REQUEST], getUserImage),
  watchgetExpectedJoinersImg: takeLatest(
    actionTypes.GET_EXPECTED_JOINERS_IMAGE[REQUEST],
    getExpectedJoinersImages,
  ),
  watchCreateUser: takeLatest(actionTypes.CREATE_USER[REQUEST], createUser),
  watchAddNewJoiners: takeLatest(actionTypes.ADD_NEW_JOINERS[REQUEST], addNewJoiners),
  watchAddBankInfo: takeLatest(actionTypes.ADD_BANK_INFO[REQUEST], addBankInfo),
  watchUpdateNewJoiners: takeLatest(actionTypes.UPDATE_NEW_JOINERS[REQUEST], updateNewJoiners),
  watchGetNewJoinersDetails: takeLatest(
    actionTypes.GET_NEW_JOINERS_DETAILS[REQUEST],
    getNewJoinersDetails,
  ),
  watchFetchUserRole: takeLatest(actionTypes.FETCH_ALL_ROLE[REQUEST], fetchAllRole),
  watchFetchUserState: takeLatest(actionTypes.FETCH_ALL_STATE[REQUEST], fetchAllState),
  watchFetchUserCountry: takeLatest(actionTypes.FETCH_ALL_COUNTRY[REQUEST], fetchAllCountry),
  watchFetchUserFloor: takeLatest(actionTypes.FETCH_ALL_FLOOR[REQUEST], fetchAllFloor),
  watchFetchUserWorkstation: takeLatest(
    actionTypes.FETCH_ALL_WORKSTATION[REQUEST],
    fetchAllWorkstation,
  ),
  watchFetchUserLocation: takeLatest(actionTypes.FETCH_ALL_LOCATION[REQUEST], fetchAllLocation),
  watchFetchUserClientLocation: takeLatest(
    actionTypes.FETCH_ALL_CLIENT_LOCATION[REQUEST],
    fetchAllClientLocation,
  ),
  watchFetchUserAllList: takeLatest(actionTypes.FETCH_ALL_USER_LIST[REQUEST], getAllUserList),
  watchUpdateUser: takeLatest(actionTypes.UPDATE_USER[REQUEST], updateUser),
  watchDeleteUser: takeLatest(actionTypes.DELETE_USER[REQUEST], deleteUser),
  watchEmployeeRole: takeLatest(actionTypes.GET_EMPLOYEE[REQUEST], getEmployee),
  watchEmployeementInfo: takeLatest(
    actionTypes.GET_EMPLOYEEMENT_INFO[REQUEST],
    getEmployeementInfo,
  ),
  watchUserTechnology: takeLatest(
    actionTypes.GET_USER_TECHNOLOGY[REQUEST],
    getUserTechnologyApiCall,
  ),
  watchGradeLabel: takeLatest(actionTypes.GET_GRADE_LABLE[REQUEST], getGradeLabel),
  watchGetCertificationWiseSplitUp: takeLatest(
    actionTypes.GET_CERTIFICATE_BY_SPLIT[REQUEST],
    getCertificationWiseSplitUp,
  ),
  watchCertificationPercentage: takeLatest(
    actionTypes.GET_CERTI_PERCENTAGE[REQUEST],
    getCertificationPercentage,
  ),
  watchUserStatics: takeLatest(actionTypes.GET_USER_STATICS[REQUEST], getUserStatisticsApiCall),
  watchCountryCountrybution: takeLatest(
    actionTypes.GET_COUNTRY_DISTRIBUTION[REQUEST],
    getCountryDistributionApiCall,
  ),
  watchEmpBgInfo: takeLatest(actionTypes.ADD_BACKGROUND_INFO[REQUEST], createBackgroundInfo),
  watchEmpSalarySlip: takeLatest(actionTypes.EMP_SALARY_SLIP[REQUEST], getEmpSalarySlip),
  watchEmpCompensation: takeLatest(actionTypes.EMP_COMPENSATION[REQUEST], empCompensation),
  watchEmpDocumentDownload: takeLatest(actionTypes.DOWNLOAD_DOCUMENT[REQUEST], empDocumentDownoad),
  watchGetEmpBgInfo: takeLatest(actionTypes.FETCH_BACKGROUND_INFO[REQUEST], getBackgroundInfo),
  watchGetLeavesType: takeLatest(actionTypes.FETCH_LEAVE_TYPE[REQUEST], getLeavesType),
  watchCreateLeaves: takeLatest(actionTypes.CREATE_LEAVE[REQUEST], createLeaves),
  watchUpdateLeaves: takeLatest(actionTypes.UPDATE_LEAVE[REQUEST], updateLeaveApi),
  watchDeleteLeaves: takeLatest(actionTypes.DELETE_LEAVE[REQUEST], deleteLeaveApi),
  watchGetpayrollDropDown: takeLatest(actionTypes.PAYROLL_DROPDOWN_TYPE[REQUEST], getpayrollDropDown),
  watchAddPayrollApi: takeLatest(actionTypes.CREATE_PAYROLL[REQUEST], addPayrollApi),
  watchGetPayrollAPI: takeLatest(actionTypes.GET_PAYROLL[REQUEST], getPayrollAPI),
}
