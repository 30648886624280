import { Button } from '@mui/material'
import styled from 'styled-components'
import styles from '../../utils/styles.json'

export const ResultActionButton = styled(Button)(({ theme }) => ({
  fontSize: '18px',
  height: '43px',
  padding: '20px 25px',
  fontFamily: 'Montserrat-Light',
  marginLeft: '10px',
  borderRadius: '10px',
  '@media only screen and (max-width:900px)': {
    fontSize: '14px',
    height: '35px',
    padding: '14px 17px',
    marginTop: '10px',
  },
}))

export const style = {
  container: {
    overflow: 'hidden',
    fontFamily: 'Montserrat-Bold',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    background: '#FFFFFF',
    opacity: '1',
    textAlign: 'left',
    margin: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '10px 25px 25px 25px',
    width: '93%',
    border: '1px solid #DDDDDD',
    '@media print': {
      '@page': {
        size: '1280px',
      },
    },
  },
  pageHead: {
    paddingLeft: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 0 1rem 0',
    '@media only screen and (max-width:860px)': {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  headingBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  primaryHeading: {
    fontSize: '1.3rem',
    fontFamily: styles.FONT_MEDIUM,
    color: styles.PRIMARY_COLOR,
    '@media only screen and (max-width:480px)': {
      textAlign: 'center',
    },
  },
  headerHeading: {
    fontSize: '0.9rem',
    textAlign: 'left',
    fontFamily: 'Montserrat-Regular',
    color: styles.PRIMARY_COLOR,
    '@media only screen and (max-width:780px)': {
      fontSize: '0.7rem',
      textAlign: 'center',
    },
    width: 'fit-content',
  },
  table: {
    '*::-webkit-scrollbar ': { display: 'none' },
    '@media only screen and (max-width:860px)': {
      marginLeft: '40px',
    },
  },
  tableContainer: {
    marginBottom: '1px',
  },
  tableHeadWidth: {
    minWidth: '120px',
  },
  border: {
    borderLeft: '1px solid #E9E9E9',
    borderRight: '1px solid #E9E9E9',
  },
  tableHeadCell: {
    color: 'white',
  },
  rowBorder: {
    borderTop: '1px solid #EEEEEE',
    borderBottom: '1px solid #EEEEEE',
    minHeight: '60px',
    margin: '16px 0 0',
  },
  rowInnerItem: {
    fontFamily: 'Montserrat-Regular',
    fontSize: '0.8rem',
    padding: '0',
  },
  actionBarConatiner: {
    position: 'relative',
    border: '2px solid #E0E0E0',
    borderRadius: '5px',
    '@media only screen and (max-width:860px)': {
      marginLeft: '20px',
    },
  },
  actionBar: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingLeft: '1rem',
    width: '98%',
    '@media only screen and (max-width:1230px)': {
      width: '94%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '1rem',
    },
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    mraginRight: '10px',
    justifyContent: 'space-between',
    width: 'fit-content',
    '@media only screen and (max-width:1160px)': {
      width: '100%',
      justifyContent: 'center',
    },
    '@media only screen and (max-width:700px)': {
      flexDirection: 'column',
    },
  },
  serachBoxContainer: {
    '@media only screen and (max-width:460px)': {
      width: '180px',
    },
  },
  actionBarDownloadbtn: {
    marginRight: '10px',
    // width: '5%',
  },
  expandButton: {
    backgroundColor: 'transparent',
    position: 'absolute',
    right: '0',
    top: '0.5rem',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  cardSection: {
    width: '22rem',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem 2.8rem',
    color: 'primary',
    backgroundColor: '#F2F2F2',
    textTransform: 'uppercase',
  },
  cardText: {
    color: '#353665',
    fontFamily: styles.FONT_MEDIUM,
  },
  loader: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40vh',
  },
  cardContainer: {
    display: 'flex',
    width: '97%',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: '15px',
    marginTop: '-10px',
  },
  tableHead: {
    '& th:first-of-type': {
      borderRadius: '50px 0 0 50px',
      border: 'none',
    },
    '& th:last-of-type': {
      borderRadius: '0 50px 50px 0',
      border: 'none',
    },
    '& th': {
      fontFamily: styles.FONT_MEDIUM,
      fontSize: '1rem',
      padding: '15px 0 15px 0',
      border: 'none',
    },
    backgroundColor: styles.PRIMARY_COLOR,
  },
  datePickerContainer: {
    '@media only screen and (max-width:1230px)': {
      marginTop: '-10px',
    },
    '@media only screen and (max-width:460px)': {
      flexDirection: 'column',
      margin: '10px',
    },
  },
}
