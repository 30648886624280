import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { loaderProps } from '../Common/CommonStyles'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { CommonButton, useStyles } from './EmpoloyeementInfoStyle'
import { FormValues } from './IFormValue'
import { RootState } from '../../configureStore'
import { fetchUserDetailsEntities, fetchUserDetailsUI } from '../../reducers'
import { createPayroll, fetchPayroll, fetchPayrollDropdown } from '../../actions'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ArrowBack } from '@mui/icons-material'
import { FieldArray, Form, FormikProvider } from 'formik'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import styled from 'styled-components'
import styles from '../../utils/styles.json'

const style = {
  selectStyle: {
    '& .MuiSelect-select': {
      height: '15px',
    },
  },
  gridStyle: {
    '& .MuiFormControl-root': {
      margin: '0 0 15px',
    },
  },
}

export const InputField = styled(TextField)(({ theme }) => ({
  marginLeft: '10px',
  marginBottom: '1px !important',
  '& .MuiOutlinedInput-input': {
    padding: '13.5px 14px', // Adjust the padding here
    fontSize: '13px !important', // Adjust the font size here
    fontFamily: `${styles.FONT_MEDIUM} !important`,
  },
  '& .MuiFormLabel-asterisk': {
    color: 'white',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
    width: '91.5%',
    height: '40px',
    marginLeft: '15px',
  },
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    marginLeft: '15px',

    fontSize: '13px', // Adjust the font size here
    fontFamily: styles.FONT_MEDIUM,
    lineHeight: '1.8em',
  },
}))

export const SelectField = styled(TextField)(({ theme }) => ({
  marginBottom: '1px !important',

  '& .MuiOutlinedInput-input': {
    marginLeft: '2px',
    padding: '13.5px 14px', // Match the padding with InputField
    fontSize: '13px', // Adjust the font size here
    fontFamily: styles.FONT_MEDIUM,
  },
  '& .MuiFormLabel-root': {
    marginLeft: '15px',
    marginTop: '2px',
    fontSize: '13px', // Adjust the font size here
    fontFamily: styles.FONT_MEDIUM,
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    marginLeft: '15px',
    borderRadius: '20px',
    height: '40px', // Same height as InputField
    width: '92%', // Ensure full width
  },
}))

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '13px',
  fontFamily: styles.FONT_MEDIUM,
}))

const AdminPayroll = (props: any) => {
  const {
    fetchPayrollTypeDropDown,
    payrollDropDown,
    isPayrollCreated,
    isCreatePayrollLoading,
    createUserPayroll,
    fetchPayroll,
    getPayrollData,
    createUserPayrollReset,
    isGetPayrollLoading,
  } = props

  const location = useLocation()
  const userId = new URLSearchParams(location.search).get('userId')

  const rowData: any = useLocation()?.state
  const classes = useStyles()
  const navigate = useNavigate()
  // const userData = location?.state?.data?.rowData || {}
  const [states, setStates] = useState([])

  useEffect(() => {
    fetchPayrollTypeDropDown()
    fetchPayroll({ userId: userId })
  }, [])

  useEffect(() => {
    if (isPayrollCreated) {
      toast.success('Payroll Created successfully!')
      // setTimeout(() => {
      fetchPayroll({ userId: userId })
      // }, 500)
      formik.resetForm()
      createUserPayrollReset()
    }
  }, [isPayrollCreated])

  const validationSchema = Yup.object({
    annualSalary: Yup.string().required('Please Enter Annual Salary first'),
  })

  const formik = useFormik({
    initialValues: {
      annualSalary: '',
      monthlyCTC: '',
      payrollFrequency: 1,
      totalAllowances: '',
      totalDeductions: '',

      allowanceData: [
        {
          allowance: 54,
          payPeriodAmound: '',
        },
      ],
      deductionsData: [
        {
          deductions: 36,
          payPeriodAmound: '',
        },
      ],
      companyCountributionData: [
        {
          companyCountribution: 41,
          payPeriodAmound: '',
        },
      ],
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {},
  })

  useEffect(() => {
    if (getPayrollData) {
      formik.setValues({
        annualSalary: getPayrollData?.annual_salary || '',
        monthlyCTC: getPayrollData?.monthly_salary || '',
        payrollFrequency: getPayrollData?.payrollFrequencyId || 1,
        totalAllowances: getPayrollData?.totalAllowance || '',
        totalDeductions: getPayrollData?.totalDeduction || '',
        allowanceData:
          getPayrollData?.allowances?.length > 0
            ? getPayrollData?.allowances.map((allowance: any) => ({
                allowance: allowance.id_allowances,
                payPeriodAmound: allowance.pay_period_amount,
              }))
            : [
                {
                  allowance: 54,
                  payPeriodAmound: '',
                },
              ],
        deductionsData:
          getPayrollData?.deductions?.length > 0
            ? getPayrollData?.deductions.map((deduction: any) => ({
                deductions: deduction.id_deductions,
                payPeriodAmound: deduction.pay_period_amount,
              }))
            : [
                {
                  deductions: 36,
                  payPeriodAmound: '',
                },
              ],
        companyCountributionData:
          getPayrollData?.companyContributions?.length > 0
            ? getPayrollData?.companyContributions.map((contribution: any) => ({
                companyCountribution: contribution.id_company_contribution,
                payPeriodAmound: contribution.pay_period_amount,
              }))
            : [
                {
                  companyCountribution: 41,
                  payPeriodAmound: '',
                },
              ],
      })
    }
  }, [getPayrollData])

  const transformPayload = (formikValues: any) => {
    const {
      annualSalary,
      monthlyCTC,
      payrollFrequency,
      allowanceData,
      deductionsData,
      companyCountributionData,
    } = formikValues

    return {
      salary: {
        id_employee_salary: getPayrollData?.id_employee_salary
          ? getPayrollData?.id_employee_salary
          : 0,
        annual_salary: parseFloat(annualSalary),
        pay_period_rate: parseFloat(monthlyCTC), // Assuming 30 days in a month
      },
      payrollFrequencyId: payrollFrequency,
      allowances: allowanceData.map((item: any) => ({
        id_allowances: parseInt(item.allowance, 10),
        pay_period_amount: parseFloat(item.payPeriodAmound),
      })),
      deductions: deductionsData.map((item: any) => ({
        id_deductions: parseInt(item.deductions, 10),
        pay_period_amount: parseFloat(item.payPeriodAmound),
      })),
      uid: Number(userId), // Replace with the actual UID dynamically if needed
      contributionForEmp: companyCountributionData.map((item: any) => ({
        id_contribution: parseInt(item.companyCountribution, 10),
        pay_period_amount: parseFloat(item.payPeriodAmound),
      })),
    }
  }

  const handleSubmit = async () => {
    const entity = transformPayload(formik.values)
    try {
      await validationSchema.validate(formik.values, { abortEarly: false })
      createUserPayroll(entity)
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const stepErrors = error.inner.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path as keyof FormValues] = curr.message
          }
          return acc
        }, {} as { [K in keyof FormValues]?: string })

        formik.setTouched(
          Object.keys(stepErrors).reduce((acc, key) => {
            acc[key as keyof FormValues] = true
            return acc
          }, {} as { [K in keyof FormValues]: any }),
        )

        formik.setErrors(stepErrors)
      } else {
        console.error('Unexpected error:', error)
      }
    }
  }

  return (
    <>
      {isCreatePayrollLoading ||
        (isGetPayrollLoading && (
          <Dialog
            open={isCreatePayrollLoading || isGetPayrollLoading}
            PaperProps={{ style: loaderProps }}
          >
            <CircularProgress color='secondary' />
          </Dialog>
        ))}

      <div className={classes.root}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mx: 4, my: 1, mt: 3, backgroundColor: '' }}>
            <div>
              <Grid item container xs={12}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    paddingBottom: '15px,',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center', // Center items vertically
                      justifyContent: 'space-between', // Space items apart (button left, heading center)
                      width: '84.6vw', // Set width as per your requirement
                      height: '70px', // Set height as per your requirement
                      borderRadius: '8px', // Round corners of the box
                      padding: '0 20px', // Optional: Add horizontal padding
                      opacity: 1, // Adjust opacity as needed
                      // backgroundColor: 'white',
                      // marginTop: '20px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: styles.FONT_BOLD,
                        fontSize: '32px', // Set font size
                        fontWeight: 700, // Set font weight
                        lineHeight: '38.73px', // Set line height
                        textAlign: 'center', // Center align the text
                        flexGrow: 1, // Allow the heading to grow and take available space
                        margin: '0', // Optional: Remove default margin
                        color: '#193C6C',
                      }}
                    >
                      {`${rowData?.first_name}'s`} Payroll
                    </Typography>
                    <Box
                      onClick={() => navigate(-1)}
                      sx={{ float: 'right', mt: '0px', cursor: 'pointer' }}
                    >
                      <ArrowBack />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      border: '1px solid #DDDDDD',
                      width: '98%', // Set width to 90%
                    }}
                  >
                    <Box
                      sx={{
                        padding: '16px',
                        margin: '10px auto',
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, 1fr)', // Four columns layout
                        gap: '16px', // Space between the columns
                      }}
                    >
                      {/* Employee Id */}
                      <Typography
                        sx={{
                          fontSize: '1rem',
                          minHeight: '20px',
                          textAlign: 'left',
                          fontFamily: styles.FONT_MEDIUM,
                        }}
                      >
                        Employee Id:{' '}
                        <span
                          style={{
                            color: 'grey',
                            fontFamily: styles.FONT_MEDIUM,
                            fontSize: '14px',
                          }}
                        >
                          {getPayrollData?.employeeId}
                        </span>
                      </Typography>

                      {/* Employee Name */}
                      <Typography
                        sx={{
                          fontSize: '1rem',
                          minHeight: '20px',
                          textAlign: 'left',
                          fontFamily: styles.FONT_MEDIUM,
                          width: '400px',
                        }}
                      >
                        Employee Name:{' '}
                        <span
                          style={{
                            color: 'grey',
                            fontFamily: styles.FONT_MEDIUM,
                            fontSize: '14px',
                          }}
                        >
                          {getPayrollData?.empName}
                        </span>
                      </Typography>

                      {/* Designation */}
                      <Typography
                        sx={{
                          fontSize: '1rem',
                          minHeight: '20px',
                          textAlign: 'left',
                          fontFamily: styles.FONT_MEDIUM,
                        }}
                      >
                        Designation:{' '}
                        <span
                          style={{
                            color: 'grey',
                            fontFamily: styles.FONT_MEDIUM,
                            fontSize: '14px',
                          }}
                        >
                          {getPayrollData?.designation}
                        </span>
                      </Typography>

                      {/* Accrual Type */}
                      <Typography
                        sx={{
                          fontSize: '1rem',
                          minHeight: '20px',
                          textAlign: 'left',
                          fontFamily: styles.FONT_MEDIUM,
                        }}
                      >
                        Accrual Type:{' '}
                        <span
                          style={{
                            color: 'grey',
                            fontFamily: styles.FONT_MEDIUM,
                            fontSize: '14px',
                          }}
                        >
                          {getPayrollData?.accrual_type}
                        </span>
                      </Typography>
                    </Box>

                    <Grid item xs={0} md={0}>
                      <Divider orientation='horizontal' flexItem></Divider>
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        // justifyContent: 'space-between',
                        width: '100%',
                        paddingBottom: '15px',
                      }}
                    >
                      <Grid item xs={3}>
                        <InputField
                          size='small'
                          fullWidth
                          id='annualSalary'
                          name='annualSalary'
                          label='Annual Salary'
                          type='number'
                          onChange={(e: any) => {
                            formik.setFieldValue(`annualSalary`, e.target.value)
                            const monthlySalary = e.target.value / 12
                            formik.setFieldValue(`monthlyCTC`, monthlySalary.toFixed(2))
                          }}
                          value={formik.values.annualSalary}
                          required
                          error={formik.touched.annualSalary && Boolean(formik.errors.annualSalary)}
                          // sx={{
                          //   '& .MuiFormLabel-asterisk': {
                          //     color: 'red',
                          //   },
                          // }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <InputField
                          size='small'
                          fullWidth
                          id='monthlyCTC'
                          name='monthlyCTC'
                          label='Monthly CTC'
                          disabled
                          onChange={formik.handleChange}
                          value={formik.values.monthlyCTC}
                          // required={true}
                          // error={formik.touched.monthlyCTC && Boolean(formik.errors.monthlyCTC)}
                          // sx={{
                          //   '& .MuiFormLabel-asterisk': {
                          //     color: 'red',
                          //   },
                          // }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <InputField
                          size='small'
                          fullWidth
                          id='totalAllowances'
                          name='totalAllowances'
                          label='Total Allowances'
                          onChange={formik.handleChange}
                          value={formik.values.totalAllowances}
                          // required={true}
                          // error={
                          //   formik.touched.totalAllowances && Boolean(formik.errors.totalAllowances)
                          // }
                          disabled
                          // sx={{
                          //   '& .MuiFormLabel-asterisk': {
                          //     color: 'red',
                          //   },
                          // }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SelectField
                          size='small'
                          id='payrollFrequency'
                          name='payrollFrequency'
                          label='Payroll Frequency'
                          select
                          value={formik.values.payrollFrequency}
                          onChange={formik.handleChange}
                          disabled
                          // error={
                          //   formik.touched.payrollFrequency &&
                          //   Boolean(formik.errors.payrollFrequency)
                          // }
                          // required={true}

                          sx={{
                            ...style.selectStyle,
                            '& .MuiFormLabel-asterisk': {
                              color: 'white',
                            },
                          }}
                        >
                          <StyledMenuItem value={1}> Monthly </StyledMenuItem>
                          <StyledMenuItem value={0}> Yearly </StyledMenuItem>
                        </SelectField>
                      </Grid>
                      <Grid item xs={3}>
                        <InputField
                          size='small'
                          fullWidth
                          id='totalDeductions'
                          name='totalDeductions'
                          label='Total Deductions'
                          onChange={formik.handleChange}
                          value={formik.values.totalDeductions}
                          disabled
                          // required={true}
                          // error={
                          //   formik.touched.totalDeductions && Boolean(formik.errors.totalDeductions)
                          // }
                          // sx={{
                          //   '& .MuiFormLabel-asterisk': {
                          //     color: 'red',
                          //   },
                          // }}
                        />
                      </Grid>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      border: '1px solid #DDDDDD',
                      width: '98%', // Set width to 90%
                      display: 'flex',
                      marginTop: '20px',
                      paddingBottom: '15px',
                      alignItems: 'start',
                    }}
                  >
                    <FormikProvider value={formik}>
                      <Form
                        style={{
                          width: '100%',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '20px',
                            marginTop: '10px',
                            background: '#E2E2E2',
                            marginLeft: '5px',
                            padding: '5px 0',
                            width: '92%',
                          }}
                        >
                          <Typography
                            sx={{
                              width: '40%',
                              fontSize: '0.8rem',
                              minHeight: '20px',
                              textAlign: 'center',
                              fontFamily: styles.FONT_MEDIUM,
                              // marginLeft: '50px',
                            }}
                          >
                            Allowances
                          </Typography>
                          <Typography
                            sx={{
                              width: '150px',
                              fontSize: '0.8rem',
                              minHeight: '20px',
                              textAlign: 'center',
                              fontFamily: styles.FONT_MEDIUM,
                              marginLeft: '10px',
                            }}
                          >
                            Pay Period Amount (Monthly)
                          </Typography>
                        </Box>
                        <FieldArray name='allowanceData'>
                          {({ push, remove }) => (
                            <>
                              {formik.values.allowanceData.map((dependent: any, index: number) => (
                                <Grid
                                  container
                                  spacing={1}
                                  key={index}
                                  alignItems='center'
                                  style={{ maxHeight: '80px' }}
                                  xs={12}
                                >
                                  {/* Allowance */}
                                  <Grid item xs={5.3}>
                                    <SelectField
                                      name={`allowanceData[${index}].allowance`}
                                      size='small'
                                      fullWidth
                                      select
                                      label=''
                                      variant='outlined'
                                      margin='normal'
                                      value={dependent?.allowance}
                                      disabled={index === 0 || !formik.values.annualSalary}
                                      onChange={(e) => {
                                        const value = e.target.value
                                        const selectedAllowance = payrollDropDown?.allowance.find(
                                          (data: any) => data.id === value,
                                        )
                                        formik.setFieldValue(
                                          `allowanceData[${index}].allowance`,
                                          value,
                                        )
                                        formik.setFieldValue(
                                          `allowanceData[${index}].payPeriodAmound`,
                                          selectedAllowance?.allowance_amount || '',
                                        )
                                      }}
                                      onBlur={formik.handleBlur}
                                      SelectProps={{
                                        MenuProps: {
                                          PaperProps: {
                                            style: {
                                              maxHeight: 200,
                                              overflowY: 'auto',
                                            },
                                          },
                                        },
                                      }}
                                    >
                                      {payrollDropDown?.allowance &&
                                      payrollDropDown.allowance.length > 0 ? (
                                        payrollDropDown.allowance.map((data: any) => (
                                          <StyledMenuItem
                                            sx={{ textTransform: 'capitalize' }}
                                            key={data.id}
                                            value={data.id}
                                            disabled={formik.values.allowanceData.some(
                                              (item: any, idx: number) =>
                                                idx !== index && item.allowance === data.id,
                                            )}
                                          >
                                            {data.name}
                                          </StyledMenuItem>
                                        ))
                                      ) : (
                                        <StyledMenuItem disabled>
                                          No allowances available
                                        </StyledMenuItem>
                                      )}
                                    </SelectField>
                                  </Grid>

                                  <Grid item xs={5}>
                                    <InputField
                                      name={`allowanceData[${index}].payPeriodAmound`}
                                      placeholder='Amount'
                                      size='small'
                                      fullWidth
                                      disabled={!formik.values.annualSalary}
                                      value={dependent.payPeriodAmound}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </Grid>

                                  {/* Add/Remove Buttons */}
                                  <Grid
                                    item
                                    xs={1.4}
                                    style={{
                                      textAlign: 'center',
                                      marginTop: '20px',
                                      display: 'flex',
                                    }}
                                  >
                                    {index === formik.values.allowanceData.length - 1 ? (
                                      <>
                                        <Tooltip title='Add Allowance'>
                                          <AddCircleOutlineIcon
                                            onClick={() => {
                                              if (!formik.values.annualSalary) {
                                                formik.setFieldTouched('annualSalary', true)
                                                formik.setFieldTouched(
                                                  `allowanceData[${index}].allowance`,
                                                  true,
                                                )
                                              } else {
                                                if (
                                                  formik.values.allowanceData[index]?.allowance &&
                                                  formik.values.allowanceData[index]
                                                    ?.payPeriodAmound
                                                ) {
                                                  push({
                                                    allowance: 0,
                                                    payPeriodAmound: '',
                                                  })
                                                } else {
                                                  formik.setFieldTouched(
                                                    `allowanceData[${index}].allowance`,
                                                    true,
                                                  )
                                                  formik.setFieldTouched(
                                                    `allowanceData[${index}].payPeriodAmound`,
                                                    true,
                                                  )
                                                }
                                              }
                                            }}
                                            sx={{
                                              fontSize: '27px',
                                              cursor: 'pointer',
                                              color: '#1e69a4',
                                              opacity: '1',
                                            }}
                                          />
                                        </Tooltip>

                                        {index !== 0 && (
                                          <Tooltip title='Remove Allowance'>
                                            <HighlightOffIcon
                                              onClick={() => remove(index)}
                                              sx={{
                                                fontSize: '27px',
                                                cursor: 'pointer',
                                                color: 'red',
                                              }}
                                            />
                                          </Tooltip>
                                        )}
                                      </>
                                    ) : (
                                      <Tooltip title='Remove Allowance'>
                                        <HighlightOffIcon
                                          onClick={() => remove(index)}
                                          sx={{
                                            fontSize: '27px',
                                            cursor: 'pointer',
                                            color: 'red',
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </Grid>

                                  {/* Error Message Handling */}
                                  {(!formik.values.allowanceData[index].allowance ||
                                    !formik.values.allowanceData[index].payPeriodAmound) &&
                                    (formik.touched?.allowanceData as any[])?.[index]?.allowance &&
                                    (formik.touched?.allowanceData as any[])?.[index]
                                      ?.payPeriodAmound && (
                                      <Typography
                                        sx={{
                                          color: 'red',
                                          fontSize: '0.8rem',
                                          marginTop: '10px',
                                          marginLeft: '30px',
                                        }}
                                      >
                                        Please fill out all fields before adding a new one.
                                      </Typography>
                                    )}

                                  {formik.touched?.allowanceData?.[index]?.allowance &&
                                    formik.touched.annualSalary &&
                                    Boolean(formik.errors.annualSalary) && (
                                      <Typography
                                        sx={{
                                          color: 'red',
                                          fontSize: '0.8rem',
                                          marginTop: '10px',
                                          marginLeft: '30px',
                                        }}
                                      >
                                        {formik.errors.annualSalary}
                                      </Typography>
                                    )}
                                </Grid>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </Form>
                    </FormikProvider>
                    <FormikProvider value={formik}>
                      <Form style={{ width: '100%' /*  margin: 'auto' */ }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            marginTop: '10px',
                            background: '#E2E2E2',
                            padding: '5px 0',
                            width: '93%',
                          }}
                        >
                          <Typography
                            sx={{
                              width: '40%',
                              fontSize: '0.8rem',
                              minHeight: '20px',
                              textAlign: 'center',
                              fontFamily: styles.FONT_MEDIUM,
                              marginLeft: '15px',
                            }}
                          >
                            Deductions
                          </Typography>
                          <Typography
                            sx={{
                              width: '150px',
                              fontSize: '0.8rem',
                              minHeight: '20px',
                              textAlign: 'center',
                              fontFamily: styles.FONT_MEDIUM,
                              marginLeft: '15px',
                            }}
                          >
                            Pay Period Amount (Monthly)
                          </Typography>
                        </Box>
                        <FieldArray name='deductionsData'>
                          {({ push, remove }) => (
                            <>
                              {formik.values.deductionsData.map((dependent: any, index: number) => (
                                <Grid
                                  container
                                  spacing={1}
                                  key={index}
                                  alignItems='center'
                                  style={{ maxHeight: '80px' }}
                                  xs={12}
                                >
                                  {/* Deduction Dropdown */}
                                  <Grid item xs={5.3}>
                                    <SelectField
                                      name={`deductionsData[${index}].deductions`}
                                      size='small'
                                      fullWidth
                                      sx={{ marginLeft: '0' }}
                                      select
                                      label=''
                                      variant='outlined'
                                      margin='normal'
                                      value={dependent.deductions}
                                      disabled={!formik.values.annualSalary}
                                      onChange={(e) => {
                                        const value = e.target.value
                                        const selectedDeduction = payrollDropDown?.deduction.find(
                                          (deduction: any) => deduction.id === value,
                                        )
                                        formik.setFieldValue(
                                          `deductionsData[${index}].deductions`,
                                          value,
                                        )
                                        formik.setFieldValue(
                                          `deductionsData[${index}].payPeriodAmound`,
                                          selectedDeduction?.deductions_amount || '',
                                        )
                                      }}
                                      onBlur={formik.handleBlur}
                                      SelectProps={{
                                        MenuProps: {
                                          PaperProps: {
                                            style: {
                                              maxHeight: 200,
                                              overflowY: 'auto',
                                            },
                                          },
                                        },
                                      }}
                                    >
                                      {payrollDropDown?.deduction?.length > 0 ? (
                                        payrollDropDown?.deduction.map((deduction: any) => (
                                          <StyledMenuItem
                                            sx={{ textTransform: 'capitalize' }}
                                            key={deduction.id}
                                            value={deduction.id}
                                            disabled={formik.values.deductionsData.some(
                                              (item: any, idx: number) =>
                                                idx !== index && item.deductions === deduction.id,
                                            )}
                                          >
                                            {deduction.name}
                                          </StyledMenuItem>
                                        ))
                                      ) : (
                                        <StyledMenuItem disabled>
                                          No deductions available
                                        </StyledMenuItem>
                                      )}
                                    </SelectField>
                                  </Grid>

                                  {/* Pay Period Amount */}
                                  <Grid item xs={5}>
                                    <InputField
                                      name={`deductionsData[${index}].payPeriodAmound`}
                                      placeholder='Amount'
                                      size='small'
                                      fullWidth
                                      value={dependent.payPeriodAmound}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </Grid>

                                  {/* Add/Remove Buttons */}
                                  <Grid
                                    item
                                    xs={1.5}
                                    style={{
                                      textAlign: 'center',
                                      marginTop: '20px',
                                      display: 'flex',
                                    }}
                                  >
                                    {index === formik.values.deductionsData.length - 1 ? (
                                      <>
                                        <Tooltip title='Add Deduction'>
                                          <AddCircleOutlineIcon
                                            onClick={() => {
                                              if (!formik.values.annualSalary) {
                                                formik.setFieldTouched('annualSalary', true)
                                                formik.setFieldTouched(
                                                  `deductionsData[${index}].deductions`,
                                                  true,
                                                )
                                              } else {
                                                if (
                                                  formik.values.deductionsData[index]?.deductions &&
                                                  formik.values.deductionsData[index]
                                                    ?.payPeriodAmound
                                                ) {
                                                  push({
                                                    allowance: 0,
                                                    payPeriodAmound: '',
                                                  })
                                                } else {
                                                  formik.setFieldTouched(
                                                    `deductionsData[${index}].deductions`,
                                                    true,
                                                  )
                                                  formik.setFieldTouched(
                                                    `deductionsData[${index}].payPeriodAmound`,
                                                    true,
                                                  )
                                                }
                                              }
                                            }}
                                            sx={{
                                              fontSize: '27px',
                                              cursor: 'pointer',
                                              color: '#1e69a4',
                                              opacity: '1',
                                            }}
                                          />
                                        </Tooltip>

                                        {index !== 0 && (
                                          <Tooltip title='Remove Deduction'>
                                            <HighlightOffIcon
                                              onClick={() => remove(index)}
                                              sx={{
                                                fontSize: '27px',
                                                cursor: 'pointer',
                                                color: 'red',
                                              }}
                                            />
                                          </Tooltip>
                                        )}
                                      </>
                                    ) : (
                                      <Tooltip title='Remove Deduction'>
                                        <HighlightOffIcon
                                          onClick={() => remove(index)}
                                          sx={{
                                            fontSize: '27px',
                                            cursor: 'pointer',
                                            color: 'red',
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </Grid>
                                  {(!formik.values.deductionsData[index].deductions ||
                                    !formik.values.deductionsData[index].payPeriodAmound) &&
                                    (formik.touched?.deductionsData as any[])?.[index]
                                      ?.deductions &&
                                    (formik.touched?.deductionsData as any[])?.[index]
                                      ?.payPeriodAmound && (
                                      <Typography
                                        sx={{
                                          color: 'red',
                                          fontSize: '0.8rem',
                                          marginTop: '10px',
                                          marginLeft: '30px',
                                        }}
                                      >
                                        Please fill out all fields before adding a new one.
                                      </Typography>
                                    )}

                                  {(formik.touched?.deductionsData as any[])?.[index]?.deductions &&
                                    formik.touched.annualSalary &&
                                    Boolean(formik.errors.annualSalary) && (
                                      <Typography
                                        sx={{
                                          color: 'red',
                                          fontSize: '0.8rem',
                                          marginTop: '10px',
                                          marginLeft: '30px',
                                        }}
                                      >
                                        {formik.errors.annualSalary}
                                      </Typography>
                                    )}
                                </Grid>
                              ))}

                              {formik.values.deductionsData.length === 0 && (
                                <Tooltip title='Add Deduction'>
                                  <AddCircleOutlineIcon
                                    onClick={() =>
                                      push({
                                        deductions: '',
                                        payPeriodAmound: '',
                                      })
                                    }
                                    fontSize='large'
                                    style={{ color: 'green', cursor: 'pointer' }}
                                  />
                                </Tooltip>
                              )}
                            </>
                          )}
                        </FieldArray>
                      </Form>
                    </FormikProvider>
                    <FormikProvider value={formik}>
                      <Form style={{ width: '100%', marginRight: '7px' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            marginTop: '10px',
                            background: '#E2E2E2',
                            padding: '5px 0',
                            width: '92%',
                          }}
                        >
                          <Typography
                            sx={{
                              width: '45%',
                              fontSize: '0.8rem',
                              minHeight: '20px',
                              textAlign: 'center',
                              fontFamily: styles.FONT_MEDIUM,
                            }}
                          >
                            Company Contributions
                          </Typography>
                          <Typography
                            sx={{
                              width: '180px',
                              marginLeft: '-5px',
                              fontSize: '0.8rem',
                              minHeight: '20px',
                              textAlign: 'center',
                              fontFamily: styles.FONT_MEDIUM,
                            }}
                          >
                            Pay Period Amount (Monthly)
                          </Typography>
                        </Box>
                        <FieldArray name='companyCountributionData'>
                          {({ push, remove }) => (
                            <>
                              {formik.values.companyCountributionData.map(
                                (dependent: any, index: number) => (
                                  <Grid
                                    container
                                    spacing={1}
                                    key={index}
                                    alignItems='center'
                                    style={{ maxHeight: '80px' }}
                                    xs={12}
                                  >
                                    <Grid item xs={5.3}>
                                      {/* <Tooltip title={dependent.companyCountribution} arrow> */}
                                      <SelectField
                                        name={`companyCountributionData[${index}].companyCountribution`}
                                        size='small'
                                        fullWidth
                                        sx={{ marginLeft: '0' }}
                                        select
                                        variant='outlined'
                                        margin='normal'
                                        value={dependent.companyCountribution}
                                        disabled={!formik.values.annualSalary}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        SelectProps={{
                                          MenuProps: {
                                            PaperProps: {
                                              style: {
                                                maxHeight: 200,
                                                overflowY: 'auto',
                                              },
                                            },
                                          },
                                        }}
                                      >
                                        {payrollDropDown?.contribution?.map((data: any) => (
                                          <StyledMenuItem
                                            key={data.id}
                                            value={data.id}
                                            sx={{ textTransform: 'capitalize' }}
                                            disabled={formik.values.companyCountributionData.some(
                                              (item: any, idx: number) =>
                                                idx !== index &&
                                                item.companyCountribution === data.id,
                                            )}
                                          >
                                            {data.name}
                                          </StyledMenuItem>
                                        ))}
                                      </SelectField>
                                      {/* </Tooltip> */}
                                    </Grid>
                                    {/* Pay Period Amount */}
                                    <Grid item xs={5}>
                                      <InputField
                                        name={`companyCountributionData[${index}].payPeriodAmound`}
                                        placeholder='Amount'
                                        size='small'
                                        fullWidth
                                        value={dependent.payPeriodAmound}
                                        disabled={!formik.values.annualSalary}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={1.5}
                                      style={{
                                        textAlign: 'center',
                                        marginTop: '20px',
                                        display: 'flex',
                                      }}
                                    >
                                      {index ===
                                      formik.values.companyCountributionData.length - 1 ? (
                                        <>
                                          <Tooltip title='Add Company Countribution'>
                                            <AddCircleOutlineIcon
                                              onClick={() => {
                                                if (!formik.values.annualSalary) {
                                                  formik.setFieldTouched('annualSalary', true)
                                                  formik.setFieldTouched(
                                                    `companyCountributionData[${index}].companyCountribution`,
                                                    true,
                                                  )
                                                } else {
                                                  if (
                                                    formik.values.companyCountributionData[index]
                                                      ?.companyCountribution &&
                                                    formik.values.companyCountributionData[index]
                                                      ?.payPeriodAmound
                                                  ) {
                                                    push({
                                                      allowance: 0,
                                                      payPeriodAmound: '',
                                                    })
                                                  } else {
                                                    formik.setFieldTouched(
                                                      `companyCountributionData[${index}].companyCountribution`,
                                                      true,
                                                    )
                                                    formik.setFieldTouched(
                                                      `companyCountributionData[${index}].payPeriodAmound`,
                                                      true,
                                                    )
                                                  }
                                                }
                                              }}
                                              sx={{
                                                fontSize: '27px',
                                                cursor: 'pointer',
                                                color: '#1e69a4',
                                                opacity: '1',
                                              }}
                                            />
                                          </Tooltip>
                                          {index !== 0 && (
                                            <Tooltip title='Remove Company Countribution'>
                                              <HighlightOffIcon
                                                onClick={() => remove(index)}
                                                sx={{
                                                  fontSize: '27px',
                                                  cursor: 'pointer',
                                                  color: 'red',
                                                }}
                                              />
                                            </Tooltip>
                                          )}
                                        </>
                                      ) : (
                                        <Tooltip title='Remove Company Countribution'>
                                          <HighlightOffIcon
                                            onClick={() => remove(index)}
                                            sx={{
                                              fontSize: '27px',
                                              cursor: 'pointer',
                                              color: 'red',
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                    </Grid>
                                    {(!formik.values.companyCountributionData[index]
                                      .companyCountribution ||
                                      !formik.values.companyCountributionData[index]
                                        .payPeriodAmound) &&
                                      (formik.touched?.companyCountributionData as any[])?.[index]
                                        ?.companyCountribution &&
                                      (formik.touched?.companyCountributionData as any[])?.[index]
                                        ?.payPeriodAmound && (
                                        <Typography
                                          sx={{
                                            color: 'red',
                                            fontSize: '0.8rem',
                                            marginTop: '10px',
                                            marginLeft: '30px',
                                          }}
                                        >
                                          Please fill out all fields before adding a new one.
                                        </Typography>
                                      )}

                                    {(formik.touched?.companyCountributionData as any[])?.[index]
                                      ?.companyCountribution &&
                                      formik.touched.annualSalary &&
                                      Boolean(formik.errors.annualSalary) && (
                                        <Typography
                                          sx={{
                                            color: 'red',
                                            fontSize: '0.8rem',
                                            marginTop: '10px',
                                            marginLeft: '30px',
                                          }}
                                        >
                                          {formik.errors.annualSalary}
                                        </Typography>
                                      )}
                                  </Grid>
                                ),
                              )}

                              {formik.values.companyCountributionData.length === 0 && (
                                <Tooltip title='Add Company Countribution'>
                                  <AddCircleOutlineIcon
                                    onClick={() =>
                                      push({
                                        companyCountribution: '',
                                        payPeriodAmound: '',
                                      })
                                    }
                                    fontSize='large'
                                    style={{ color: 'green', cursor: 'pointer' }}
                                  />
                                </Tooltip>
                              )}
                            </>
                          )}
                        </FieldArray>
                      </Form>
                    </FormikProvider>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      gap: '10px',
                      margin: '20px 20px 20px 20px',
                    }}
                  >
                    <Button
                      sx={{
                        fontFamily: styles.FONT_BOLD,
                        width: '40%',
                        borderRadius: '20px',
                        height: '40px',
                        padding: '14px 28px',
                        background: '#E2E2E2',
                        color: '#193C6C',
                        fontSize: '14px',
                        '&:hover': {
                          background: '#E2E2E2',
                          color: '#000000',
                        },
                      }}
                      variant='contained'
                      onClick={() => navigate(-1)}
                    >
                      CANCEL
                    </Button>
                    <CommonButton
                      type='submit'
                      variant='contained'
                      color='primary'
                      onClick={handleSubmit}
                      sx={{
                        width: '40%',
                        fontSize: '14px !important',
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                          backgroundColor: '#193C6D',
                          transform: 'scale(1.05)', // Add scale effect on hover
                          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', // Shadow effect on hover
                        },
                      }}
                    >
                      SUBMIT
                    </CommonButton>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Box>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isGetPayrollLoading: fetchUserDetailsUI.fetchUserData(state).isGetPayrollLoading,
    isCreatePayrollLoading: fetchUserDetailsUI.fetchUserData(state).isCreatePayrollLoading,
    isPayrollCreated: fetchUserDetailsUI.fetchUserData(state).isPayrollCreated,
    payrollDropDown: fetchUserDetailsEntities.fetchUserData(state).payrollDropDown,
    getPayrollData: fetchUserDetailsEntities.fetchUserData(state).getPayrollData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchPayrollTypeDropDown: (data: any) => dispatch(fetchPayrollDropdown.request(data)),
    createUserPayroll: (data: any) => dispatch(createPayroll.request(data)),
    createUserPayrollReset: () => dispatch(createPayroll.reset()),
    fetchPayroll: (data: any) => dispatch(fetchPayroll.request(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminPayroll)
