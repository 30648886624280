import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
  Dialog,
  CircularProgress,
  Button,
  Tooltip,
  Avatar,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { loaderProps } from '../Common/CommonStyles'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
  createLeave,
  deleteLeave,
  fetchLeaveDetails,
  fetchLeaveType,
  updateLeave,
} from '../../actions'
import {
  dashboardEntity,
  dashboardUI,
  fetchUserDetailsEntities,
  fetchUserDetailsUI,
} from '../../reducers'
import { useLocation, useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import { ILeavesAccrualReport } from '../../models/leaves-accrual-report.model'
import { ILeaveDetails } from '../../models/leave-detail.model'
import style from '../../utils/styles.json'
import { CommonButton, StyledButton } from './UserDetailsStyle'
import UpdateLeaveInfo from './UpdateLeaveInfo'
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'
import RemoveIcon from '@mui/icons-material/Remove'
import DeleteIcon from '@mui/icons-material/Delete'
import html2pdf from 'html2pdf.js'
import PdfIcon from '../../assets/images/pdf_image.png'
import { toast } from 'react-toastify'
import { TFuncReturn } from 'i18next'
import { ReactComponent as UpdateLeaveIcon } from '../../components/Svg/updateLeave.svg'

export interface ILeaveDetailsProps {
  leaveDetails: ILeavesAccrualReport
  loaderState: boolean
  fetchLeaveDetails: (data: any) => void
  showBackArrow?: boolean
}

const AdminLeaveDetails = (props: any) => {
  const {
    deleteLeaveReset,
    isUserLeaveDeleted,
    updateLeaveReset,
    isUserLeaveUpdated,
    isUpdateLeaveLoading,
    createLeaveReset,
    deleteLeave,
    createLeave,
    updateLeave,
    isUserLeaveLoading,
    isUserLeaveCreated,
    fetchLeaveDetails,
    fetchLeaveType,
    getLeaveTypes,
    leaveDetails,
    loaderState,
    showBackArrow = true,
  } = props
  const [expanded, setExpanded] = useState<string | false | true>(false)
  const [open, setOpen] = useState(false)
  const [leaveType, setLeaveType] = useState(0)
  const [checkDownLoadPdf, setCheckDownLoadPdf] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const userId = new URLSearchParams(location.search).get('userId')
  //   const companyLogo = useSelector((state: any) => state.dashboardEntity.getDashboard(state).getLogo)

  const companyLogo = useSelector(
    (state: { entities: { dashboard: { getLogo: string } } }) =>
      state?.entities?.dashboard?.getLogo,
  )


  useEffect(() => {
    if (isUserLeaveDeleted) {
      fetchLeaveDetails({ id: userId })
      toast.success('Leave Deleted Successfully!')
      deleteLeaveReset()
    }
  }, [isUserLeaveDeleted])

  useEffect(() => {
    if (open === false) {
      fetchLeaveType()
      fetchLeaveDetails({ id: userId })
    }
  }, [open])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const MainContainer = {
    background: 'rgb(231, 235, 240)',
    width: '100%',
    paddingTop: '20px',
    paddingBottom: '1px',
    height: '100%',
  }

  const MultiAccordion: React.CSSProperties = {
    margin: '10px 0 10px 60px',
  }

  const AccordionStyle = {
    width: '95%',
    marginBottom: '15px',
  }

  const AccordingHeading = {
    flexShrink: 0,
    fontFamily: style.FONT_MEDIUM,
    letterSpacing: '0',
    fontSize: '20px',
  }

  const StyledPaper = styled(Paper)(({ theme }) => ({
    width: '86%',
    padding: '30px 25px',
    background: '#FFFFFF',
    opacity: '1',
    marginTop: '25px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '20px',
  }))

  const PaperBlock = styled(Paper)(({ theme }) => ({
    width: '30%',
    padding: '6px',
    fontFamily: style.FONT_MEDIUM,
    fontSize: '13px',
  }))

  const PaperMiniBlock = styled(Paper)(({ theme }) => ({
    width: '5%',
    padding: '6px',
    fontFamily: style.FONT_MEDIUM,
    fontSize: '13px',
    textAlign: 'center',
  }))

  const PaperSepBlock = styled(Paper)(({ theme }) => ({
    width: '68%',
    padding: '6px',
    fontFamily: style.FONT_MEDIUM,
    fontSize: '13px',
    textAlign: 'center',
  }))

  const SplitDiv: React.CSSProperties = {
    display: 'flex',
  }

  const Heading: React.CSSProperties = {
    margin: 0,
    marginBottom: '12px',
    fontSize: '25px',
    textAlign: 'center',
    fontFamily: style.FONT_MEDIUM,
  }

  const LeavesDetailsHeading = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    textAlign: 'center',
    fontFamily: style.FONT_BOLD,
    color: 'black',
    padding: '20px',
  }))

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: style.PRIMARY_COLOR,
      color: 'White',
      fontFamily: style.FONT_MEDIUM,
      textAlign: 'center',
      border: '1px solid #d1cbcbf2',
      fontSize: '11px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      fontFamily: style.FONT_MEDIUM,
      textAlign: 'center',
      border: '1px solid #d1cbcbf2',
      color: '#483f3f',
    },
  }))

  const ActionButton = styled(Button)(() => ({
    fontSize: '13px',
    height: '42px',
    float: 'right',
    marginTop: '15px',
    borderRadius: '20px',
    padding: '5px 20px',
    fontFamily: style.FONT_MEDIUM,
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {},
    '&:last-child td, &:last-child th': {
      fontSize: 12,
      fontFamily: style.FONT_MEDIUM,
      textAlign: 'center',
      border: '1px solid #d1cbcbf2',
      borderBottom: 'none',
    },
  }))

  const LastCellStyle = { color: '#555', fontFamily: style.FONT_MEDIUM }

  const handleDeleteLeave = (data: any) => {
    deleteLeave({ uid: userId, id: data.id })
  }

  const handleAddOpen = (type: number) => {
    setLeaveType(type)
    setOpen(true)
  }

  const handleExportPDF = async (name: string) => {
    setCheckDownLoadPdf(true)
    const parent = document.createElement('div')
    const title = document.createElement('p')
    parent.style.textAlign = 'center'
    title.innerText = 'TUDIP TECHNOLOGIES PVT. LTD.'
    title.style.fontSize = '24px'
    title.style.fontWeight = '800'
    title.style.fontFamily = style.FONT_BOLD
    title.style.display = 'inline-block'
    title.style.marginTop = '-50px !important'
    parent.appendChild(title)
    const element = document.getElementById('pdf-export')
    const leaveReportName = `${name}'s_Leave_Report`

    if (element) {
      const accordions = document.querySelectorAll('.MuiAccordion-root')
      accordions.forEach((accordion) => {
        const panel: any = accordion.querySelector('.MuiAccordionSummary-root')
        if (panel) {
          panel.click()
        }
      })

      await new Promise((resolve) => setTimeout(resolve, 500))

      const options = {
        margin: 10,
        filename: leaveReportName,
        image: { type: 'png', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait' },
      }
      parent.appendChild(element.cloneNode(true))
      html2pdf().set(options).from(parent).save()

      accordions.forEach((accordion) => {
        const panel: any = accordion.querySelector('.MuiAccordionSummary-root')
        if (panel) {
          panel.click()
        }
      })

      setCheckDownLoadPdf(false)
    }
  }

  const AllocatedLeaves = (props: {
    totalLeaveCount: number
    allocatedLeaves: ILeaveDetails[]
  }) => {
    const { totalLeaveCount, allocatedLeaves } = props

    return (
      <>
        <LeavesDetailsHeading>ALLOCATED LEAVES ({totalLeaveCount})</LeavesDetailsHeading>

        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700, border: '2px solid #d1cbcbf2' }}
            aria-label='customized table'
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>LEAVE TYPE</StyledTableCell>
                <StyledTableCell>LEAVE START DATE</StyledTableCell>
                <StyledTableCell>LEAVE END DATE</StyledTableCell>
                <StyledTableCell>COUNT</StyledTableCell>
                <StyledTableCell style={{ width: '32%' }}>DESC</StyledTableCell>
                <StyledTableCell style={{ width: '10%' }}>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allocatedLeaves?.map((leave, i) => (
                <StyledTableRow key={`${leave?.leaveStartDate}${leave?.count}`}>
                  <StyledTableCell component='th' scope='row'>
                    {leave?.leaveType}
                  </StyledTableCell>
                  <StyledTableCell align='right'>
                    {new Date(leave?.leaveStartDate).toLocaleDateString('en-GB')}
                  </StyledTableCell>
                  <StyledTableCell align='right'>
                    {new Date(leave?.leaveEndDate).toLocaleDateString('en-GB')}
                  </StyledTableCell>
                  <StyledTableCell align='right'>{leave?.count}</StyledTableCell>
                  <StyledTableCell align='right'>{leave?.desc}</StyledTableCell>
                  <StyledTableCell align='right'>
                    <Tooltip title={'Delete'} arrow>
                      <DeleteIcon
                        sx={{
                          cursor: 'pointer',
                        }}
                        onClick={(event) => {
                          event.stopPropagation()
                          handleDeleteLeave(leave)
                        }}
                      />
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              <StyledTableRow key={888}>
                <StyledTableCell component='th' scope='row' colSpan={3} style={LastCellStyle}>
                  Total Leaves Allocated:
                </StyledTableCell>
                <StyledTableCell align='right' style={LastCellStyle}>
                  {totalLeaveCount}
                </StyledTableCell>
                <StyledTableCell align='right'></StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  const DeductibleLeavesAvailed = (props: {
    totalDeductibleLeaves: number
    deductibleLeavesAvailed: ILeaveDetails[]
  }) => {
    const { totalDeductibleLeaves, deductibleLeavesAvailed } = props

    return (
      <>
        <LeavesDetailsHeading>
          DEDUCTIBLE LEAVES AVAILED ({totalDeductibleLeaves})
        </LeavesDetailsHeading>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700, border: '2px solid #d1cbcbf2' }}
            aria-label='customized table'
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>LEAVE TYPE</StyledTableCell>
                <StyledTableCell>LEAVE START DATE</StyledTableCell>
                <StyledTableCell>LEAVE END DATE</StyledTableCell>
                <StyledTableCell>COUNT</StyledTableCell>
                <StyledTableCell style={{ width: '32%' }}>DESC</StyledTableCell>
                <StyledTableCell style={{ width: '10%' }}>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deductibleLeavesAvailed?.map((leave, i) => (
                <>
                  <StyledTableRow key={`${leave?.leaveStartDate}${leave?.count}`}>
                    <StyledTableCell component='th' scope='row'>
                      {leave?.leaveType}
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      {new Date(leave?.leaveStartDate).toLocaleDateString('en-GB')}
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      {new Date(leave?.leaveEndDate).toLocaleDateString('en-GB')}
                    </StyledTableCell>
                    <StyledTableCell align='right'>{leave?.count}</StyledTableCell>
                    <StyledTableCell align='right'>{leave?.desc}</StyledTableCell>
                    <StyledTableCell align='right'>
                      <Tooltip title={'Delete'} arrow>
                        <DeleteIcon
                          sx={{
                            cursor: 'pointer',
                          }}
                          onClick={(event) => {
                            event.stopPropagation()
                            handleDeleteLeave(leave)
                          }}
                        />
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              ))}
              <StyledTableRow>
                <StyledTableCell component='th' scope='row' colSpan={3} style={LastCellStyle}>
                  Total Deductible Leaves:
                </StyledTableCell>
                <StyledTableCell align='right' style={LastCellStyle}>
                  {totalDeductibleLeaves}
                </StyledTableCell>
                <StyledTableCell align='right'></StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  const NonDeductibleLeavesAvailed = (props: {
    totalNonDeductibleLeavesAvailed: number
    nonDeductibleLeavesAvailed: ILeaveDetails[]
  }) => {
    const { totalNonDeductibleLeavesAvailed, nonDeductibleLeavesAvailed } = props
    return (
      <>
        <LeavesDetailsHeading>
          NON DEDUCTIBLE LEAVES AVAILED ({totalNonDeductibleLeavesAvailed})
        </LeavesDetailsHeading>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700, border: '2px solid #d1cbcbf2' }}
            aria-label='customized table'
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>LEAVE TYPE</StyledTableCell>
                <StyledTableCell>LEAVE START DATE</StyledTableCell>
                <StyledTableCell>LEAVE END DATE</StyledTableCell>
                <StyledTableCell>COUNT</StyledTableCell>
                <StyledTableCell style={{ width: '32%' }}>DESC</StyledTableCell>
                <StyledTableCell style={{ width: '10%' }}>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nonDeductibleLeavesAvailed?.map((leave, i) => (
                <>
                  <StyledTableRow key={`${leave?.leaveType}${leave?.leaveStartDate}`}>
                    <StyledTableCell component='th' scope='row'>
                      {leave?.leaveType}
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      {new Date(leave?.leaveStartDate).toLocaleDateString('en-GB')}
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      {new Date(leave?.leaveEndDate).toLocaleDateString('en-GB')}
                    </StyledTableCell>
                    <StyledTableCell align='right'>{leave?.count}</StyledTableCell>
                    <StyledTableCell align='right'>{leave?.desc}</StyledTableCell>
                    <StyledTableCell align='right'>
                      <Tooltip title={'Delete'} arrow>
                        <DeleteIcon
                          sx={{
                            cursor: 'pointer',
                          }}
                          onClick={(event) => {
                            event.stopPropagation()
                            handleDeleteLeave(leave)
                          }}
                        />
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              ))}
              <StyledTableRow>
                <StyledTableCell component='th' scope='row' colSpan={3} style={LastCellStyle}>
                  Total Non Deductible Leaves:
                </StyledTableCell>
                <StyledTableCell align='right' style={LastCellStyle}>
                  {totalNonDeductibleLeavesAvailed}
                </StyledTableCell>
                <StyledTableCell align='right'></StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  return (
    <div style={MainContainer}>
      {loaderState && (
        <Dialog open={loaderState} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      {leaveDetails && Object.values(leaveDetails) && (
        <>
          <UpdateLeaveInfo
            open={open}
            setOpen={setOpen}
            getLeaveTypes={getLeaveTypes}
            createLeave={createLeave}
            userId={userId}
            isUserLeaveLoading={isUserLeaveLoading}
            isUserLeaveCreated={isUserLeaveCreated}
            createLeaveReset={createLeaveReset}
            updateLeaveReset={updateLeaveReset}
            leaveType={leaveType}
            setLeaveType={setLeaveType}
            updateLeave={updateLeave}
            isUserLeaveUpdated={isUserLeaveUpdated}
            isUpdateLeaveLoading={isUpdateLeaveLoading}
            fetchLeaveDetails={fetchLeaveDetails}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Box
              onClick={() => navigate(-1)}
              sx={{
                cursor: 'pointer',
                paddingRight: '60px',
                marginTop: '-10px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <ArrowBack />
            </Box>
            {/* 
            <StyledButton
              variant='contained'
              width='180px'
              height='40px'
              hoverColor='#1565c0'
              onClick={handlerOpenUpdateLeave}
              sx={{
                cursor: 'pointer',
                marginTop: '10px', // Added margin to space out the button
                marginRight: '60px', // Adjust the right margin to ensure the button stays at the right
              }}
            >
              UPDATE LEAVE
            </StyledButton> */}
            <Box>
              <ActionButton
                variant='outlined'
                sx={{
                  width: '200px',
                  mt: 1,
                  cursor: 'pointer',
                  marginTop: '10px', // Added margin to space out the button
                  marginRight: '60px',
                }}
                startIcon={
                  <Avatar sx={{ width: 24, height: 24 }}>
                    <img src={PdfIcon} alt='PDF' />
                  </Avatar>
                }
                onClick={(e) => handleExportPDF(leaveDetails?.employeeName)}
              >
                EXPORT AS PDF
              </ActionButton>
              <ActionButton
                variant='outlined'
                startIcon={
                  <UpdateLeaveIcon style={{ width: '30px', height: '30px', fill: 'white' }} />
                }
                onClick={() => handleAddOpen(0)}
                sx={{
                  width: '200px',
                  mt: 1,
                  cursor: 'pointer',
                  marginTop: '10px', // Added margin to space out the button
                  marginRight: '10px',
                }}
              >
                UPDATE LEAVES{' '}
              </ActionButton>
              <ActionButton
                variant='outlined'
                startIcon={<AddTwoToneIcon sx={{ width: 24, height: 24 }} />}
                onClick={() => handleAddOpen(1)}
                sx={{
                  width: '300px',
                  mt: 1,
                  cursor: 'pointer',
                  marginTop: '10px', // Added margin to space out the button
                  marginRight: '10px',
                }}
              >
                ADD PREVIOUS YEAR's LEAVES
              </ActionButton>
            </Box>
          </Box>

          <Box id='pdf-export'>
            <StyledPaper elevation={3}>
              <p style={Heading}>Leave Accrual Report </p>

              <div style={SplitDiv}>
                <PaperBlock variant='outlined' square>
                  Employee Id:
                </PaperBlock>
                <PaperBlock variant='outlined' square>
                  {leaveDetails?.employeeId}
                </PaperBlock>
                <PaperBlock variant='outlined' square>
                  Total Leaves Allocated:
                </PaperBlock>
                <PaperMiniBlock variant='outlined' square>
                  {leaveDetails?.totalLeaves}
                </PaperMiniBlock>
              </div>
              <div style={SplitDiv}>
                <PaperBlock variant='outlined' square>
                  Employee Name:
                </PaperBlock>

                <PaperBlock variant='outlined' square>
                  {leaveDetails?.employeeName}
                </PaperBlock>
                <PaperBlock variant='outlined' square>
                  Leaves Availed:
                </PaperBlock>
                <PaperMiniBlock variant='outlined' square>
                  {leaveDetails?.availedLeaves}
                </PaperMiniBlock>
              </div>
              <div style={SplitDiv}>
                <PaperBlock variant='outlined' square>
                  Designation:
                </PaperBlock>

                <PaperBlock variant='outlined' square>
                  {leaveDetails?.designation}
                </PaperBlock>
                <PaperBlock variant='outlined' square>
                  Leave Balance:
                </PaperBlock>
                <PaperMiniBlock variant='outlined' square>
                  {leaveDetails?.balanceLeaves}
                </PaperMiniBlock>
              </div>
              <div style={SplitDiv}>
                <PaperBlock variant='outlined' square>
                  Accrual Type:
                </PaperBlock>

                <PaperBlock variant='outlined' square>
                  {leaveDetails?.accrualType}
                </PaperBlock>
                <PaperBlock variant='outlined' square>
                  Leaves Allocated in previous FY:
                </PaperBlock>
                <PaperMiniBlock variant='outlined' square>
                  {leaveDetails?.previousYearLeaves}
                </PaperMiniBlock>
              </div>
              {leaveDetails && leaveDetails?.encashedLeaves >= 0 ? (
                <div style={SplitDiv}>
                  <PaperBlock variant='outlined' square>
                    Previous FY Leave encashment:
                  </PaperBlock>
                  <PaperSepBlock variant='outlined' square>
                    {leaveDetails?.encashedLeaves}
                  </PaperSepBlock>
                </div>
              ) : (
                <div style={SplitDiv}>
                  <PaperBlock variant='outlined' square>
                    Extra Leaves availed in previous FY year:
                  </PaperBlock>

                  <PaperSepBlock variant='outlined' square>
                    -{leaveDetails?.encashedLeaves}
                  </PaperSepBlock>
                </div>
              )}
            </StyledPaper>
            {leaveDetails &&
              leaveDetails?.leaveAccrualList &&
              leaveDetails?.leaveAccrualList?.length > 0 &&
              leaveDetails?.leaveAccrualList?.map((leaveAccrual: any, i: any) => (
                <div
                  key={`${leaveAccrual?.totalLeaveCount}${leaveAccrual?.allocatedLeaves}`}
                  style={MultiAccordion}
                >
                  <Accordion
                    expanded={checkDownLoadPdf ? checkDownLoadPdf : expanded === `panel${i}`}
                    onChange={handleChange(`panel${i}`)}
                    sx={AccordionStyle}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1bh-content'
                      sx={{
                        backgroundColor: '#F2F2F2',
                      }}
                    >
                      <Typography sx={AccordingHeading}>
                        {`Leave Details for the Financial Year ${leaveAccrual?.financialYear}`}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <AllocatedLeaves
                        totalLeaveCount={leaveAccrual?.totalLeaveCount}
                        allocatedLeaves={leaveAccrual?.allocatedLeaves}
                      />
                      <DeductibleLeavesAvailed
                        totalDeductibleLeaves={leaveAccrual?.totalDeductibleLeaves}
                        deductibleLeavesAvailed={leaveAccrual?.deductibleLeavesAvailed}
                      />
                      <NonDeductibleLeavesAvailed
                        totalNonDeductibleLeavesAvailed={
                          leaveAccrual?.totalNonDeductibleLeavesAvailed
                        }
                        nonDeductibleLeavesAvailed={leaveAccrual?.nonDeductibleLeavesAvailed}
                      />
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
          </Box>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isUserLeaveDeleted: fetchUserDetailsUI.fetchUserData(state).isUserLeaveDeleted,
    isUserLeaveUpdated: fetchUserDetailsUI.fetchUserData(state).isUserLeaveUpdate,
    isUpdateLeaveLoading: fetchUserDetailsUI.fetchUserData(state).isUpdateLeaveLoading,
    isUserLeaveCreated: fetchUserDetailsUI.fetchUserData(state).isUserLeaveCreated,
    isUserLeaveLoading: fetchUserDetailsUI.fetchUserData(state).isUserLeaveLoading,
    getLeaveTypes: fetchUserDetailsEntities.fetchUserData(state).getLeaveTypes,
    leaveDetails: dashboardEntity.getDashboard(state).getLeaveDetails,
    loaderState: dashboardUI.getDashboard(state).isLeaveData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    deleteLeave: (data: any) => dispatch(deleteLeave.request(data)),
    deleteLeaveReset: () => dispatch(deleteLeave.reset()),
    createLeave: (data: any) => dispatch(createLeave.request(data)),
    createLeaveReset: () => dispatch(createLeave.reset()),
    updateLeave: (data: any) => dispatch(updateLeave.request(data)),
    updateLeaveReset: () => dispatch(updateLeave.reset()),
    fetchLeaveType: (data: any) => dispatch(fetchLeaveType.request(data)),
    fetchLeaveDetails: (data: any) => dispatch(fetchLeaveDetails.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLeaveDetails)
