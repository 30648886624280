// Cards.tsx
import { Avatar, Box, Card, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import style from '../../../utils/styles.json'

const StyledCard = styled(Card)(({ theme }) => ({
  width: '250px',
  height: '150px',
  border: '1px solid #66B3FF',
  cursor: 'pointer',
  background: 'linear-gradient(to bottom, hsla(210, 100%, 90%, 0.5), hsla(210, 100%, 90%, 0.3))',
  borderRadius: '15px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',

  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.3)', // Enhanced shadow
  },
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  minHeight: '20px',
  textAlign: 'center',
  fontWeight: style.FONT_BOLD,
  fontFamily: style.FONT_MEDIUM,
}))

interface CardProps {
  name: string
  avatar: string
  handleNavigateToCard: any
}

const Cards = ({ name, avatar, handleNavigateToCard }: CardProps) => {
  return (
    <Tooltip title={name === "Leave Info" ? "Coming soon" : ""} arrow>
      <StyledCard
        onClick={() => handleNavigateToCard(name)}
        sx={{ cursor: name === "Leave Info" ? "not-allowed" : "pointer" }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            p: 2,
            mt: 1,
          }}
        >
          <Avatar sx={{ width: 70, height: 70, padding:'6px' }} src={avatar}>
            {name.charAt(0)}
          </Avatar>
          <StyledTypography>{name}</StyledTypography>
        </Box>
      </StyledCard>
    </Tooltip>
  )
}

export default Cards
