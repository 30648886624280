import {
  Avatar,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Typography,
  styled,
  Grid,
  CircularProgress,
  Dialog,
  tableCellClasses,
  Box,
} from '@mui/material'
import React, { useState } from 'react'
import html2pdf from 'html2pdf.js'
import PdfIcon from '../../assets/images/pdf_image.png'
import { connect, useSelector } from 'react-redux'
import { RootState } from '../../configureStore'
import { Dispatch } from 'redux'
import { getPaySlip, getTaxFinancialYear, getTaxReport, getTimesheet } from '../../actions'
import { dashboardEntity, financeEntity, financeUI } from '../../reducers'
import moment from 'moment'
import { StyledTableRow, loaderProps } from '../Common/CommonStyles'
import style from '../../utils/styles.json'
import { StyledPaper } from '../OrgCharts/OrgStyles'
import { useLocation, useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontFamily: style.FONT_MEDIUM,
  textAlign: 'center',
  letterSpacing: '0px',
}))

const SubHeading = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontFamily: style.FONT_MEDIUM,
  textAlign: 'center',
  letterSpacing: '0px',
  color: '#6c6b6b',
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f5f5f5', 
    color: '#000000',
    fontFamily: style.FONT_MEDIUM,
    fontWeight: "bold",
    textAlign: 'center',
    fontSize: '13px',
    letterSpacing: '0px',
    '& .MuiTableCell-root': { padding: '6px' },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: style.FONT_MEDIUM,
    textAlign: 'center',
    color: '#483f3f',
    letterSpacing: '0px',
    padding: '6px 6px 6px 16px',
  },
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '13px',
  fontFamily: style.FONT_MEDIUM,
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  // marginTop: '4.5px',
}))

const SelectField = styled(Select)(({ theme }) => ({
  width: '240px',
  borderRadius: '20px',
  '& .MuiSelect-select': {
    padding: '11px 14px',
    fontSize: '13px',
    fontFamily: style.FONT_MEDIUM,
    borderRadius: '20px',
  },
}))

const handleExportPDF = (year: string) => {
  const element = document.getElementById('pdf-export')
  const PaySlipname = `Tax_Report_${year}`

  if (element) {
    const options = {
      margin: 10,
      filename: PaySlipname,
      image: { type: 'png', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait' },
    }

    html2pdf().set(options).from(element).save()
  }
}

const TaxReport = (props: any) => {

  const {
    timesheetData,
    isGettingTaxReportData,
    fetchPaySlips,
    payslipData,
    loaderStateoftimeSheet,
    fetchFinancialYear,
    financialYearData,
    fetchTaxReport,
    taxReportData,
    userInfo,
  } = props

  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const navigate = useNavigate()
  const location = useLocation()
  const userId = new URLSearchParams(location.search).get('userId')
  const id = userId ? userId : userInfo.id;

  const [payslipMonth, setPayslipMonth] = useState('')
  const [paySlipYear, setPaySlipYear] = useState(0)
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedPaySlipDateOption, setPaySlipDateOption] = useState('')

  React.useEffect(() => {
    if (id) {
      fetchFinancialYear({ userId: id })
    }
  }, [userInfo])

  React.useEffect(() => {
    if (financialYearData.length > 0) {
      setPaySlipDateOption(financialYearData[0].id);
    }
  }, [financialYearData]);

  React.useEffect(() => {
    const selectedDate = financialYearData.find((item: any) => item.id === selectedPaySlipDateOption);

    if (selectedDate) {
      const startYear = moment(selectedDate.start_date).format('YYYY');
      const endYear = moment(selectedDate.end_date).format('YYYY');
      setSelectedYear(`${startYear}-${endYear}`);
    }
    if (selectedPaySlipDateOption) {
      fetchTaxReport({ userId: id, yearId: selectedPaySlipDateOption })
    }
  }, [selectedPaySlipDateOption])

  // React.useEffect(() => {
  //   if (loaderStateoftimeSheet && timesheetData.length) {
  //     setPaySlipDateOption(timesheetData[0].id)
  //     let dateIndex: number = new Date(timesheetData[0].start_date).getMonth()
  //     setPayslipMonth(month[dateIndex])
  //     setPaySlipYear(new Date(timesheetData[0].start_date).getFullYear())
  //     fetchPaySlips(timesheetData[0].id)
  //   }
  // }, [loaderStateoftimeSheet])

  const handlePaySlipDateChange = (event: any, timesheets: any[]) => {
    const selectedId = event.target.value
    const selectedTimesheet = timesheets.find((timesheet) => timesheet.id === selectedId)
    if (selectedTimesheet) {
      let dateIndex: number = new Date(selectedTimesheet.start_date).getMonth()
      setPayslipMonth(month[dateIndex])
      setPaySlipYear(new Date(selectedTimesheet.start_date).getFullYear())
      setPaySlipDateOption(selectedTimesheet.id)
      // fetchPaySlips(selectedTimesheet.id)
    }
  }

  const CompanyName = useSelector((state: { entities: { dashboard: { getUserDetails: { company_name: string } } } }) => state?.entities?.dashboard?.getUserDetails?.company_name)

  return (
    <>
      {isGettingTaxReportData && (
        <Dialog open={isGettingTaxReportData} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      {/* <StyledPaper>
        <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <StyledFormControl>
              <InputLabel id='demo-simple-select-readonly-label'>Select Financial Year</InputLabel>
              <SelectField
                variant='outlined'
                value={selectedPaySlipDateOption}
                label='Select Financial Year:'
                onChange={(event) => handlePaySlipDateChange(event, financialYearData)}
              >
                {financialYearData?.map((option: any) => (
                  <StyledMenuItem key={`${option?.id}`} value={option?.id}>
                    ({moment(option?.start_date).format('DD/MM/YYYY')}) To (
                    {moment(option?.end_date).format('DD/MM/YYYY')})
                  </StyledMenuItem>
                ))}
              </SelectField>
            </StyledFormControl>
          </div>

          <div style={ExportButton}>
            <Button
              variant='outlined'
              sx={{
                fontSize: '14px',
                height: '42px',
                float: 'right',
                borderRadius: '20px',
              }}
              startIcon={
                <Avatar sx={{ width: 24, height: 24 }}>
                  <img src={PdfIcon} alt='PDF' />
                </Avatar>
              }
              onClick={(e) =>
                handleExportPDF(
                  payslipData?.employeeDetails?.full_name,
                  payslipMonth,
                  paySlipYear,
                )
              }
            >
              EXPORT AS PDF
            </Button>
          </div>
        </Grid>

        <div id='pdf-export'>
          <Heading>{CompanyName}</Heading>
          <SubHeading>
            Employees Tax Report for the year {selectedYear}
          </SubHeading>

          <TableContainer sx={{ margin: '2rem 0', border: '1px solid #DDDDDD' }} component={Paper}>
            <Table sx={{ minWidth: '85vw' }} aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ textAlign: 'center !important', padding: '8px 5px 8px 16px' }}>
                    MONTH
                  </StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center !important', padding: '8px 5px 8px 16px', }}>
                    TAX AMOUNT DEDUCTED <span style={{ fontSize: '14px' }}>( ₹ )</span>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {taxReportData.length > 0 ? (
                  taxReportData.map((data: any, index: any) => (
                    <TableRow key={index} sx={{ cursor: "pointer" }}>
                      <StyledTableCellForMyTeam sx={{ textAlign: "center !important" }}>
                        {moment(data.created_at).format("MMMM-YYYY")}
                      </StyledTableCellForMyTeam>
                      <StyledTableCellForMyTeam sx={{ textAlign: "center !important" }}>
                        {data.pay_period_amount}
                      </StyledTableCellForMyTeam>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <StyledTableCellForMyTeam colSpan={2} sx={{ textAlign: "center !important" }}>
                      No data available
                    </StyledTableCellForMyTeam>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </StyledPaper> */}
      <StyledPaper sx={{ minWidth:'85vw',padding: '2rem', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
      {/* Header Section */}
      <Grid container spacing={3} justifyContent="space-between" alignItems="center">
        {/* Select Financial Year */}
        <Grid item xs={12} sm={6} md={6}>
          <StyledFormControl>
              <InputLabel id='demo-simple-select-readonly-label'>Select Financial Year</InputLabel>
              <SelectField
                variant='outlined'
                value={selectedPaySlipDateOption}
                label='Select Financial Year:'
                sx={{top:'4px'}}
                onChange={(event) => handlePaySlipDateChange(event, financialYearData)}
              >
                {financialYearData?.map((option: any) => (
                  <StyledMenuItem key={`${option?.id}`} value={option?.id}>
                    ({moment(option?.start_date).format('DD/MM/YYYY')}) To (
                    {moment(option?.end_date).format('DD/MM/YYYY')})
                  </StyledMenuItem>
                ))}
                {financialYearData?.length === 0 && (
                  <StyledMenuItem disabled>
                    No data available
                  </StyledMenuItem>
                )}
              </SelectField>
            </StyledFormControl>
        </Grid>

        {/* Export Button */}
        <Grid item xs={12} sm={6} md={6} sx={{ display:'flex',alignItems:'center',justifyContent:'end' }}>
          <Button
            variant="outlined"
            sx={{
              fontSize: '14px',
              height: '42px',
              borderRadius: '20px',
              padding: '0 20px',
              fontWeight: 'bold',
              borderColor: '#00A4B4',
              color: '#fffff',
              '&:hover': {
                backgroundColor: '#00A4B4',
                color: '#fff',
              },
            }}
            startIcon={
              <Avatar sx={{ width: 24, height: 24 }}>
                <img src={PdfIcon} alt="PDF" />
              </Avatar>
            }
            onClick={(e) => handleExportPDF(selectedYear)}
          >
            EXPORT AS PDF
          </Button>
          <Box onClick={() => navigate(-1)} sx={{ float: 'right', top: 6,mt: '0px', cursor: 'pointer',marginLeft:'10px' }}>
          <ArrowBack />
        </Box>
        </Grid>
      </Grid>

      {/* Table Section */}
      <div id="pdf-export" style={{ marginTop: '2rem' }}>
        <Heading variant="h5" sx={{ fontWeight: '600', textAlign: 'center' }}>
          {CompanyName}
        </Heading>
        <SubHeading variant="subtitle1" sx={{ textAlign: 'center', marginTop: '0.5rem', color: '#555' }}>
        Employees Tax Report {selectedYear ? `for the year ${selectedYear}` : ''}
        </SubHeading>

        <TableContainer sx={{ margin: '2rem 0', border: '1px solid #DDDDDD', borderRadius: '10px' }} component={Paper}>
          <Table sx={{ minWidth: '100%' }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ width: '50%',textAlign: 'center', fontWeight: '`bold`', padding: '10px', backgroundColor: '#f5f5f5' }}>
                  MONTH
                </StyledTableCell>
                <StyledTableCell sx={{ width: '50%',textAlign: 'center', fontWeight: 'bold', padding: '10px', backgroundColor: '#f5f5f5',borderLeft:'1px solid #DDDDDD' }}>
                  TAX AMOUNT DEDUCTED <span style={{ fontSize: '14px' }}>( ₹ )</span>
                </StyledTableCell>
              </TableRow>
            </TableHead>

            {/* Table Body with Conditional Rendering for No Data */}
            <TableBody>
              {taxReportData.length > 0 ? (
                taxReportData.map((data: any, index: any) => (
                  <TableRow key={index} sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#f5f5f5' } }}>
                    <StyledTableCell sx={{ textAlign: 'center', padding: '10px', color: '#333' }}>
                      {moment(data.created_at).subtract(1, 'months').format('MMMM-YYYY')}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: 'center', padding: '10px', color: '#333',borderLeft:'1px solid #DDDDDD' }}>
                      {data.pay_period_amount}
                    </StyledTableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <StyledTableCell colSpan={2} sx={{ textAlign: 'center', padding: '10px', color: '#888' }}>
                    No data available
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </StyledPaper>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    userInfo: dashboardEntity.getDashboard(state).getUserDetails,
    financialYearData: financeEntity.getFinance(state).getTaxFinancialYear,
    taxReportData: financeEntity.getFinance(state).getTaxReportData,
    payslipData: financeEntity.getFinance(state).getPaySlipData,
    loaderStateoftimeSheet: financeUI.getFinance(state).isTimesheetData,
    isGettingTaxReportData: financeUI.getFinance(state).isGettingTaxReportData,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchFinancialYear: (data: any) => dispatch(getTaxFinancialYear.request(data)),
    fetchTaxReport: (data: any) => dispatch(getTaxReport.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaxReport)
