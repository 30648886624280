import { useFormik } from 'formik'
import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { CircularProgress, MenuItem, TextField } from '@mui/material'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Box } from '@mui/system'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import dayjs from 'dayjs'
import styles from '../../utils/styles.json'
import { StyledButton, StyledMenuItem } from './UserDetailsStyle'
import style from '../../utils/styles.json'
import { toast } from 'react-toastify'
import { loaderProps } from '../Common/CommonStyles'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '30px 40px',
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'center',
    gap: '15px',
    padding: '20px 0',
  },
}))

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: '26px',
  textAlign: 'center',
  fontFamily: style.FONT_MEDIUM,
  letterSpacing: '0px',
}))

const InputField = styled(TextField)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '13.5px 14px', // Adjust padding for alignment
    fontSize: '14px',
    fontFamily: styles.FONT_MEDIUM,
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  '& .MuiInputLabel-root': {
    fontFamily: styles.FONT_MEDIUM,

    fontSize: '14px', // Ensure label font size matches the input
    transform: 'translate(14px, 16px) scale(1)', // Position the label correctly
    transition: 'all 0.2s ease', // Smooth animation for floating label
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(14px, -6px) scale(0.75)', // Position and scale when input is focused
  },
}))

const ActionButton = styled(Button)(({ theme }) => ({
  fontSize: '13px',
  height: '42px',
  width: '20%',
  borderRadius: '20px',
}))

const CancelButton = styled(Button)(({ theme }) => ({
  background: '#E2E2E2',
  color: '#000000',
  fontSize: '13px',
  height: '42px',
  width: '20%',
  borderRadius: '20px',

  transition: 'all 0.2s ease-in-out',

  '&:hover': {
    background: '#E2E2E2',
    color: '#000000',
    transform: 'scale(1.05)', // Add scale effect on hover
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)', // Shado
  },
}))

export const SelectField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    padding: '11px 10px', // Adjust the padding here
    fontSize: '14px', // Adjust the font size here
    fontFamily: style.FONT_MEDIUM,
    marginLeft: '5px',
  },
  '& .MuiFormLabel-root': {
    marginTop: '2px',
    fontSize: '14px',
    fontFamily: style.FONT_MEDIUM,
    backgroundColor: 'white',
    padding: '0 7px',
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
  // Styling the dropdown (Menu) and MenuItems
  '& .MuiSelect-root': {
    '& .MuiSelect-menu': {
      maxHeight: '200px', // Adjust max height of the dropdown menu
      overflowY: 'auto', // Enable scrolling when items overflow
    },
  },
}))

const UpdateLeaveInfo = (props: any) => {
  const {
    open,
    setOpen,
    getLeaveTypes,
    createLeave,
    userId,
    isUserLeaveLoading,
    isUserLeaveCreated,
    createLeaveReset,
    updateLeaveReset,
    leaveType,
    updateLeave,
    isUserLeaveUpdated,
    isUpdateLeaveLoading,
    fetchLeaveDetails,
  } = props

  // Yup validation schema
  const validationSchema = Yup.object({
    leaveType: leaveType === 1 ? Yup.string() : Yup.string().required('Leave Type is required'),
    leaveStartDate: Yup.date().required('Leave start date is required'),
    leaveEndDate: Yup.date()
      .min(Yup.ref('leaveStartDate'), 'End date must be after start date')
      .required('Leave end date is required'),
    count: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
  })

  const formik = useFormik({
    initialValues: {
      leaveType: '',
      leaveStartDate: '',
      leaveEndDate: '',
      count: '',
      description: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {},
  })

  const handleClose = () => {
    formik.resetForm()
    setOpen((pre: boolean) => !pre)
  }

  useEffect(() => {
    if (isUserLeaveCreated) {
      toast.success('Leave Created Successfully!')
      createLeaveReset()
      handleClose()
    }
  }, [isUserLeaveCreated])

  useEffect(() => {
    if (isUserLeaveUpdated) {
      toast.success('Leave Updated Successfully!')
      updateLeaveReset()
      handleClose()
    }
  }, [isUserLeaveUpdated])

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formik.values, { abortEarly: false })
      const addEntity = {
        uid: userId,
        data: {
          id_leave_type: 257,
          leave_start_date: formik.values.leaveStartDate,
          leave_end_date: formik.values.leaveEndDate,
          count: Number(formik.values.count),
          desc: formik.values.description,
        },
      }
      const updateEntity = {
        uid: userId,
        data: {
          id_leave_type: formik.values.leaveType,
          leave_start_date: formik.values.leaveStartDate,
          leave_end_date: formik.values.leaveEndDate,
          count: Number(formik.values.count),
          desc: formik.values.description,
        },
      }
      leaveType === 1 && createLeave(addEntity)
      leaveType === 0 && updateLeave(updateEntity)
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const stepErrors = error.inner.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr.message
          }
          return acc
        }, {} as { [K in keyof any]?: string })

        formik.setTouched(
          Object.keys(stepErrors).reduce((acc, key) => {
            acc[key] = true
            return acc
          }, {} as { [K in keyof any]: any }),
        )

        formik.setErrors(stepErrors)
      } else {
        console.error('Unexpected error:', error)
      }
    }
  }

  return (
    <>
      {(isUserLeaveLoading || isUpdateLeaveLoading) && (
        <Dialog
          open={isUserLeaveLoading || isUpdateLeaveLoading}
          PaperProps={{ style: loaderProps }}
        >
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
          <Heading>{leaveType === 0 ? 'UPDATE LEAVES' : "ADD LEAVES"}</Heading>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px', // Adjust the gap between fields as needed
                width: '100%',
              }}
            >
              <SelectField
                size='small'
                id='leaveType'
                name='leaveType'
                label='Leave Type'
                select
                value={leaveType === 0 ? formik.values.leaveType : 1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  margin: '0',
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 200, 
                        overflowY: 'auto', 
                      },
                    },
                  },
                }}
                required={true}
                error={formik.touched.leaveType && Boolean(formik.errors.leaveType)}
              >
                {leaveType === 0 ? (
                  getLeaveTypes?.length > 0 &&
                  getLeaveTypes?.map((type: any) => (
                    <StyledMenuItem key={type.id} value={type.id}>
                      {type.leave_type_name}
                    </StyledMenuItem>
                  ))
                ) : (
                  <StyledMenuItem value={leaveType}>Add/Update Leaves</StyledMenuItem>
                )}
              </SelectField>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  gap: '10px', // Adjust the gap between DatePickers
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={['DatePicker']}
                    sx={{
                      overflow: 'visible',
                    }}
                  >
                    <DatePicker
                      label='Leave Start Date'
                      value={
                        formik.values.leaveStartDate ? dayjs(formik.values.leaveStartDate) : null
                      }
                      onChange={(date: any) => {
                        formik.setFieldValue('leaveStartDate', date?.format('YYYY-MM-DD'))
                        formik.setFieldValue('leaveEndDate', null) // Reset leaveEndDate if leaveStartDate changes
                      }}
                      maxDate={dayjs()} // Disable future dates
                      slotProps={{
                        textField: {
                          error:
                            formik.touched.leaveStartDate && Boolean(formik.errors.leaveStartDate),
                          // helperText: formik.touched.leaveStartDate && formik.errors.leaveStartDate,
                        },
                      }}
                      sx={{
                        border: 'none',
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '20px',
                          height: '45px',
                          fontSize: '14px',
                          fontFamily: styles.FONT_MEDIUM,
                          boxSizing: 'border-box',
                        },
                        '& .MuiInputLabel-root': {
                          fontSize: '14px',
                          fontFamily: styles.FONT_MEDIUM,
                          marginTop: '-2px',
                          '& .MuiFormLabel-asterisk': {
                            color: 'red',
                          },
                        },
                        flex: 1,
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={['DatePicker']}
                    sx={{
                      overflow: 'visible',
                    }}
                  >
                    <DatePicker
                      label='Leave End Date'
                      value={formik.values.leaveEndDate ? dayjs(formik.values.leaveEndDate) : null}
                      onChange={(date: any) => {
                        formik.setFieldValue('leaveEndDate', date?.format('YYYY-MM-DD'))
                      }}
                      minDate={
                        formik.values.leaveStartDate
                          ? dayjs(formik.values.leaveStartDate).add(1, 'day')
                          : null
                      } // Ensure leaveEndDate is after leaveStartDate
                      maxDate={dayjs()} // Disable future dates
                      slotProps={{
                        textField: {
                          error: formik.touched.leaveEndDate && Boolean(formik.errors.leaveEndDate),
                          // helperText: formik.touched.leaveEndDate && formik.errors.leaveEndDate,
                        },
                      }}
                      sx={{
                        border: 'none',
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '20px',
                          height: '45px',
                          fontSize: '14px',
                          fontFamily: styles.FONT_MEDIUM,
                          boxSizing: 'border-box',
                        },
                        '& .MuiInputLabel-root': {
                          fontSize: '14px',
                          fontFamily: styles.FONT_MEDIUM,
                          marginTop: '-2px',
                          '& .MuiFormLabel-asterisk': {
                            color: 'red',
                          },
                        },
                        flex: 1,
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>

              <InputField
                id='count'
                name='count'
                label='Count'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.count}
                error={formik.touched.count && Boolean(formik.errors.count)}
                //   helperText={formik.touched.count && formik.errors.count}
              />

              <InputField
                id='description'
                name='description'
                label='Description'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                error={formik.touched.description && Boolean(formik.errors.description)}
                //   helperText={formik.touched.description && formik.errors.description}
              />
            </Box>
          </form>
        </DialogContent>

        <DialogActions>
          <CancelButton onClick={handleClose}>CANCEL</CancelButton>
          <StyledButton
            type='submit'
            variant='contained'
            width='120px'
            height='40px'
            hoverColor='#1565c0'
            fontSize='13px !important'
            sx={{
              cursor: 'pointer',
            }}
            onClick={handleSubmit}
          >
            SAVE
          </StyledButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  )
}

export default UpdateLeaveInfo
