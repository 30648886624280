import { Paper, Box, Typography } from '@mui/material'
import Cards from '../Common/Card/Cards'
import BackgroundInfoSvg from '../../components/Svg/noun-personal-information-7242160 .svg'
import EmploymentInfoSvg from '../../components/Svg/employmentInfo.svg'
// import LeaveInfo from '../../components/Svg/noun-leave-940740.svg'
import AddEmp from '../../components/Svg/addEmployee.svg'
import BankInformation from '../../components/Svg/noun-bank-7329159.svg'
import SalarySlip from '../../components/Svg/noun-payroll-7160887.svg'
import Payroll from '../../components/Svg/payroll.svg'
import TaxReport from '../../components/Svg/noun-tax-6784489.svg'
import BasicInfo from '../../components/Svg/noun-account-info-4604294.svg'
import Compensation from '../../components/Svg/noun-funds-7223384.svg'
import LeaveInfo from '../../components/Svg/noun-absence-7388037.svg'

import { useLocation, useNavigate } from 'react-router-dom'

import styled from '@emotion/styled'
import style from '../../utils/styles.json'
import { ArrowBack, ConstructionOutlined } from '@mui/icons-material'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { fetchUserDetailsEntities } from '../../reducers'
import { fetchEmployee } from '../../actions'
import { useEffect } from 'react'
const employees = [
  { name: 'Background Info', avatar: BackgroundInfoSvg },
  { name: 'Bank Info', avatar: BankInformation },
  { name: 'Basic Info', avatar: BasicInfo },
  { name: 'Compensation', avatar: Compensation },
  { name: 'Employment Info', avatar: EmploymentInfoSvg },
  { name: 'Leave Details', avatar: LeaveInfo },
  { name: 'Payroll', avatar: Payroll },
  { name: 'Salary Slip', avatar: SalarySlip },
  { name: 'Tax Report', avatar: TaxReport },
]

const HeaderHeading = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  textAlign: 'center',
  fontFamily: style.FONT_BOLD,
  letterSpacing: '0px',
  color: style.PRIMARY_COLOR,
  marginBottom: '20px',
}))

const EmployeeTiles = (props: any) => {
  const { fetchEmployee, rowdata, restFetchEmployee } = props
  const navigate = useNavigate()
  const rowData: any = useLocation()?.state

  const navigateByName = (name: string) => {
    switch (name) {
      case 'Background Info':
        navigate(`/home/employees/backgroundInfo?userId=${rowData?.userid}`, {
          state: rowData?.userid ? rowData : {},
        })
        break
      case 'Employment Info':
        navigate(`/home/employees/employees-info?userId=${rowData?.userid}`, {
          state: rowData?.userid ? rowData : {},
        })

        break
      case 'Payroll':
        navigate(`/home/employees/payroll?userId=${rowData?.userid}`, {
          state: rowData?.userid ? rowData : {},
        })
        break
      case 'Bank Info':
        navigate(`/home/employees/bank-info?userId=${rowData?.userid}`, {
          state: rowData?.userid ? rowData : {},
        })
        break
      case 'Salary Slip':
        navigate(`/home/employees/salarySlip?userId=${rowData?.userid}`, {
          state: rowData?.userid ? rowData : {},
        })
        break
      case 'Leave Details':
        navigate(`/home/employees/LeaveDetails?userId=${rowData?.userid}`, {
          state: rowData?.userid ? rowData?.userid : {},
        })
        break

      case 'Compensation':
        navigate(`/home/employees/compensation?userId=${rowData?.userid}`, {
          state: rowData?.userid ? rowData?.userid : {},
        })
        break
      case 'Tax Report':
        navigate(`/home/employees/tax-report?userId=${rowData?.userid}`, {
          state: rowData?.userid ? rowData?.userid : {},
        })
        break
      default:
        console.warn(`No route defined for name: ${name}`)
    }
  }

  const handleNavigateToCard = (name: string) => {
    if (name === 'Basic Info') fetchEmployee({ id: rowData?.userid })

    navigateByName(name)
  }
  useEffect(() => {
    if (rowdata?.userid) {
      navigate('/home/employees/new-employees', { state: rowData?.userid ? rowdata : {} })
      restFetchEmployee()
    }
  }, [rowdata])

  return (
    <Paper
      elevation={3}
      style={{
        width: '95%',
        height: '100%',
        padding: '10px 10px 0 10px',
        margin: '20px',
        background: '#FFFFFF',
        boxShadow: '0px 3px 6px #00000029',
        overflow: 'auto',
      }}
    >
      <Box sx={{ textAlign: 'left' }}>
        <Box
          onClick={() => navigate(-1)}
          sx={{ float: 'right', pr: '20px', mt: '5px', cursor: 'pointer' }}
        >
          <ArrowBack />
        </Box>
      </Box>
      <HeaderHeading>{rowData?.first_name}'s Details</HeaderHeading>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          justifyContent: 'center',
        }}
      >
        {employees.map((employee, index) => (
          <Box
            key={index}
            sx={{
              width: { xs: '100%', sm: '48%', md: '23%' },
              marginBottom: 2,
            }}
          >
            <Cards
              name={employee.name}
              avatar={employee.avatar}
              handleNavigateToCard={handleNavigateToCard}
            />
          </Box>
        ))}
      </Box>
    </Paper>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    rowdata: fetchUserDetailsEntities.fetchUserData(state).getEmployee,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchEmployee: (data: any) => dispatch(fetchEmployee.request(data)),
    restFetchEmployee: (data: any) => dispatch(fetchEmployee.reset()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTiles)
