import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Box, Button, Checkbox, CircularProgress, Dialog, FormControl, InputLabel, MenuItem, OutlinedInput, Pagination, Select, TextField, Typography } from '@mui/material'
import { SearchBoxCustom, SearchIconStyle, loaderProps } from '../Common/CommonStyles'
import { Dispatch } from 'redux'
import { RootState } from '../../configureStore'
import { connect } from 'react-redux'
import { fetchAsignedRequest, fetchDepartmentList, fetchSR, getIssueTypeList } from '../../actions'
import { SRUI, dashboardEntity, dashboardUI } from '../../reducers'
import getHardCodedData from './ServiceRequestData.json'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import SRTab from '../Common/SRTab'
import UpdateRequestDialog from './UpdateRequestDialog'
import style from '../../utils/styles.json'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs, { Dayjs } from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import styles from '../../utils/styles.json'
// import { SelectField } from '../Common/ComponentCommonStyles'

const SelectField = styled(TextField)(({ theme }) => ({
  marginTop: '5px',
  marginBottom: '5px',
  '& .MuiOutlinedInput-input': {
    padding: '12px 14px', // Adjust the padding here
    fontSize: '13px', // Adjust the font size here
    fontFamily: styles.FONT_MEDIUM,
  },
  '& .MuiFormLabel-asterisk': {
    color: 'red',
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
}))

const StyledPaper = styled(Paper)(() => ({
  width: '93%',
  padding: '25px 25px',
  background: '#FFFFFF',
  opacity: '1',
  margin: '20px',
  border: '1px solid #DDDDDD',
}))

const MainContainer = {
  backgroundColor: 'rgb(231, 235, 240)',
  width: '100%',
}

const stylle = {
  resultBtnStyle: {
    fontSize: '13px',
    letterSpacing: '0px',
    borderRadius: '20px',
    padding: '5px 20px',
    height: '40px',
    whiteSpace: 'nowrap',
    fontFamily: style.FONT_MEDIUM,
    marginTop: '-10px',
  },
  datePickerStyle: {
    marginTop: '0',
    '.MuiFormLabel-asterisk': {
      color: 'red',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
      height: '40px',
    },
    '& .MuiInputLabel-root': {
      fontSize: '14px',
    },
    '& .MuiPickersDay-dayDisabled': {
      cursor: 'not-allowed',
    },
    width: '150px!important',
  },
}

const SearchBox: React.CSSProperties = {
  width: '250px',
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: style.PRIMARY_COLOR,
    color: 'White',
    fontFamily: style.FONT_MEDIUM,
    textAlign: 'center',
    fontSize: '13px',
    letterSpacing: '0px',
    padding: '11px 0px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontFamily: style.FONT_MEDIUM,
    textAlign: 'center',
    color: '#483f3f',
    letterSpacing: '0px',
  },
}))

const StyledTableRow = styled(TableRow)(() => ({
  left: '160px',
  width: '1719px',
  height: '60px',
  boxShadow: '0px 10px 3px #6c6c6c10',
  opacity: '1',
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const StyledSelectField = styled(Select)(({ theme }) => ({
  borderRadius: '20px',
  '& .MuiSelect-select': {
    padding: '9px 11px',
    fontSize: '13px',
    fontFamily: style.FONT_MEDIUM,
    borderRadius: '20px',
  },
  '&&.MuiInputBase-root.MuiOutlinedInput-root.MuiSelect-root': {
    borderRadius: '20px',
    padding: '9px 11px',
    marginLeft: '-1px',
  },
}))

const CustomFormControll = styled(FormControl)(() => ({
  // marginLeft: '1px',
  // marginTop: '5px',
}))

const SRstatus = ['Closed', 'In progress', 'Open', 'Reopen', `Won't fix`];

const ServiceRequestTableData = ({
  serviceRequestData,
  DepartmentList,
  page,
  handleChangePage,
  fetchSRData1,
  total,
  setSRStatusParent,
  setStartDateParent,
  setEndDateParent,
  fetchAssignedRequest,
  getIssueTypeList,
  GetIssueTypeData,
  setDeptId,
  setIssueId
}: any) => {
  const { getAllPriority } = getHardCodedData
  const { getAllStatus } = getHardCodedData
  const getPriorityLabel = (priorityValue: any) => {
    const priority = getAllPriority.find((p: any) => p.value === priorityValue)
    return priority ? priority.label : 'Unknown'
  }
  const getStatusLabel = (statusValue: any) => {
    const status = getAllStatus.find((p: any) => p.value === statusValue)
    return status ? status.label : 'Unknown'
  }
  const rowsPerPage = 100
  const [totalPages, setTotalPages] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredRows, setFilteredRows] = useState<any[]>([])
  const [projectDomain, setProjectDomain] = useState<string[]>(['Open'])
  const [getDepartmentId, setDepartmentId] = useState('2')
  const [getIssueTypeId, setIssueTypeId] = useState('')
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [open, setOpen] = useState(false)
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(7, 'day'))
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs())
  const navigate = useNavigate()
  const startDateString = startDate?.format('YYYY-MM-DD') || ''
  const endDateString = endDate?.format('YYYY-MM-DD') || ''

  useEffect(() => {
    const filteredData = serviceRequestData?.filter((row: any) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    )
    setFilteredRows(filteredData || [])
  }, [searchQuery, serviceRequestData])

  useEffect(() => {
    const totalRows = total || 0
    const totalPages = Math.ceil(totalRows / rowsPerPage)

    setTotalPages(totalPages)
  }, [total, rowsPerPage])

  const handleClickOpen = (rowData: any) => {
    setSelectedRowData(rowData)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleProjectDomainChange = (event: any) => {
    const {
      target: { value },
    } = event
    setProjectDomain(typeof value === 'string' ? value.split(',') : value)
    setSRStatusParent(typeof value === 'string' ? value.split(',') : value)
  }

  const handleDepartmentChange = (event: any) => {
    const selectedDepartmentId = event.target.value
    // setParticularDepartmentId(event.target.value)
    setDepartmentId(selectedDepartmentId)
    setDeptId(selectedDepartmentId)
    // setIssueTypeId(null)
    getIssueTypeList({ dept_id: selectedDepartmentId })
  }

  const handleIssueTypeChange = (event: any) => {
    const selectedIssueTypeId = event.target.value
    setIssueTypeId(selectedIssueTypeId)
    setIssueId(selectedIssueTypeId)
    // setTypeError(typeof event.target.value !== 'number')
  }

  const handleStartDateChange = (date: Dayjs | null) => {
    if (date) {
      setStartDate(date)
      setStartDateParent(date)
    }
  }
  const handleEndDateChange = (date: any | null) => {
    if (date) {
      setEndDate(date)
      setEndDateParent(date)
    }
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchQuery(e.target.value)
  }

  let SRStatus: number[] = []
  projectDomain.forEach((item) =>
    getAllStatus.forEach((data: any) => {
      if (item === data.label) {
        SRStatus.push(data.value)
      }
    }),
  )
  const handleShowResult = () => {
    return fetchAssignedRequest({
      page,
      pageSize : 100,
      srStatus: SRStatus === undefined ? "" : SRStatus,
      startDate: startDateString === undefined ? "" : startDateString,
      endDate: endDateString === undefined ? "" : endDateString,
    })
  }

  return (
    <>
      <Box sx={{ textAlign: 'left' }}>
        <Box
          onClick={() => navigate(-1)}
          sx={{ float: 'right', pr: '30px', mt: '0px', cursor: 'pointer' }}
        >
          <ArrowBack />
        </Box>
      </Box>
      <SRTab />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
          <SearchBoxCustom
            id='outlined-basic'
            placeholder='Search by Title'
            variant='outlined'
            size='small'
            value={searchQuery}
            onChange={handleSearch}
            style={SearchBox}
            InputProps={{
              startAdornment: <SearchIconStyle />,
            }}
          />
        </Box>
        <Box display={'flex'}>
        <CustomFormControll>
            <InputLabel id="demo-multiple-checkbox-label" sx={{ marginTop: -0.7 }}>
              Department
            </InputLabel>
            <StyledSelectField
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              variant="outlined"
              label="Department"
              sx={{ borderRadius: '20px', width: '150px', height: '42px',marginRight:'10px' }}
              // input={<OutlinedInput label="Select SR status" />}
              value={getDepartmentId}
              onChange={handleDepartmentChange}
              MenuProps={MenuProps}
            >
              {DepartmentList?.map((option: any) => (
                  <MenuItem key={option?.id} value={option?.id}>
                    {option.dept_name}
                  </MenuItem>
                ))}
            </StyledSelectField>
          </CustomFormControll>
          <CustomFormControll>
            <InputLabel id="demo-multiple-checkbox-label" sx={{ marginTop: -0.7 }}>
              Issue Type
            </InputLabel>
            <StyledSelectField
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              variant="outlined"
              label="Issue Type"
              sx={{ borderRadius: '20px', width: '150px', height: '42px',marginRight:'10px' }}
              // input={<OutlinedInput label="Select SR status" />}
              value={getIssueTypeId}
              onChange={handleIssueTypeChange}
              MenuProps={MenuProps}
            >
              <MenuItem value='' disabled>
                Select type
              </MenuItem>
              {GetIssueTypeData.map((option: any) => (
                <MenuItem key={option?.id} value={option?.id}>
                  {option.title}
                </MenuItem>
              ))}
            </StyledSelectField>
          </CustomFormControll>
          <CustomFormControll>
            <InputLabel id="demo-multiple-checkbox-label" sx={{ marginTop: -0.7 }}>
              Select SR status
            </InputLabel>
            <StyledSelectField
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              variant="outlined"
              label="Select SR status"
              sx={{ borderRadius: '20px', width: '150px', height: '42px', }}
              multiple
              input={<OutlinedInput label="Select SR status" />}
              value={projectDomain}
              onChange={handleProjectDomainChange}
              renderValue={(selected: any) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {getAllStatus.map((option) => (
                <MenuItem key={option.value} value={option.label}>
                  <Checkbox checked={projectDomain.includes(option.label)} />
                  {option.label}
                </MenuItem>
              ))}
            </StyledSelectField>
          </CustomFormControll>
        </Box>
         <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 1,
          marginTop: '10px',
        }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <Box>
                <DatePicker
                  sx={stylle.datePickerStyle}
                  label='Start Date'
                  value={startDate}
                  onChange={handleStartDateChange}
                  shouldDisableDate={(date) => (endDate ? date.isAfter(endDate, 'day') : false)}
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <Box>
                <DatePicker
                  sx={stylle.datePickerStyle}
                  label='End Date'
                  value={endDate}
                  onChange={handleEndDateChange}
                  shouldDisableDate={(date) =>
                    startDate ? date.isBefore(startDate, 'day') : false
                  }
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>
          <Button sx={stylle.resultBtnStyle} onClick={handleShowResult} color='primary'>
            SHOW RESULT
          </Button>
        </Box>
      </Box>
      <UpdateRequestDialog
        open={open}
        fetchSRData1={fetchSRData1}
        onClose={handleClose}
        rowData={selectedRowData}
        DepartmentList={DepartmentList}
      />

      <TableContainer component={Paper} sx={{ cursor: 'pointer' }}>
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>TICKET NO</StyledTableCell>
              <StyledTableCell>TITLE</StyledTableCell>
              <StyledTableCell>DEPARTMENT</StyledTableCell>
              <StyledTableCell>ISSUE TYPE</StyledTableCell>
              <StyledTableCell>PRIORITY</StyledTableCell>
              <StyledTableCell>STATUS</StyledTableCell>
              <StyledTableCell>RAISED BY</StyledTableCell>
              <StyledTableCell>RAISED ON</StyledTableCell>
              <StyledTableCell>DUE BY</StyledTableCell>
            </TableRow>
          </TableHead>
          {filteredRows && filteredRows.length ? (
            <TableBody>
              {filteredRows.map((request: any) => (
                <StyledTableRow onClick={() => handleClickOpen(request)} key={request.srId}>
                  <StyledTableCell component='th' scope='row'>
                    {request?.srId}
                  </StyledTableCell>
                  <StyledTableCell>{request?.title}</StyledTableCell>
                  <StyledTableCell>{request?.department?.dept_name}</StyledTableCell>
                  <StyledTableCell>{request?.issues?.title}</StyledTableCell>
                  <StyledTableCell>{getPriorityLabel(request?.priority)}</StyledTableCell>
                  <StyledTableCell>{getStatusLabel(request?.status)}</StyledTableCell>
                  <StyledTableCell>{request?.employeeName}</StyledTableCell>
                  <StyledTableCell>
                    {new Date(request?.created_at).toLocaleDateString('en-GB')}
                  </StyledTableCell>
                  <StyledTableCell>{request?.dueBy}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align='center' colSpan={10}>
                  <Typography variant='subtitle1' sx={{ color: '#483f3f' }}>
                    No matching records found.
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        <Pagination
          count={totalPages}
          color='primary'
          page={page + 1}
          onChange={(_, newPage) => handleChangePage(null, newPage - 1)}
        />
      </Box>
    </>
  )
}

const AssignedSR = (props: any) => {
  const {
    loaderState,
    fetchDepartmentList,
    DepartmentList,
    fetchAssignedRequest,
    assignedRequest,
    isAssignedRequest,
    getIssueTypeList,
    GetIssueTypeData,
  } = props
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(100)
  const [srStatus, setSRStatus] = useState<string[]>(['Open'])
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(7, 'day'))
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs())
  const [getDepartmentId, setDepartmentId] = useState('2')
  const [getIssueTypeId, setIssueTypeId] = useState('') 

  const { getAllStatus } = getHardCodedData

  useEffect(() => {
    fetchDepartmentList()
  }, [])

  let SRStatus: number[] = []
  srStatus.forEach((item) =>
    getAllStatus.forEach((data: any) => {
      if (item === data.label) {
        SRStatus.push(data.value)
      }
    }),
  )
  const startDateString = startDate?.format('YYYY-MM-DD') || ''
  const endDateString = endDate?.format('YYYY-MM-DD') || ''
  useEffect(() => {
    if (SRStatus) {
      fetchAssignedRequest({
        page,
        pageSize,
        srStatus: SRStatus === undefined ? "" : SRStatus,
        startDate:startDateString,
        endDate:endDateString,
        department:Number(getDepartmentId),
        issueType: getIssueTypeId,
      })
    }
  }, [page, srStatus,getDepartmentId,getIssueTypeId])
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: any) => {
    setPage(page)
  }

  const fetchSRData1 = () => {
    fetchAssignedRequest({
      page,
      pageSize,
      srStatus: SRStatus === undefined ? "" : SRStatus,
      startDate:startDateString,
      endDate:endDateString,
      department:Number(getDepartmentId),
      issueType: getIssueTypeId,
    })
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(event.target.value)
  }

  return (
    <>
      {(loaderState || isAssignedRequest) && (
        <Dialog open={loaderState || isAssignedRequest} PaperProps={{ style: loaderProps }}>
          <CircularProgress color='secondary' />
        </Dialog>
      )}
      {!loaderState ? (
        <div style={MainContainer}>
          <StyledPaper>
            <ServiceRequestTableData
              serviceRequestData={assignedRequest?.data}
              isAssignedRequest={isAssignedRequest}
              fetchSRData1={fetchSRData1}
              page={page}
              DepartmentList={DepartmentList}
              total={assignedRequest.totalCount}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              setSRStatusParent={setSRStatus}
              setStartDateParent={setStartDate}
              setEndDateParent={setEndDate}
              setDeptId={setDepartmentId}
              setIssueId={setIssueTypeId}
              fetchAssignedRequest={fetchAssignedRequest}
              getIssueTypeList={getIssueTypeList}
              GetIssueTypeData={GetIssueTypeData}
            />
          </StyledPaper>
        </div>
      ) : null}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    SRData: dashboardEntity.getDashboard(state).getSRData,
    loaderState: dashboardUI.getDashboard(state).isServiceRequestData,
    DepartmentList: dashboardEntity.getDashboard(state).getDepartmentList,
    isCreateServiceRequest: SRUI.getServiceRequestCreate(state).isCreateServiceRequest,
    assignedRequest: dashboardEntity.getDashboard(state).getAssignedRequest,
    isAssignedRequest: SRUI.getServiceRequestCreate(state).isAssignedRequest,
    GetIssueTypeData: dashboardEntity.getDashboard(state).getIssueTypeList,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchSRData: (data: any) => dispatch(fetchSR.request(data)),
    fetchDepartmentList: () => dispatch(fetchDepartmentList.request()),
    fetchAssignedRequest: (data: any) => dispatch(fetchAsignedRequest.request(data)),
    getIssueTypeList: (data: any) => {
      dispatch(getIssueTypeList.request(data))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignedSR)
